import React from "react";

export default function CardLink({ src }) {
  if (!src) {
    return null;
  }

  return (
    <div style={{ marginRight: "0px", overflow: "hidden" }}>
      <button
        style={{
          backgroundColor: "black",
          color: "gray",
          border: "none", // "1px solid #fff",
          padding: "0px 0px",
          cursor: "pointer",
          float: "left",
        }}
        onClick={(e) => {
          e.preventDefault();
          console.log("button clicked");
          window.open(src, "_blank");
          // window.location.href = "http://google.com";
        }}
      >
        Link to Media
      </button>
    </div>
  );
}
