import React from "react";

export default function CardYoutube(props) {
  if (!props.src) {
    return null;
  }
  return (
    <div style={{ width: "400px" }}>
      <iframe
        width="400px"
        height="240px"
        src={props.src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        // allowfullscreen
      ></iframe>
    </div>
  );
}
