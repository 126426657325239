import React, { useState } from "react";
// import { SliderData } from "./SliderData";
// import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { RiArrowDropLeftFill, RiArrowDropRightFill } from "react-icons/ri";
// import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
// import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";

export default function MediaSlider({ materials }) {
  //   console.log(props.pinsData, props.currentSlideNum);

  const [current, setCurrent] = useState(0);
  if (!materials) {
    return null;
  }

  const length = materials.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const divFromLink = (link) => {
    if (!link) {
      return null;
    }
    console.log(link);
    const linkType = link.split(".")[link.split(".").length - 1];
    return linkType === "mp4" ? (
      <video
        src={link}
        alt="travel image"
        className="image"
        controls="controls"
        autoPlay={false}
      />
    ) : (
      <img src={link} alt="travel" className="image" />
    );
  };

  return (
    <>
      <section className="slider">
        {materials.length > 1 ? (
          <RiArrowDropLeftFill
            color={"white"}
            className="left-arrow"
            onClick={prevSlide}
          />
        ) : null}
        {materials.length > 1 ? (
          <RiArrowDropRightFill
            color={"white"}
            className="right-arrow"
            onClick={nextSlide}
          />
        ) : null}

        {materials.map((material, index) => {
          return (
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
            >
              {index === current && divFromLink(material.url)}
            </div>
          );
        })}
      </section>
      <div>
        <p style={{ fontSize: "12px", color: "white", textAlign: "center" }}>
          {materials[current].caption}
        </p>
        <p style={{ fontSize: "12px", color: "gray", textAlign: "center" }}>
          {current + 1 + "/" + length + " "}
        </p>
      </div>
    </>
  );
}
