import React from "react";
import PropTypes from "prop-types";

import useMeasure from "react-use-measure";
import {ResizeObserver} from "@juggle/resize-observer";

export const Resizable = ({className = ``, children}) => {
  const [container, bounds] = useMeasure({polyfill: ResizeObserver});

  return (
    <div className={`${className}`}>
      <div className="w-100 h-100" ref={container}>
        {children(bounds)}
      </div>
    </div>
  );
};

Resizable.propTypes = {
  children: PropTypes.func.isRequired,
};
