import React from "react";

export default function CardAudio(props) {
  if (!props.src) {
    return null;
  }

  return (
    <div style={{ padding: "0px" }}>
      <video
        // preload
        controls="controls"
        autoPlay={false}
        width="400px"
        height="50px"
        src={props.src}
      />
    </div>
  );
}
