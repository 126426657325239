import React, {useRef, useMemo, useEffect, useState} from "react";
import {useGLTF} from "@react-three/drei/core/useGLTF";
import * as THREE from "three";

import AnimationCamera from "./AnimationCamera";
import PhotomatchPlane from "./PhotomatchPlane";
import MatchCameraHelper from "./MatchCameraHelper";

import {cameraSettingsFromSlideCam} from "./utils/camera";

import {Html} from "@react-three/drei";

const colorByHouseStatusDict = {
  occupied: { color: 0xfe0505, opacity: 1 }, // old red 0x5b0a08
  decision: { color: 0xe5231b, opacity: 1 },
  risk: { color: 0xe76c60, opacity: 1 },
  sealed: { color: 0x202020, opacity: 1 },
  built: { color: 0x6e6e6e, opacity: 1 },
  hide: { color: 0x42423c, opacity: 0 },
  highlight: { color: 0xff0000, opacity: 0.8 },
  strong: { color: 0xff0000, opacity: 1 },
  mark: { color: 0xdb463c, opacity: 0.5 },
};

const TERRAIN_MESH_NAME = "Terrain002";

export default function Model({isMobile, slide, matchCameras, language}) {
  const [terrainMaterial] = useState(
    () =>
      new THREE.MeshStandardMaterial({
        color: "white",
        side: THREE.DoubleSide,
        transparent: false,
      })
  );

  const [highlightMaterial] = useState(
    () =>
      new THREE.MeshPhongMaterial({
        color: 0x6e6e6e,
        side: THREE.DoubleSide,
        opacity: 1,
        transparent: true,
        depthWrite: true,
      })
  );

  const { nodes } = useGLTF("/shj/3d/2022.06.15_ShJ.glb");
  const terrainFile = useGLTF("/shj/3d/2022.04.27_terrain.glb");
  // const treeFile = useGLTF("/shj/3d/2022.03.04_tree.glb");

  const createMeshLabels = (labelSet) => {
    return Object.keys(labelSet).map((meshName) => {
      const mesh = nodes[meshName];
      const position = mesh.position;

      return (
        <Html position={[position.x, position.y, position.z]}>
          <div
            style={{
              // backgroundColor: "black",
              fontSize: "10px",
              color: "white",
              width: "auto",
              // display: "inline-block",
            }}
          >
            <strong
              style={{
                display: "inline-block",
                width: "100px",
              }}
            >
              <span
                style={{
                  backgroundImage: "linear-gradient(to bottom, black, black)",
                }}
              >
                {labelSet[meshName] && labelSet[meshName][language]}
              </span>
            </strong>
          </div>
        </Html>
      );
    });
  };

  const nodesDict = {
    "2022.06.15_ShJ.glb": nodes,
  };

  const childMeshes = nodes["Scene"].children.filter(
    (child) => child.type === "Mesh"
  );

  const photomatchPlanes = useMemo(
    () =>
      matchCameras.map((cam3) => (
        <PhotomatchPlane
          slideCam={slide.camera3}
          matchCamera={cam3}
          cameraSettings={cameraSettingsFromSlideCam(isMobile, cam3, nodesDict)}
        />
      )),
    [matchCameras]
  );

  const matchCameraHelpers = useMemo(
    () =>
      matchCameras.map((cam3) => (
        <MatchCameraHelper
          cameraSettings={cameraSettingsFromSlideCam(isMobile, cam3, nodesDict)}
        />
      )),
    [matchCameras]
  );

  const sceneMeshes = useMemo(
    () =>
      childMeshes.map((m) => {
        const meshName = m.name;
        const {geometry, position, rotation, scale} = nodes[meshName];

        const meshMaterial = highlightMaterial.clone();

        const highlightedMesh =
          slide.highlightedMeshes && slide.highlightedMeshes[meshName];

        if (highlightedMesh) {
          const {opacity, color} = colorByHouseStatusDict[highlightedMesh];

          meshMaterial.color.setHex(color);
          meshMaterial.opacity = opacity;
          meshMaterial.depthWrite = opacity === 0 ? false : true;
        }

        return (
          <mesh
            castShadow
            receiveShadow
            material={meshMaterial}
            geometry={geometry}
            position={position}
            rotation={rotation}
            scale={scale}
          />
        );
      }),
    [childMeshes]
  );

  useEffect(() => {
    if (terrainFile)
      terrainMaterial.map = terrainFile.nodes[TERRAIN_MESH_NAME].material.map;
  }, [terrainFile]);

  const activeCameraSettings = cameraSettingsFromSlideCam(
    isMobile,
    slide.camera3,
    nodesDict
  );

  return (
    <group>
      <AnimationCamera
        isMobile={isMobile}
        slide={slide}
        activeCameraSettings={activeCameraSettings}
      />
      <group>{photomatchPlanes}</group>
      <group>{matchCameraHelpers}</group>
      {slide.labels3D && createMeshLabels(slide.labels3D)}
      <group name="shj-model-objects">
        <mesh
          // meshes that uses blender materials
          castShadow
          receiveShadow
          geometry={terrainFile.nodes[TERRAIN_MESH_NAME].geometry}
          position={terrainFile.nodes[TERRAIN_MESH_NAME].position}
          material={terrainMaterial}
        ></mesh>
        <group>{sceneMeshes}</group>
      </group>
    </group>
  );
}

useGLTF.preload("/shj/3d/2022.04.27_terrain.glb");
useGLTF.preload("/shj/3d/2022.06.15_ShJ.glb");
