import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import "./Mapbox.css";

import { createThreeLayer } from "./mapbox/threeLayer";
import { addConfigLayers } from "./mapbox/geojsonLayers";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZmFkZXYiLCJhIjoiY2pyMjNiZHdvMHVjcjQ5cnU0b2dpdGRxZCJ9.7bci8C-z3EVCa_yMZKymeg";

mapboxgl.setRTLTextPlugin(
  "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
  null,
  true // Lazy load the plugin
);

const BASE_URL = "shj/";
const geoJsonList = [
  // points
  // {
  //   id: "Pal_Cities",
  //   data: BASE_URL + "geojson/" + "Pal_Cities.geojson",
  //   type: "symbol",
  //   minOpacity: 0.8,
  //   maxOpacity: 0.9,
  // },
  {
    id: "Pal_Uprising",
    data: BASE_URL + "geojson/" + "Pal_Uprising.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "Pal_Haifa_JS",
    data: BASE_URL + "geojson/" + "Pal_Haifa_JS.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "Pal_JS_Protests",
    data: BASE_URL + "geojson/" + "Pal_JS_Protests.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "Pal_JS_neigh",
    data: BASE_URL + "geojson/" + "Pal_JS_neigh.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "Pal_WB_cities",
    data: BASE_URL + "geojson/" + "Pal_WB_cities.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "Pal_48_mixed",
    data: BASE_URL + "geojson/" + "Pal_48_mixed.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "Pal_MY",
    data: BASE_URL + "geojson/" + "Pal_MY.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "Pal_Gaza",
    data: BASE_URL + "geojson/" + "Pal_Gaza.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "Pal_C48",
    data: BASE_URL + "geojson/" + "Pal_C48.geojson",
    type: "symbol",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  // lines

  // fill
  {
    id: "the_48",
    data: BASE_URL + "geojson/" + "the_48.geojson",
    type: "fill",
    minOpacity: 0.8,
    maxOpacity: 0.9,
    // hatch: "pattern_diagonal_x2.png",
  },
  {
    id: "green_areas_js",
    data: BASE_URL + "geojson/" + "green_areas_js.geojson",
    type: "fill",
    minOpacity: 0.2,
    maxOpacity: 0.3,
    hatch: "pattern_01.png",
  },
  {
    id: "small_settlements",
    data: BASE_URL + "geojson/" + "small_settlements.geojson",
    type: "fill",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "colonial_building_projects",
    data: BASE_URL + "geojson/" + "colonial_building_projects.geojson",
    type: "fill",
    minOpacity: 0.5,
    maxOpacity: 0.5,
  },
  {
    id: "threatened_houses",
    data: BASE_URL + "geojson/" + "threatened_houses.geojson",
    type: "fill",
    minOpacity: 0.4,
    maxOpacity: 0.4,
  },
  {
    id: "more_threatened_houses",
    data: BASE_URL + "geojson/" + "more_threatened_houses.geojson",
    type: "fill",
    minOpacity: 0.6,
    maxOpacity: 0.6,
  },
  {
    id: "built_up_settlements_js_west",
    data: BASE_URL + "geojson/" + "built_up_settlements_js_west.geojson",
    type: "fill",
    minOpacity: 0.9,
    maxOpacity: 1,
    // hatch: "hatch_03.png",
  },
  {
    id: "built_up_settlements_js_east",
    data: BASE_URL + "geojson/" + "built_up_settlements_js_east.geojson",
    type: "fill",
    minOpacity: 0.7,
    maxOpacity: 0.9,
  },
  {
    id: "jerusalem_walls_national_park",
    data: BASE_URL + "geojson/" + "jerusalem_walls_national_park.geojson",
    type: "fill",
    minOpacity: 0.2,
    maxOpacity: 0.3,
    hatch: "pattern_01.png",
  },
  {
    id: "new_national_parks",
    data: BASE_URL + "geojson/" + "new_national_parks.geojson",
    type: "fill",
    minOpacity: 0.2,
    maxOpacity: 0.3,
    hatch: "pattern_01.png",
  },
  {
    id: "area_c",
    data: BASE_URL + "geojson/" + "area_c.geojson",
    type: "fill",
    minOpacity: 0.4,
    maxOpacity: 0.5,
    // hatch: "pattern_cross_x2.png",
  },
  {
    id: "settlements_outside_js",
    data: BASE_URL + "geojson/" + "settlements_outside_js.geojson",
    type: "fill",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  {
    id: "settlements_outside_js",
    data: BASE_URL + "geojson/" + "settlements_outside_js.geojson",
    type: "fill",
    minOpacity: 0.8,
    maxOpacity: 0.9,
  },
  // separation_barrier
  {
    id: "separation_barrier",
    data: BASE_URL + "geojson/" + "separation_barrier.geojson",
    type: "line",
    color: "#9e0000",
    width: 7,
    minOpacity: 0.2,
    maxOpacity: 0.3,
  },
  {
    id: "haifa_to_js",
    data: BASE_URL + "geojson/" + "haifa_to_js.geojson",
    type: "line",
    color: "#ff0000",
    width: 3,
    minOpacity: 0.2,
    maxOpacity: 0.3,
  },
  {
    id: "js_new_municipal_boundary",
    data: BASE_URL + "geojson/" + "js_new_municipal_boundary.geojson",
    type: "line",
    color: "#ffffff",
    width: 4,
    minOpacity: 0.2,
    maxOpacity: 0.3,
  },
  {
    id: "green_line",
    data: BASE_URL + "geojson/" + "green_line.geojson",
    type: "line",
    color: "#ffffff",
    width: 4,
    minOpacity: 0.2,
    maxOpacity: 0.3,
  },
  {
    id: "roads",
    data: BASE_URL + "geojson/" + "roads.geojson",
    type: "line",
    color: "#9e0000",
    width: 4,
    minOpacity: 0.2,
    maxOpacity: 0.3,
  },
  {
    id: "roads_base",
    data: BASE_URL + "geojson/" + "roads_base.geojson",
    type: "line",
    color: "#ffffff",
    width: 5,
    minOpacity: 0.2,
    maxOpacity: 0.3,
  },
];

const getVerticalBounds = (bounds) => {
  // map.fitBounds([
  //   [slide.bounds[0], slide.bounds[1]], // southwestern corner of the bounds
  //   [slide.bounds[2], slide.bounds[3]], // northeastern corner of the bounds
  // ]);
  const horz_mid_point = (bounds[2] - bounds[0]) / 2 + bounds[0];
  const ver_mid_point = (bounds[3] - bounds[1]) / 2 + bounds[1];

  const vertical_bounds = [
    [horz_mid_point, bounds[1]],
    [horz_mid_point, bounds[3]],
  ];
  return vertical_bounds;
};

export default function MapboxMap({ slide, language, isMobile }) {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const map = mapRef.current;

  useEffect(() => {
    if (mapRef.current) return; // initialize map only once
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/fadev/cl1q8gklr000a14o0xjscqgpe",
      center: [35.22093, 31.78035],
      zoom: 10,
      antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
    });

    const map = mapRef.current;

    map.on("style.load", () => {
      map.loadImage("shj/" + "icons/" + "circle.png", (err, image) => {
        // Throw an error if something goes wrong.
        if (err) throw err;
        // Add the image to the map style.
        map.addImage("circle_icon", image);
      });
      addConfigLayers(map, geoJsonList, language);
      /////
    });
  });

  useEffect(() => {
    if (!mapRef.current) return; // wait for map to initialize
    // console.log("setting map bounds", slide.bounds);

    // map.current.transform._zoom = viewState.zoom;
    const map = mapRef.current;
    if (slide.bounds) {
      const verticalBounds = getVerticalBounds(slide.bounds);
      map.fitBounds(verticalBounds);
      // map.fitBounds([
      //   [slide.bounds[0], slide.bounds[1]], // southwestern corner of the bounds
      //   [slide.bounds[2], slide.bounds[3]], // northeastern corner of the bounds
      // ]);
    }

    if (slide.visibleMapLayers) {
      console.log(slide.visibleMapLayers);
      Object.keys(slide.visibleMapLayers).forEach((layerId) => {
        const selectedLayer = map.getLayer(layerId);
        // console.log(selectedLayer);

        // map.setLayoutProperty("SHJ geojson", "visibility", "visible"); // visible or none
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-fill-fill-opacity
        // doesn't work if the slide is not loaded yet
        console.log(selectedLayer.type);
        if (selectedLayer.type === "fill") {
          map.setPaintProperty(
            layerId,
            "fill-opacity",
            slide.visibleMapLayers[layerId]
          );
        }
        if (selectedLayer.type === "line") {
          map.setPaintProperty(
            layerId,
            "line-opacity",
            slide.visibleMapLayers[layerId]
          );
        }
        if (selectedLayer.type === "circle") {
          map.setPaintProperty(
            layerId,
            "circle-opacity",
            slide.visibleMapLayers[layerId]
          );
        }
        if (selectedLayer.type === "symbol") {
          map.setLayoutProperty(
            layerId,
            "visibility",
            slide.visibleMapLayers[layerId] > 0 ? "visible" : "none"
          );
          map.setLayoutProperty(
            layerId,
            "text-field",
            language === "en" ? ["get", "Name_EN"] : ["get", "Name_AR"]
          );
        }

        map.moveLayer("built_up_settlements_js_west");
        map.moveLayer("built_up_settlements_js_east");
        map.moveLayer("js_new_municipal_boundary");
        map.moveLayer("separation_barrier");
        map.moveLayer("roads_base");
        map.moveLayer("roads");
        map.moveLayer("green_line");
        map.moveLayer("Pal_Uprising");
        map.moveLayer("Pal_Haifa_JS");
        map.moveLayer("Pal_JS_neigh");
        map.moveLayer("Pal_48_mixed");
        map.moveLayer("Pal_WB_cities");
        map.moveLayer("Pal_MY");
        map.moveLayer("Pal_Gaza");
        map.moveLayer("Pal_C48");
        // map.setPaintProperty(layerId, "fill-color", "green"); // "#e41b14"
      });
    }

    map.on("load", () => {
      const layers = map.getStyle().layers;
      console.log(layers);
      // map.current.addLayer(createThreeLayer(), "waterway-label");
    });

    // map.on("idle", () => {
    //   const selectedLayer = map.getLayer("SHJ geojson");
    //   console.log(selectedLayer);
    //   // const visibility = map.getLayoutProperty("SHJ geojson", "visibility");
    //   // map.setLayoutProperty("SHJ geojson", "visibility", "none");
    // });
    // map.current.flyTo({
    //   center: [viewState.lon, viewState.lat],
    //   zoom: viewState.zoom,
    //   bearing: viewState.bearing,
    //   pitch: viewState.pitch,
    //   speed: 0.2,
    //   //   curve: 1,
    //   //   easing: (t) => t,

    //   essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    // });
  }, [slide, language]);

  // useEffect(() => {
  //   console.log("language from map", language);
  //   if (!mapRef.current) return; // wait for map to initialize
  // }, [language]);

  return (
    <div>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
}
