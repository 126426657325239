import React from "react";
import {InView} from "react-intersection-observer";
import {useNavigate} from "react-router-dom";

import Card from "./Card";
import Section from "./Section";

export default function Scrollers({
  isMobile,
  slides,
  galleryData,
  callbackFromParent,
  next,
  language
}) {
  const navigate = useNavigate();
  const SLIDES = slides;

  // const slidesKeys = Object.keys(SLIDES);
  // const lastSlideToShow = slidesKeys.length + parseInt(slidesKeys[0]);

  const narrativeWrapperStyle = {
    fontSize: 30,
    color: "yellow",
    position: "absolute",
    // overflow: "scroll",
    zIndex: 90,
  };

  const narrativeElementStyle = {
    height: "120vh",
    width: "100vw",
    // display: "flex",
    // alignItems: "center",
  };

  return (
    <div style={narrativeWrapperStyle} id={"narrative-wrapper-card"}>
      {SLIDES.map((value, i) => {
        // console.log(key, value);
        const index = i;
        // const slide = value;
        // console.log(index, slide);
        return (
          <>
            {/* <div style={{ height: `100vh` }} /> */}
            <InView
              key={`scroller-slide-${value.index}`}
              initialInView={false}
              threshold={0.5}
              onChange={(inView, ref, entry) => {
                if (inView) {
                  console.warn("switching to", index + 1);
                }
                inView && callbackFromParent(index);
              }}
            >
              {({ref, inView, entry}) => (
                <div
                  key={index}
                  style={narrativeElementStyle}
                // id={"card-full-screen-container-" + (index + 1).toString()}
                >
                  <div
                    id={"track-div-" + (index + 1).toString()}
                    ref={ref}
                    style={{
                      width: "100%",
                      height: "1%",
                      // backgroundColor: "white",
                    }}
                  />
                  {slides[index].cardText ? (
                    <Card
                      isMobile={isMobile}
                      slide={slides[index]}
                      slideIdx={index}
                      language={language}
                    />
                  ) : null}
                  {slides[index].sectionText ? (
                    <Section slide={slides[index]} slideIdx={index} />
                  ) : null}
                </div>
              )}
            </InView>
          </>
        );
      })}
      {next && (
        <InView
          style={{height: `80vw`}}
          as="div"
          className="move-to-next"
          threshold={0.5}
          initialInView={false}
          onChange={(inView) => {
            if (inView && next) {
              console.log("navigating to", next);
              navigate(next);
              callbackFromParent(0);
            }
          }}
        />
      )}
    </div>
  );
}
