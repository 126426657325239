import React from "react";

export default function ScreenText({ isMobile, slide }) {
  if (!slide.fullScreenText) {
    return null;
  }

  return (
    <div
      // id={"full-screen-text"}
      style={{
        // color: "black",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "100",
        flexDirection: "column",
        // top: 0,
        // left: 600,
        // top: 150,
        // right: 0,
        // bottom: 300,
        opacity: slide.fullScreenText ? "0.8" : "0.0",
        backgroundColor: "black", // "black", 7dffb3  00ff7f
        pointerEvents: "none",
        // zIndex: 20,
        width: "100vw",
        height: "100vh",
        // padding: "0vw 10vw",
        WebkitTransition: "opacity 2s",
      }}
      // className={`scrollable-video__container`}
    >
      {slide.fullScreenText.split("\n").map((p, i) => (
        <p
          key={i}
          style={{
            color: "white",
            // fontWeight: "normal",
            fontSize: isMobile ? "4vw" : "1.7vw",
            textAlign: "center",
            margin: "1vw 10vw",
          }}
        >
          {p}
        </p>
      ))}
    </div>
  );
}
