import React, { useRef } from "react";
// import * as THREE from "three";

export default function SceneLighting({ slide }) {
  const lightRef = useRef();

  if (lightRef.current) {
    const light = lightRef.current;

    // light.target = new THREE.Vector3(1, 2, 1); //;
    const d = 5000;

    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.radius = 1.5;

    light.shadow.camera.near = 0.1; // default
    light.shadow.camera.far = 1000; // default
    // console.log(light);
  }

  // const dirLightRef = useRef();
  // const dirLight = dirLightRef.current;
  // if (dirLight) {
  //   const d = 50;

  //   dirLight.shadow.camera.left = -d;
  //   dirLight.shadow.camera.right = d;
  //   dirLight.shadow.camera.top = d;
  //   dirLight.shadow.camera.bottom = -d;

  //   dirLight.shadow.camera.near = 0.1;
  //   dirLight.shadow.camera.far = 500;
  //   dirLight.shadowDarkness = 0.5;
  // }

  return (
    <group>
      <spotLight
        ref={lightRef}
        position={[15, 4, 25]}
        castShadow
        intensity={2}
        shadow-mapSize-height={1024 * 4}
        shadow-mapSize-width={1024 * 4}
        // shadow-mapSize-width={2048}
        // shadow-mapSize-height={2048}
      />

      {/* <ambientLight intensity={1} /> */}
      {/* <pointLight intensity={1} position={[200, 500, 300]} castShadow /> */}
      {/* <spotLight position={[-220, 725, 130]} angle={-0.15} penumbra={1} /> */}
      {/* <directionalLight
            position={new THREE.Vector3(-220, 735, 140)}
            // intensity={1}
            color={0xffffff}
            shadow-mapSize-height={512}
            shadow-mapSize-width={512}
            castShadow
          /> */}
      {/* <directionalLight
            ref={dirLightRef}
            position={new THREE.Vector3(1, 3, 1)}
            intensity={0.1}
            color={"white"}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            // castShadow
          />
          <directionalLight
            // ref={dirLightRef}
            position={new THREE.Vector3(-1, -2, -1)}
            intensity={0.5}
            color={"gray"}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            // castShadow
          /> */}
      {/* <pointLight position={[-100, 300, 100]} /> */}
    </group>
  );
}
