const addSymbolLayer = (map, layer) => {
  map.addLayer({
    id: layer.id,
    // References the GeoJSON source defined above
    // and does not require a `source-layer`
    source: {
      type: "geojson",
      data: layer.data,
    },
    type: layer.type,
    paint: {
      "text-color": "#ffffff",
      "icon-color": "#000000",
      "text-halo-color": "rgba(0, 0, 0, 255)",
      "text-halo-width": 3,
      "text-halo-blur": 1,
    },
    layout: {
      "icon-image": "circle_icon",
      // Set the label content to the
      // feature's `name` property
      "text-field": ["get", "Name_EN"],
      "text-variable-anchor": ["top", "left"],
      // "text-radial-offset": 0.5,
      "text-justify": "auto",
      "text-padding": 0.3,

      // "text-visibility-transition": {
      //   duration: 2000,
      //   delay: 0,
      // },
    },
  });
};

const addCircleLayer = (map, layer) => {
  map.addLayer({
    id: layer.id,
    // References the GeoJSON source defined above
    // and does not require a `source-layer`
    source: {
      type: "geojson",
      data: layer.data,
    },
    type: layer.type,
    paint: {
      "circle-color": "red",
      "circle-opacity": 0.7,
      "circle-radius": 8,
    },
    "circle-opacity-transition": {
      duration: 2000,
      delay: 0,
    },
    layout: {
      // Set the label content to the
      // feature's `name` property
      // "text-field": ["get", "Name_EN"],
    },
  });
};

const addLineLayer = (map, layer) => {
  map.addLayer({
    id: layer.id,
    // References the GeoJSON source defined above
    // and does not require a `source-layer`
    source: {
      type: "geojson",
      data: layer.data,
    },
    type: layer.type,
    paint: {
      "line-color": layer.color,
      "line-width": layer.width,
      // "line-gap-width": layer.width + 1,
      "line-opacity-transition": {
        duration: 2000,
        delay: 0,
      },
    },
    layout: {
      "line-cap": "round",
      // Set the label content to the
      // feature's `name` property
      // "text-field": ["get", "name"],
    },
  });
};

const addFillLayer = (map, layer) => {
  map.addLayer({
    id: layer.id,
    // References the GeoJSON source defined above
    // and does not require a `source-layer`
    source: {
      type: "geojson",
      data: layer.data,
    },
    type: layer.type,
    paint: {
      "fill-color": "red",
      // "fill-antialias": true,
      // "fill-outline-color": "yellow",
      "fill-opacity-transition": {
        duration: 2000,
        delay: 0,
      },
      "fill-opacity": [
        "interpolate",
        // Set the exponential rate of change to 0.5
        ["exponential", 0.5],
        ["zoom"],
        // When zoom is 10, buildings will be 100% transparent.
        13,
        layer.minOpacity,
        // When zoom is 18 or higher, buildings will be 100% opaque.
        14,
        layer.maxOpacity,
      ],
    },
    layout: {
      // Set the label content to the
      // feature's `name` property
      // "text-field": ["get", "name"],
    },
  });
};

const addFillLayerHatch = (map, layer) => {
  map.loadImage("shj/" + "hatches/" + layer.hatch, (err, image) => {
    // Throw an error if something goes wrong.
    if (err) throw err;
    // Add the image to the map style.
    map.addImage(layer.hatch, image);
    // Create a new layer and style it using `fill-pattern`.
    map.addLayer({
      id: layer.id,
      // References the GeoJSON source defined above
      // and does not require a `source-layer`
      source: {
        type: "geojson",
        data: layer.data,
      },
      type: layer.type,
      paint: {
        "fill-color": "red",
        "fill-pattern": layer.hatch,
        // "fill-antialias": true,
        "fill-outline-color": "red",
        "fill-opacity-transition": {
          duration: 2000,
          delay: 0,
        },
        "fill-opacity": [
          "interpolate",
          // Set the exponential rate of change to 0.5
          ["exponential", 0.5],
          ["zoom"],
          // When zoom is 10, buildings will be 100% transparent.
          13,
          layer.minOpacity,
          // When zoom is 18 or higher, buildings will be 100% opaque.
          14,
          layer.maxOpacity,
        ],
      },
      layout: {
        // Set the label content to the
        // feature's `name` property
        // "text-field": ["get", "name"],
      },
    });

    ///////
  });
};

export const addConfigLayers = (map, geoJsonList) => {
  geoJsonList.forEach((layer) => {
    if (layer.hatch !== undefined) {
      addFillLayerHatch(map, layer);
    }
    if (layer.type === "fill" && layer.hatch === undefined) {
      addFillLayer(map, layer);
    }
    if (layer.type === "line") {
      addLineLayer(map, layer);
    }
    if (layer.type === "circle") {
      addCircleLayer(map, layer);
    }
    if (layer.type === "symbol") {
      addSymbolLayer(map, layer);
    }
  });
};
