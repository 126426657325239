import React from "react";
import {useNavigate} from "react-router-dom";

import Toggle from './Toggle.js';

import "./NavBar.css";
import {LANGUAGES, LANGUAGE_LOWERCASE} from "../constants.js";

const LOGOS = [
  // {
  //   src: "logos/ECCHR.png",
  //   alt: "ECCHR",
  //   link: "https://www.ecchr.eu/en/",
  // },
  // {
  //   src: "logos/HR 360.png",
  //   alt: "HR 360",
  //   link: "https://www.humanrights360.org",
  // },
  {
    src: "logos/FA Square logo.png",
    alt: "Forensic Architecture",
    link: "https://forensic-architecture.org",
  },
];

export default function NavBar({URLS, chapter, width, height, isMobile, onToggle, language}) {
  const navigate = useNavigate();
  const handleChapterClick = (url, startIdx) => {
    navigate(url);
    // setCurrentSlide(startIdx);
  };

  const navTitle = {
    en: `Sheikh Jarrah`,
    ar: `الشيخ جراح`
  }

  const urls = language === LANGUAGE_LOWERCASE.EN ? URLS : URLS.slice(0).reverse()

  return (
    <div className="nav-bar" width={width} height={height}>
      {isMobile ? null : (<div className="chapters-container">
        {urls.map(({title, startIdx, endIdx, url, next}) => (
          <button
            className={`nav-button ${url === chapter ? "current" : null}`}
            type="button"
            onClick={() => handleChapterClick(url, startIdx)}
          >
            {title[language]}
          </button>
        ))}
      </div>)}
      <div className="nav-title-container" >
        <p className="nav-title">{navTitle[language]}</p>
      </div>
      <Toggle
        onChange={(value) => onToggle(value)}
        isMobile={isMobile}
        leftLabel={LANGUAGES.EN}
        rightLabel={LANGUAGES.AR}
      />
      <div className="logos-container">
        {LOGOS.map((l) => (
          <img
            className="nav-logo"
            src={l.src}
            alt={l.alt}
            onClick={(e) => {
              e.preventDefault();
              console.log("button clicked");
              window.open(l.link, "_blank");
              // window.location.href = "http://google.com";
            }}
          ></img>
        ))}
      </div>
    </div >
  );
}
