const BASE_URL = "shj/";
const customPhotoMatchDelay = 2000;

const highlightedMeshesCustom = {
  // 67
  shanti: "occupied",
  // 2008/2009
  ghawi: "occupied",
  umkamel: "occupied",
  hanoon: "occupied",
  kurd_house_2000: "occupied",
  // 2021 May 2
  kurd_house_1954: "risk",
  jaoni: "risk",
  skafi: "risk",
  qasem: "risk",
  // 2021 Aug 2
  dajani: "decision",
  daoodi: "decision",
  sabagh: "decision",
  hmad: "risk", // sure?
  // rest
  abdulatif: "risk",
  abuarfa: "risk",
  attiah: "risk",
  fityani: "risk",
  ghosha: "risk",
  hjajeej: "risk",
  husseini: "risk",
  kawasmi: "risk",
  khatib: "risk",
  mani: "risk",
  nilsiba: "risk",
  oydah: "risk",
  slaimah: "risk",
  thiyab: "risk",
  zamamiri: "risk",
  zein: "risk",
};

const hiddenMeshes = {
  obaidat: "hide",
  planned_hotel: "hide",
  settlements: "hide",
  israeli_builtup_area_1: "hide",
  israeli_builtup_area_2: "hide",
  israeli_builtup_area_3: "hide",
  planned_parks_2: "hide",
  karm_mufti: "hide",
  shepherd_hotel: "hide",
  interior_ministry: "hide",
  settlement1: "hide",
  settlement2: "hide",
  western_neighbourhood_shiekh_jarrah: "hide",
  border_police_base: "hide",
  // kurd_house_2000001: "hide",
};

const geoJsonVisibility = {
  the_48: 0,
  small_settlements: 1,
  colonial_building_projects: 0.6,
  threatened_houses: 0.3,
  more_threatened_houses: 0.5,
  built_up_settlements_js_west: 0,
  built_up_settlements_js_east: 0,
  jerusalem_walls_national_park: 0,
  new_national_parks: 0,
  green_areas_js: 0,
  area_c: 0,
  settlements_outside_js: 0,
  separation_barrier: 0,
  Pal_Uprising: 0,
  // Pal_Cities: 0,
  haifa_to_js: 0,
  roads: 0,
  roads_base: 0,
  Pal_Haifa_JS: 0,
  Pal_JS_Protests: 0,
  Pal_JS_neigh: 0,
  js_new_municipal_boundary: 0,
  Pal_WB_cities: 0,
  Pal_48_mixed: 0,
  Pal_MY: 0,
  Pal_Gaza: 0,
  green_line: 0,
  Pal_C48: 0,
};

const data = {
  "/trailer": {
    slides: [
      {
        index: 1,
        notes: "Full screen title – landing page [first frame of Muna’s video]",
        chapterTitle: "Sheikh Jarrah: Ethnic Cleansing in Jerusalem",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: false,
        camera3: {
          type: "blender",
          name: "muna_settler_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        fullScreenVideoNoAudio: BASE_URL + "videos/INTRO VIDEO.mp4",
        blackScreen: true,
      },
      {
        index: 1,
        cardText:
          "The Palestinian neighbourhood of Sheikh Jarrah, near the Old City of Jerusalem, is at the frontier of Israel's efforts to ethnic cleanse Palestinians from the city.",
        notes: "Fade out the title screen, keep first frame of the video",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: true,
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 2,
        cardText:
          "Four homes have already been occupied by settlers, their inhabitants expelled. ",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: true,
        highlightedMeshes: {
          shanti: "occupied",
          ghawi: "occupied",
          umkamel: "occupied",
          hanoon: "occupied",
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 3,
        cardText: "Twenty two more are at risk.",
        notes: "[3D] Fade in the 3d model behind the photo-matched frame",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: { ...highlightedMeshesCustom },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 4,
        cardText:
          "Forensic Architecture has created an interactive urban account of the systemic apartheid, displacement and resistance moving across scales.",
        notes: "[3D] Slight camera movement, highlight the rest of the house",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        // labels3D: { kurd_house_1954:  {en: "Al-Kurd Home", ar: "دار الكرد"} },
        // highlightedMeshes: {
        //   kurd_house_2000: "occupied",
        //   kurd_house_1954: "risk",
        // },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 5,
        cardText:
          "Starting from the story of the Palestinian family of Al-Kurd, where Muna, a young , confronted a settler that occupied part of her home.",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: true,
        camera3: {
          type: "blender",
          name: "muna_settler_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
          threeMatch: BASE_URL + "images/" + "muna_settler_cam.jpg",
        },
      },
      {
        index: 5,
        cardText: ".. that occupied part of her home.",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: true,
        labels3D: { kurd_house_1954: { en: "Al-Kurd Home", ar: "دار الكرد" } },
        highlightedMeshes: {
          kurd_house_2000: "occupied",
          // kurd_house_1954: "risk",
        },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },

      {
        index: 6,
        cardText:
          "The narrative move to describe the Uthman ibn Affan street where other homes have also been occupied by Jewish settlers.",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        camera3: {
          type: "blender",
          name: "ghawi_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 25.3443,
          sensorSize: 38,
          threeMatch: BASE_URL + "images/" + "Ghawi_cam1.jpg",
        },
      },
      {
        index: 7,
        cardText:
          "And where acts of protest and resistance are routinely performed",
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to a photo-matched view at the street level",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        camera3: {
          type: "blender",
          name: "solidarity_in_street_cam", // change cam
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 27.4295,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "solidarity_in_street.jpeg",
        },
      },

      {
        index: 8,
        cardText:
          "and met with violence by the occupation forces and settlers.",
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to a photo-matched view at the street level",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        camera3: {
          type: "blender",
          name: "beatings_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 28.35,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "beatings_cam.jpg",
        },
      },
      {
        index: 9,
        cardText:
          "The story then moves  to the neighbourhood scale, where more homes are under threat of displacement.",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        labels3D: {
          shanti: { en: "Al-Shanti", ar: "دار الشنطي" },
          // 2008/2009
          ghawi: { en: "Ghawi", ar: "دار غاوي" },
          umkamel: { en: "Um Kamel", ar: "دار أم كامل" },
          hanoon: { en: "Hannoun", ar: "دار حنون" },
          kurd_house_2000: { en: "Al-Kurd Extention", ar: "توسعة دار الكرد" },
          // 2021 May 2
          kurd_house_1954: { en: "Al-Kurd", ar: "دار الكرد" },
          jaoni: { en: "Al-Jaoni", ar: "دار الجاعوني" },
          skafi: { en: "Skafi", ar: "دار سكافي" },
          qasem: { en: "Qasem", ar: "دار قاسم" },
          // 2021 Aug 2
          dajani: { en: "Al-Dajani", ar: "دار الدجاني" },
          daoodi: { en: "Al-Dauodi", ar: "دار الداوودي" },
          sabagh: { en: "Sabagh", ar: "دار الصباغ" },
          hmad: { en: "Hammad", ar: "دار حماد" }, // sure?
          // rest
          abdulatif: { en: "Abd Al-Latif", ar: "دار عبد اللطيف" },
          abuarfa: { en: "Abu Arafeh", ar: "دار أبو عرفة" },
          attiah: { en: "Attiyeh", ar: "دار عطية" },
          fityani: { en: "Fityani", ar: "دار الفتياني" },
          ghosha: { en: "Ghosha", ar: "دار غوشة" },
          hjajeej: { en: "Hajeej", ar: "دار حجيج" },
          husseini: { en: "Hussieni", ar: "دار الحسيني" },
          kawasmi: { en: "Qawasmi", ar: "دار القواسمي" },
          khatib: { en: "Al-Khatib", ar: "دار الخطيب" },
          mani: { en: "Al-Mani", ar: "دار الماني" },
          nilsiba: { en: "Nissiba", ar: "دار نسيبة" },
          oydah: { en: "Oydah", ar: "دار عودة" },
          slaimah: { en: "Slaymeh", ar: "دار السلايمة" },
          thiyab: { en: "Thiyab", ar: "دار ذياب" },
          zamamiri: { en: "Zamamiri", ar: "دار الزماميري" },
          zein: { en: "Zein", ar: "دار الزين" },
        },

        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          // 2021 Aug 2
          dajani: "decision",
          daoodi: "decision",
          sabagh: "decision",
          hmad: "decision", // sure?
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 10,
        cardText: "And where palestinian lands are confiscated",
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: { karm_mufti: { en: "Karm Al-Mufti", ar: "كرم المفتي" } },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "strong",
          salhiya: "hide",
        },
        camera3: {
          type: "blender",
          name: "salhiye_cam_2",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 50,
          sensorSize: 36,
        },
      },
      {
        index: 11,
        cardText: "Palestinian houses being demolitished",
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "hide",
          interior_ministry: "hide",
          settlement1: "hide",
          settlement2: "hide",
          western_neighbourhood_shiekh_jarrah: "hide",
        },
        camera3: {
          type: "blender",
          name: "salhiya_cam_2",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 57.21,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "salhiya_cam_2.jpeg",
        },
      },
      {
        index: 12,
        cardText:
          "…despite evidence of their presence well before the foundation of Israel.",
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          karm_mufti: { en: "Karm Al-Mufti", ar: "كرم المفتي" },
          british_consulate: {
            en: "British Consulate",
            ar: "القنصلية البريطانية",
          },
          shepherd_hotel: { en: "Shepherd Hotel", ar: "فندق شبرد" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "strong",
          shepherd_hotel: "strong",
          shepherd_hotel_building: "strong",
        },
        camera3: {
          type: "blender",
          name: "hussieni_1933_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 19.8414,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "husseini_1933_cam.png",
        },
      },
      {
        index: 13,
        cardText:
          "this neighbourhood of SJ is surrounded by settlements and roads serving Jews only",
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "highlight",
          shepherd_hotel: "highlight",
          shepherd_hotel_building: "highlight",
          interior_ministry: "highlight",
          settlements: "highlight",
          israeli_builtup_area_1: "highlight",
          israeli_builtup_area_2: "highlight",
          israeli_builtup_area_3: "highlight",
          israeli_builtup_area_1_buildings: "highlight",
          israeli_builtup_area_2_buildings: "highlight",
          israeli_builtup_area_3_buildings: "highlight",
          settlement2: "strong",
          road1: "strong",
          road2: "strong",
          western_neighbourhood_shiekh_jarrah: "highlight",
          /////
          interior_ministry001: "highlight",
          ministry_sci_tech: "highlight",
          national_headquarters: "highlight",
          regional_police_headquarters: "highlight",
          russian_compound_police_station: "highlight",
          social_security: "highlight",
          border_police_base: "highlight",
          border_police_base_buildings: "highlight",
          ministry_public_security: "highlight",
          western_neighbourhood_shiekh_jarrah: "highlight",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        camera3: {
          type: "blender",
          name: "neighborhood_view_3",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 24,
          sensorSize: 36,
        },
      },
      {
        index: 14,
        cardText:
          "It is a microcosm  of the scale of Jerusalem as a whole, whose urban planning policies seek to fragment and displace Palestinians",
        // labels3D: { western_neighbourhood_shiekh_jarrah: "Um Haroun" },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "highlight",
          shepherd_hotel: "highlight",
          shepherd_hotel_building: "highlight",
          interior_ministry: "highlight",
          settlements: "highlight",
          israeli_builtup_area_1: "highlight",
          israeli_builtup_area_2: "highlight",
          israeli_builtup_area_3: "highlight",
          israeli_builtup_area_1_buildings: "highlight",
          israeli_builtup_area_2_buildings: "highlight",
          israeli_builtup_area_3_buildings: "highlight",
          settlement2: "strong",
          road1: "strong",
          road2: "strong",
          western_neighbourhood_shiekh_jarrah: "highlight",
          /////
          interior_ministry001: "highlight",
          ministry_sci_tech: "highlight",
          national_headquarters: "highlight",
          regional_police_headquarters: "highlight",
          russian_compound_police_station: "highlight",
          social_security: "highlight",
          border_police_base: "highlight",
          border_police_base_buildings: "highlight",
          ministry_public_security: "highlight",
          western_neighbourhood_shiekh_jarrah: "strong",
          planned_parks_2: "mark",
        },
        bounds: [35.226716222, 31.789213693, 35.237220663, 31.794242541],
        show3D: true,
        camera3: {
          type: "blender",
          name: "top_view_large",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 1,
          small_settlements: 1,
        },
      },
      {
        index: 14,
        cardText:
          "[[[It is a microcosm  of the scale of Jerusalem as a whole, whose urban planning policies seek to fragment and displace Palestinians]]]",
        bounds: [35.226716222, 31.789213693, 35.237220663, 31.794242541],
        show3D: false,
        camera3: {
          type: "blender",
          name: "top_view_large",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 1,
        },
      },
      {
        index: 14,
        cardText:
          "[[[It is a microcosm  of the scale of Jerusalem as a whole, whose urban planning policies seek to fragment and displace Palestinians]]]",
        show3D: false,
        camera3: {
          type: "blender",
          name: "top_view_large",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
          jerusalem_walls_national_park: 0.3,
          new_national_parks: 0.5,
          green_areas_js: 0.5,
          built_up_settlements_js_west: 0.8,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          built_up_settlements_js_east: 1,
          Pal_JS_neigh: 1,
          js_new_municipal_boundary: 1,
        },
      },
      {
        index: 15,
        cardText:
          "Which itself is consistent with the way Israel planning policies has dominated historical Palestine under a system of colonial aparthied since the Nakba.",
        // images: [
        //   BASE_URL + "content/" + "demolition_01.png",
        //   BASE_URL + "content/" + "demolition_02.png",
        // ],
        // bounds: [35.21041, 31.76337, 35.26087, 31.7953],
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        // bounds: [34.8875, 31.5992, 35.486, 31.9778],
        show3D: false,
        camera3: {
          type: "blender",
          name: "top_view_large",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          built_up_settlements_js_east: 1,
          new_national_parks: 0.4,
          green_areas_js: 0.4,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          Pal_C48: 1,
          green_line: 1,
          area_c: 1,
          the_48: 1,
        },
      },
      {
        index: 15,
        cardText:
          "Which itself is consistent with the way Israel planning policies has dominated historical Palestine under a system of colonial aparthied since the Nakba.",
        bounds: [32.982, 30.738, 37.166, 33.386],
        camera3: {
          type: "blender",
          name: "top_view_large",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          Pal_48_mixed: 1,
          Pal_Gaza: 1,
          Pal_WB_cities: 1,
          // separation_barrier: 1,
        },
      },
    ],
    layers: [],
    pins: [],
    labels: [],
    frames: [],
  },
  "/": {
    slides: [
      {
        index: 1,
        notes: "Full screen title – landing page [first frame of Muna’s video]",
        chapterTitle: {
          en: "Sheikh Jarrah: Ethnic Cleansing in Jerusalem",
          ar: "الشيخ جراح: التطهير العرقي في القدس",
        },
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: false,
        hiddenMeshes: { ...hiddenMeshes },
        camera3: {
          type: "blender",
          name: "muna_settler_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        fullScreenVideoNoAudio: BASE_URL + "videos/INTRO VIDEO.mp4",
        blackScreen: true,
      },
      {
        index: 2,
        cardText: {
          en: "In May 2021, a video went viral showing Muna Al-Kurd, a young Palestinian from Sheikh Jarrah, confronting a settler who invaded and occupied part of her home.",
          ar: "في مايو 2021، انتشر مقطع فيديو يُظهر منى الكرد، شابة فلسطينية من الشيخ جراح، وهي تواجه مستوطنًا اقتحم جزءًا من منزلها واحتله.",
        },
        notes: "Fade out the title screen, keep first frame of the video ",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: false,
        hiddenMeshes: { ...hiddenMeshes },
        camera3: {
          type: "blender",
          name: "muna_settler_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        blackScreen: true,
        imageMatch: BASE_URL + "images/muna_yacub_first frame.jpg",
        imageLink:
          "https://www.instagram.com/reel/COX3KPHHx9p/?igshid=YmMyMTA2M2Y=",
        imageCredit: "Instagram @muna.kurd15",
      },
      {
        index: 3,
        cardText: "",
        notes: "Video plays, stops at the photo-matched frame ",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: true,
        hiddenMeshes: { ...hiddenMeshes },
        camera3: {
          type: "blender",
          name: "muna_settler_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
          threeMatch: BASE_URL + "images/" + "muna_settler_cam.jpg",
        },
        fullScreenVideo: BASE_URL + "videos/muna_yacub_n.mp4",
        // imageMatch: BASE_URL + "images/muna_yacub_last frame.png",
        imageLink:
          "https://www.instagram.com/reel/COX3KPHHx9p/?igshid=YmMyMTA2M2Y=",
        imageCredit: "Instagram @muna.kurd15",
      },
      {
        index: 4,
        cardText: {
          en: "The video was captured in the front yard of the family home, raising global attention to the case of the Al-Kurd family and the ongoing forced displacement of Palestinians in occupied Jerusalem. ",
          ar: "تم التقاط الفيديو في الباحة الأمامية لمنزل العائلة، ما لفت الانتباه العالمي إلى قضية عائلة الكرد والتهجير القسري المستمر للفلسطينيين في القدس المحتلة.",
        },
        notes: "[3D] Fade in the 3d model behind the photo-matched frame",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        hiddenMeshes: { ...hiddenMeshes },
        camera3: {
          type: "blender",
          name: "muna_settler_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
          threeMatch: BASE_URL + "images/" + "muna_settler_cam.jpg",
        },
        // imageMatch: BASE_URL + "images/muna_yacub_last frame.png",
      },
      {
        index: 5,
        cardText: {
          en: "In 2000, the family built a house extension.\nShortly afterwards, the occupation’s municipality declared it illegal and did not allow the family to move in.\nSince 2009, however, it has turned a blind eye to various groups of Jewish settlers who invade this extension and occupy it.",
          ar: "في عام 2000 ، قامت الأسرة ببناء توسعة للمنزل.\nبعد فترة قصيرة، قررت السلطات أن توسعة المنزل غير قانونية ولم تسمح للعائلة بالسكن فيها.\nلكن منذ عام 2009، غضت الطرف عن مجموعات مختلفة من المستوطنين اليهود الذين قاموا باقتحام هذه التوسعة واحتلالها.",
        },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to a higher view, highlight the relevant part of the house",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true, //half_of_kurd_house
        labels3D: {
          kurd_house_1954: { en: "Al-Kurd Home", ar: "دار الكرد" },
          kurd_house_2000: { en: "Al-Kurd Extention", ar: "توسعة دار الكرد" },
        },
        highlightedMeshes: { ...hiddenMeshes, kurd_house_2000: "occupied" },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 32,
          sensorSize: 36,
        },
      },
      {
        index: 6,
        cardText: {
          en: "Now the Al-Kurds are at risk of being forcibly expelled from the rest of their house, where they have lived since 1956.",
          ar: "في الوقت الراهن، عائلة الكرد معرضة لخطر الطرد قسرًا من بقية منزلهم، حيث عاشوا  منذ عام 1956.",
        },
        notes: "[3D] Slight camera movement, highlight the rest of the house",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        labels3D: { kurd_house_1954: { en: "Al-Kurd Home", ar: "دار الكرد" } },
        highlightedMeshes: {
          ...hiddenMeshes,
          kurd_house_2000: "occupied",
          kurd_house_1954: "risk",
        },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 7,
        cardText: {
          en: "The Al-Kurd family is one of 28 Palestinian families in Sheikh Jarrah that are facing—or have already been subjected to—forced displacement following occupation and harassment by Jewish settlers.\nAll these families are refugees having been forcibly displaced from different parts of historic Palestine during the Nakba.",
          ar: "عائلة الكرد هي واحدة من 28 عائلة فلسطينية في الشيخ جراح تواجه—أو سبق أن تعرضت—للتهجير القسري بعد الاحتلال والمضايقات من قبل المستوطنين اليهود.\nكل هذه العائلات لاجئة نزحت قسراً من أجزاء مختلفة من فلسطين التاريخية خلال النكبة.",
        },
        notes:
          "[3D] Move the camera to show other houses in the street, (jum detail street) highlight houses that received expulsion notices/decisions, add 3d labels with the families names.",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        labels3D: {
          shanti: { en: "Al-Shanti", ar: "دار الشنطي" },
          // 2008/2009
          ghawi: { en: "Ghawi", ar: "دار غاوي" },
          umkamel: { en: "Um Kamel", ar: "دار أم كامل" },
          hanoon: { en: "Hannoun", ar: "دار حنون" },
          kurd_house_2000: { en: "Al-Kurd Extention", ar: "توسعة دار الكرد" },
          // 2021 May 2
          kurd_house_1954: { en: "Al-Kurd", ar: "دار الكرد" },
          jaoni: { en: "Al-Jaoni", ar: "دار الجاعوني" },
          skafi: { en: "Skafi", ar: "دار سكافي" },
          qasem: { en: "Qasem", ar: "دار قاسم" },
          // 2021 Aug 2
          dajani: { en: "Al-Dajani", ar: "دار الدجاني" },
          daoodi: { en: "Al-Dauodi", ar: "دار الداوودي" },
          sabagh: { en: "Sabagh", ar: "دار الصباغ" },
          hmad: { en: "Hammad", ar: "دار حماد" }, // sure?
          // rest
          abdulatif: { en: "Abd Al-Latif", ar: "دار عبد اللطيف" },
          abuarfa: { en: "Abu Arafeh", ar: "دار أبو عرفة" },
          attiah: { en: "Attiyeh", ar: "دار عطية" },
          fityani: { en: "Fityani", ar: "دار الفتياني" },
          ghosha: { en: "Ghosha", ar: "دار غوشة" },
          hjajeej: { en: "Hajeej", ar: "دار حجيج" },
          husseini: { en: "Hussieni", ar: "دار الحسيني" },
          kawasmi: { en: "Qawasmi", ar: "دار القواسمي" },
          khatib: { en: "Al-Khatib", ar: "دار الخطيب" },
          mani: { en: "Al-Mani", ar: "دار الماني" },
          nilsiba: { en: "Nissiba", ar: "دار نسيبة" },
          oydah: { en: "Oydah", ar: "دار عودة" },
          slaimah: { en: "Slaymeh", ar: "دار السلايمة" },
          thiyab: { en: "Thiyab", ar: "دار ذياب" },
          zamamiri: { en: "Zamamiri", ar: "دار الزماميري" },
          zein: { en: "Zein", ar: "دار الزين" },
        },

        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          // 2021 Aug 2
          dajani: "decision",
          daoodi: "decision",
          sabagh: "decision",
          hmad: "decision", // sure?
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 8,
        cardText: {
          en: "The street where the families live has become a site of solidarity where residents and activists gather to protest and resist imminent expulsions.",
          ar: "أصبح الشارع الذي تعيش فيه العائلات منذ ذلك الحين موقعًا للتضامن حيث يتجمع السكان والناشطون للاحتجاج على عمليات الطرد الوشيكة.",
        },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to a photo-matched view at the street level",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "solidarity_in_street_cam", // change cam
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 27.4295,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "solidarity_in_street.jpeg",
        },
        springDelay: customPhotoMatchDelay,
        imageLink:
          "https://www.facebook.com/Sheikh.Jarrah.units/photos/basw.AbpueB6L9VeAF0_hFlicmUEIB6KybItwakc9-N6hljs44Ccn3VUt7zOnWKmMgekSVVpKW3UpxaNzVmz5MVp2YZJUb4Di9l7sgmQnqKeiGubYxIDZM0XsKOek4kBjZXsaCuETXaD3Q3cP6q8SxOMyovcjLWz2TBBx7va6O41ECRSKMLVjajEnAfkQ1Li3CJ883HMGrsXmjdA4XnXK5uHb5_NECP0wF70sZgSM1q3kcxHo_mjYBwOrwK0vY06DZeIKNhGPSdMnKYm0e2EOREdsVE8gApfbo82SaOowg6ZqNtArxA/251250416802348/?opaqueCursor=Abp2f_8qQFDlureDkpTFn8tnOvJ-rva0HB4skRrSEoP7YwoSbq78Uosy2tT8TZh806KsD9GzEYvs-Rde9_4FFGXluhHTth3nsIbfyBiOIaUDBPIHg_umsPqYrPLAPw6nQ2xFr_uftt7yW_RPk-pheJatINOL-PKNLFveMfDGf1xaCtUbDsFFVRuzO3gSxlu9nDa8W4FQbEqL0lLxHinvNfYOkWFqHN1lDbPiVsLxr5kJ8uQnbBZThDWtnAFtB-MmTPvAPhuDK1aVBnrkYFaMeifoHHoG00doxD1reL7XQ4n3xovI1kI58e8X6zuHicgzkxSc_UU-Ur84xc5cS9jFF8R-M2SIZm3Z-Igb3MsCzV94SDGizcVHywFAgMmuPTHysKv6N1sVmPd_mVl-iqB7EjGZ2WM9jFVbtRm1Bxr80G8FzhJBagWO5ryOkiJds_UBiuqALzIVAGM1PAJL_qU--x_Gji8gETWd_ptX60odzMN8OM5JHgiz7FSF6yHHBWHwvlW1b_IUIdH1VYh0mjCz5gDlR_kiY7mWkfrykQftvV_0g30LTIQtDtGAJ3QCiOIOS3Ulj8LNSZIOvJu0WBh9yx9iyW6Lje-9mnXdfh6mRtxOym2T8Z-ukhEUx8CrLR-ne7pRo85ZIT8OnkW8bH5wOuHHoLIxLbhV6-h0eHaSwKt1ucsjMIG6i69V3IsmsMxoEzWa77lii5GinBBvUqgyoCpwH1su8w0_q_oVRn3bA8Mwn-mjEHNf_X2Xi9qhKwY2m-U",
        imageCredit: "Sheikh Jarrah Units Facebook page",
      },
      {
        index: 9,
        cardText: {
          en: "These gatherings are regularly suppressed by the Israeli occupation forces with arrests, beatings, stun grenades, and skunk water.",
          ar: "وتقوم قوات الاحتلال بقمع هذه التجمعات بشكل دوري من خلال الاعتقالات والضرب واستخدام القنابل الصوتية والمياه العادمة.",
        },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to a photo-matched view at the street level",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "beatings_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 28.35,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "beatings_cam.jpg",
        },
        springDelay: customPhotoMatchDelay,
        imageLink:
          "https://twitter.com/hasenegun/status/1393760260855406599?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed&ref_url=notion%3A%2F%2Fwww.notion.so%2FStreet-Videos-245ecc3c2f44470085d99fc723bd0d21",
        imageCredit: "Twitter @hasenegun",
        // camera3: {
        //   type: "blender",
        //   name: "ramadan_table_cam_3",
        //   modelFile: "2022.06.15_ShJ.glb",
        //   focalLength: 14.43,
        //   sensorSize: 36,
        //   threeMatch: BASE_URL + "images/" + "ramadan_table_cam_3.jpg",
        // },
      },

      {
        index: 10,
        cardText: {
          en: "On several occasions, the occupation forces have blocked the street to prevent protesters from reaching the families’ homes.",
          ar: "وفي عدة مناسبات، أغلقت قوات الاحتلال الشارع لمنع المتظاهرين من الوصول إلى منازل العائلات.",
        },
        notes:
          "[3D] Move the camera to top view, showing the street, highlight the checkpoint location",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404], // top view match
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
        },
      },
      {
        index: 11,
        cardText: {
          en: "However, the wave of solidarity spread beyond this street in the Sheikh Jarrah neighbourhood.",
          ar: "انتشرت موجة التضامن خارج هذا الشارع في حي الشيخ جراح.",
        },
        notes:
          "[3D-to-Map] Fade out the 3d model, show underlying map at the same zoom level",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404], // top view match
        show3D: false,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
          Pal_JS_Protests: 1,
        },
      },
      {
        index: 12,
        cardText: {
          en: "In May 2021 it ignited protests in other parts of the city, especially around the Al-Aqsa and Bab al-Amoud (Damascus Gate) areas.",
          ar: "في مايو 2021 أشعلت احتجاجات في أجزاء أخرى من المدينة، خصوصًا حول الأقصى ومنطقة باب العمود (بوابة دمشق).",
        },
        notes:
          "[Map] 2022.06.15_ShJ.glb to the old city, highlight al-Aqsa on the map",
        bounds: [35.21178, 31.77186, 35.25283, 31.79784],
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
          Pal_JS_Protests: 1,
          Pal_Uprising: 0,
          // Pal_Cities: 1,
        },
      },
      {
        index: 13,
        cardText: {
          en: "And from there to different cities across historic Palestine, including Lydd, Jaffa, Haifa and Gaza.\nThe suppression of this national uprising culminated in the May 2021 bombardment of Gaza.",
          ar: "ومن هناك، انتقلت إلى مدن مختلفة عبر فلسطين التاريخية، ومن بينها اللد، ويافا، وحيفا وغزة.\nبلغ قمع هذه الانتفاضة ذروته في مايو 2021 بقصف وتدمير غزة.",
        },
        notes:
          "[Map] zoom out to Palestine scale, show “Green Line” highlight locations where protests took place",
        bounds: [32.982, 30.738, 37.166, 33.386], // Palestine
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
          Pal_JS_Protests: 0,
          Pal_Uprising: 1,
          // Pal_Cities: 1,
        },
      },
    ],
    layers: [],
    pins: [],
    labels: [],
    frames: [],
  },

  "/house": {
    slides: [
      {
        index: 1,
        chapterTitle: {
          en: "The House: The Story of al-Kurd Family",
          ar: "البيت: قصة عائلة الكرد",
        },
        // chapterDate: "18-19 February, 2020",
        // bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        bounds: [32.982, 30.738, 37.166, 33.386], // Palestine
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
      },
      {
        index: 1,
        chapterTitle: {
          en: "The House: The Story of al-Kurd Family",
          ar: "البيت: قصة عائلة الكرد",
        },
        // chapterDate: "18-19 February, 2020",
        // bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        bounds: [32.982, 30.738, 37.166, 33.386], // Palestine
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
          the_48: 0,
          haifa_to_js: 0,
          Pal_Haifa_JS: 0,
          Pal_Uprising: 0,
        },
      },
      {
        index: 2,
        cardText: {
          en: "In 1948, The Al-Kurd family was displaced from Haifa to the then Jordanian-controlled eastern part of Jerusalem.",
          ar: "في عام 1948، تم تهجير عائلة الكرد من حيفا إلى الجزء الشرقي من القدس الذي كان تحت سيطرة الأردن في ذلك الوقت.",
        },
        notes:
          "[3D-to-Map] Fade out the 3d model, show underlying map at the same zoom level",
        bounds: [32.982, 30.738, 37.166, 33.386], // Palestine
        show3D: false,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
          the_48: 0.3,
          haifa_to_js: 1,
          Pal_Haifa_JS: 1,
        },
      },
      {
        index: 3,
        cardText: {
          en: "In 1956, the family was allocated a plot of 350 sqm to build a house in Sheikh Jarrah as part of a resettlement project managed by UNRWA.",
          ar: "في عام 1956، تم تخصيص قطعة أرض مساحتها 350 مترًا مربعًا للعائلة لبناء منزل في الشيخ جراح كجزء من مشروع إعادة التوطين الذي تديره الأونروا.",
        },
        notes:
          "[3D-to-Map] Fade out the 3d model, show underlying map at the same zoom level",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404], // top view match
        show3D: false,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
          the_48: 0.3,
          haifa_to_js: 0,
          Pal_Haifa_JS: 0,
        },
      },
      {
        index: 4,
        cardText: {
          en: "Rifqa Al-Kurd and her son Nabiel built and inhabited the first house on this plot. In 2000 with the birth of Nabiel’s children, including Muna, the house was expanded.",
          ar: "قامت رفقة الكرد وابنها نبيل ببناء وسكن أول منزل في هذه الأرض. في عام 2000، وبعد ولادة أطفال نبيل بما فيهم منى، تم توسيع المنزل.",
        },
        notes:
          "[3D] Move the camera to top view, showing the street, highlight the checkpoint location",
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404], // top view match
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          // top view camera for match
          type: "three",
          name: "cam",
          position: [-250, 750, 500],
          rotationE: [0, 0, 0],
          fov: 19.499328828692196,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0,
          colonial_building_projects: 0,
          threatened_houses: 0,
          more_threatened_houses: 0,
          the_48: 0,
          haifa_to_js: 0,
        },
      },
      {
        index: 5,
        cardText: {
          en: "In 1967, after the Israeli occupation and unilateral annexation of the eastern part of Jerusalem, settler organisations laid ownership claims to the land where the house was built, based on the plot’s alleged historical ownership by Jewish residents.\nIsraeli laws allow for restitutions of Jewish property lost during the 1948 war but not of Palestinian ones. While their home is being claimed on behalf of alleged previous owners, the Al-Kurd family can not reclaim their properties lost in Haifa during the Nakba.",
          ar: "في عام 1967، بعد الاحتلال الإسرائيلي والضم الأحادي للجزء الشرقي من القدس، قدمت منظمات المستوطنين مطالبات بملكية الأرض حيث تم بناء المنزل، بناءً على مزاعم بملكيتها التاريخية من قبل سكان يهود.\nتسمح القوانين الإسرائيلية بإعادة الممتلكات اليهودية التي فقدت خلال حرب عام 1948 ولكن لا تسمح بإعادة الممتلكات الفلسطينية. بينما تتم المطالبة بمنزلهم نيابة عن مالكين سابقين مزعومين، لا يمكن لعائلة الكرد المطالبة بممتلكاتهم التي فقدوها في حيفا خلال النكبة.",
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        toggleTitle: { en: "Further Reading", ar: "قراءة متعمقة" },
        toggleContent: {
          en: "Under the terms of Israeli apartheid, Israeli policy enables the restoration of ownership over property owned by Jews prior to 1948. Israel, however, refuses to allow for the same restoration of ownership when it comes to the much larger amounts of land and property from which Palestinians were ethnically cleansed during the Nakba.\nUsing this differential policy, settler groups attempt to claim ownership over properties in Jerusalem that they claim, often without evidence, were owned by Jews.\nFor example, in 1972, settler groups managed to register   properties in Sheikh Jarrah as “Jewish-owned” based only on temporary Ottoman land leases granted for 3 years and valid between 1891 and 1894.\nThe occupation’s courts tend to accept Jewish property claims even when they are based on flimsy or false evidence.",
          ar: "بموجب شروط الفصل العنصري الإسرائيلي، تسمح السياسة الإسرائيلية باستعادة الممتلكات التي كان يمتلكها اليهود قبل عام 1948. ومع ذلك، ترفض إسرائيل السماح بإعادة الملكية عندما يتعلق الأمر بكمية أكبر بكثير من الأراضي والممتلكات التي طُرد الفلسطينيون منها خلال النكبة.\nباستخدام هذه السياسة التميزية، تحاول مجموعات المستوطنين المطالبة بملكية عقارات في القدس يزعمون، غالبًا دون أدلة، أنها مملوكة لليهود.\n على سبيل المثال، في عام 1972، تمكنت مجموعات المستوطنين من تسجيل العقارات في حي الشيخ جراح على أنها “مملوكة لليهود” بناءً على عقود إيجار مؤقتة للأراضي العثمانية الممنوحة لمدة 3 سنوات وصالحة بين عامي 1891 و1894.\nتميل محاكم الاحتلال إلى قبول دعاوى الملكية اليهودية حتى عندما تستند إلى أدلة واهية أو كاذبة.",
        },
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 20,
          sensorSize: 36,
          marginFactor: 1,
        },
      },
      {
        index: 6,
        cardType: "section",
        cardText: {
          en: "In 1982, the family’s claim for ownership over their house was not recognised and they were recategorised merely as temporary occupiers under the category of ‘protected tenants’, which in fact grants them little protection.\nThis started a decades-long legal battle with settler groups. The occupation’s municipal courts refused to investigate evidence that cast doubts on the claim that the land on which their house was built was owned by Jewish inhabitants until 1948.",
          ar: "في عام 1982، لم يتم الاعتراف بحق الأسرة في ملكية منزلها وأعيد تصنيف أفرادها على أنهم شاغلون مؤقتون تحت فئة ”المستأجرين المحميين“، والتي في الواقع لا تؤمن إلا القليل من الحماية.\nدخلت الأسرة معركة قانونية استمرت عقودًا ضد مجموعات المستوطنين. ورفضت محاكم بلدية الاحتلال التحقيق في الأدلة التي تلقي بظلال من الشك على الادعاء بأن الأرض التي بُني عليها منزلها كانت مملوكة لسكان يهود حتى عام 1948.",
        },
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 32,
          sensorSize: 36,
          marginFactor: 1,
        },
      },
      {
        index: 7,
        cardText: {
          en: "In 2000, as the family expanded and after multiple attempts to obtain a building permit were denied, like most planning applications filed by Palestinians, they built an extension to their house.\nThe occupation’s municipality prevented the family from using the new structure and fined them. Between 2000 and 2009, the extension was locked.",
          ar: "عام 2000، مع زيادة عدد أفراد الأسرة، وبعد عدة محاولات فاشلة للحصول على تصريح بناء، قامت الأسرة ببناء توسعة للمنزل.\nمنعت بلدية الاحتلال الأسرة من استخدام المبنى الجديد وفرضت عليهم غرامة. بين عامي 2000 و2009، ظل المبنى مُغلقًا.",
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        labels3D: {
          kurd_house_2000: { en: "Al-Kurd Extention", ar: "توسعة دار الكرد" },
        },
        highlightedMeshes: { ...hiddenMeshes, kurd_house_2000: "sealed" },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 32,
          sensorSize: 36,
          marginFactor: 1,
        },
      },
      {
        index: 8,
        cardText: {
          en: "In 2003, the land was registered  to Nahalat Shimon, a US-based charity designed to “Judaise” Jerusalem—a euphemism for displacing Palestinians and settling Jews throughout all parts of the city in their stead.\nThe organisation escalated its attempts to expel Palestinian families from Sheikh Jarrah, to build a Jewish settlement of 200 residential units.",
          ar: "في عام 2003 ، تم تسجيل الأرض لصالح 'نحلات شمعون' ، وهي مؤسسة خيرية مقرها الولايات المتحدة تهدف إلى 'تهويد' القدس—وهو تعبير ملطف لتهجير الفلسطينيين وتوطين اليهود في جميع أنحاء المدينة بدلاً منهم.\nوصعّدت المنظمة من محاولاتها لطرد العائلات الفلسطينية من حي الشيخ جراح بهدف تهويد الحي وبناء مستوطنة يهودية مكونة من 200 وحدة سكنية.",
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: { ...hiddenMeshes, kurd_house_2000: "sealed" },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 24,
          sensorSize: 36,
        },
      },
      {
        index: 9,
        cardText: {
          en: "Between 2008 and 2009, the occupation’s police forcibly evicted three families in the neighbourhood and supported settler incursions into the house extension of the Al-Kurd family.",
          ar: "بين عامي 2008 و2009، أجلت شرطة الاحتلال الإسرائيلي ثلاث عائلات من الحي بالقوة ودعمت توغل المستوطنين في توسعة منزل عائلة الكرد.",
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "umkamel_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 51.98,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "umkamel_cam_1.png",
        },
        imageLink: "https://www.youtube.com/watch?v=r392kT11xCc",
        imageCredit:
          "Al-Quds International Foundation - 'Um Kamel's Tent' (Film)",
      },
      {
        index: 10,
        cardText: {
          en: "This image from 2009 shows Rifqa Al-Kurd outside of her home. The family’s furniture was removed by the police.",
          ar: `هذه الصورة من عام 2009 تظهر "رفقة الكرد" خارج منزلها. نقلت الشرطة أثاث الأسرة خارج المنزل.`,
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "umkamel_cam_5",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 26,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "umkamel_cam_5.png",
        },
        springDelay: customPhotoMatchDelay,
        imageLink: "https://www.youtube.com/watch?v=r392kT11xCc",
        imageCredit:
          "Al-Quds International Foundation - 'Um Kamel's Tent' (Film)",
      },
      {
        index: 11,
        cardText: {
          en: "Since 2009, the family has been forced to share their house, and use the same entrance, with changing groups of settlers brought in by Nahalat Shimon and other similar organisations.\nOne of these Jewish settlers appears in a video telling Muna, ‘If I don’t steal it [your home], someone else will steal it’.",
          ar: `منذ عام 2009، أُجبرت العائلة على تقاسم منزلها، واستخدام نفس المدخل، مع  مجموعات المستوطنين التي جلبتها "نحلات شمعون" وغيرها من الجمعيات.\ىيظهر أحد هؤلاء المستوطنين اليهود في مقطع فيديو يقول لـ "منى": "إذا لم أسرقه [منزلك] أنا، فسوف يسرقه شخص آخر".`,
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "muna_settler_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
          threeMatch: BASE_URL + "images/" + "muna_settler_cam.jpg",
        },
        springDelay: customPhotoMatchDelay,
        imageLink:
          "https://www.instagram.com/reel/COX3KPHHx9p/?igshid=YmMyMTA2M2Y=",
        imageCredit: "Instagram @muna.kurd15",
      },
      {
        index: 12,
        cardText: {
          en: "Moreover, groups of Jewish settlers have organised invasive tours in the neighbourhood—and even inside the Al-Kurd home—meant to harass Palestinian residents into leaving their homes.",
          ar: `علاوة على ذلك، نظمت مجموعات من المستوطنين اليهود جولات في الحي -وحتى داخل منزل الكرد- بهدف مضايقة السكان الفلسطينيين لحملهم على مغادرة منازلهم.`,
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "kurd_settlers_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 91.6452,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "kurd_settlers_cam.png",
          springDelay: customPhotoMatchDelay,
        },
        // camera3: {
        //   type: "blender",
        //   name: "kurd_house_tourists_2010_cam",
        //   modelFile: "2022.06.15_ShJ.glb",
        //   focalLength: 38.4,
        //   sensorSize: 36,
        //   threeMatch: BASE_URL + "images/" + "kurd_house_tourists_2010_cam.jpg",
        // },
        imageLink: "https://www.youtube.com/watch?v=ZH369AbxUpQ",
      },
      {
        index: 13,
        cardText: {
          en: "On 28 October 2020, the occupation’s Magistrate Court accepted a petition brought by Nahalat Shimon to evict the Al-Kurd family from the rest of the house.\nEviction notices were also received by the Iskafi, Al-Qassim and the Al-Jauni families in the neighbourhood.",
          ar: `في 28 أكتوبر 2020، قبلت "محكمة الصلح" في القدس التماسًا تقدمت به "نحلات شمعون" لطرد عائلة الكرد من باقي المنزل.\nكما استلمت عائلات الإسكافي والقاسم والجاعوني في الحي إخطارات إخلاء.`,
        },
        toggleTitle: { en: "Further Reading", ar: "قراءة متعمقة" },
        toggleContent: {
          en: "“The Jerusalem Magistrate Court held that the Al Kurd, Jauni and Al Qassim families breached their protected tenancy obligations by not paying rent, and by adding illegal extensions to the property without the consent of Nahalat Shimon International. The families claimed that the registration of the Sheikh Jarrah land in favour of Nahalat Shimon Settler Company was illegal, and that, in the absence of any proven right to the company, they have no legal duty to pay rent or to seek its approval to conduct renovations in the property.”\n“In November 2018, the Israeli Supreme Court denied the right of Palestinian families to challenge the ownership in the Eastern area of Sheikh Jarrah, placing them under the immediate risk of eviction.”",
          ar: `"قضت محكمة الصلح في القدس بأن عائلات الكرد والجاعوني والقاسم انتهكت التزامات الإيجار المحمية من خلال عدم دفع الإيجار، وإضافة توسعات غير قانونية للممتلكات دون موافقة "نحلات شمعون" الدولية. زعمت العائلات أن تسجيل أرض الشيخ جراح لصالح شركة نحلات شمعون المستوطنة غير قانوني، وأنه في غياب أي حق مثبت للشركة، فليس عليهم واجب قانوني بدفع الإيجار أو طلب موافقتها على إجراء تجديدات في الممتلكات".\n"في نوفمبر 2018، حرمت المحكمة الإسرائيلية العليا العائلات الفلسطينية من حق في الطعن على الملكية في المنطقة الشرقية من حي الشيخ جراح، ما وضعهم تحت خطر الإخلاء الفوري".`,
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,

        labels3D: {
          kurd_house_1954: { en: "Al-Kurd", ar: "دار الكرد" },
          kurd_house_2000: { en: "Al-Kurd Extention", ar: "توسعة دار الكرد" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          kurd_house_1954: "decision",
          kurd_house_2000: "occupied",
        },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 32,
          sensorSize: 36,
        },
      },
      {
        index: 14,
        cardType: "section",
        images: [
          {
            url: BASE_URL + "content/" + "aj_2.jpg",
            caption: `Image: AJ+ - 'The Israeli Authorities Want To Kick Us Out Of Our Own Home'`,
          },
          {
            url: BASE_URL + "content/" + "aj_3.jpg",
            caption: `Image: AJ+ - 'The Israeli Authorities Want To Kick Us Out Of Our Own Home'`,
          },
        ],
        cardText: {
          en: "The ruling ordered them to vacate their homes within 30 days, or face forced eviction on 2 May 2021.\nThe court also ordered the family to pay 70,000 NIS (around 21,000 USD) to Nahalat Shimon in legal costs.",
          ar: `أمرهم الحكم بإخلاء منازلهم في غضون 30 يومًا، أو مواجهة الإخلاء القسري في 2 مايو 2021.\nكما أمرت المحكمة الأسرة بدفع 70 ألف شيكل (حوالي 21 ألف دولار أمريكي) لـ"نحلات شمعون" كتكاليف قانونية.`,
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        labels3D: {
          kurd_house_1954: { en: "Al-Kurd", ar: "دار الكرد" },
          kurd_house_2000: { en: "Al-Kurd Extention", ar: "توسعة دار الكرد" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          kurd_house_1954: "decision",
          kurd_house_2000: "occupied",
        },
        camera3: {
          type: "blender",
          name: "kurd_house_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 32,
          sensorSize: 36,
        },
      },
      {
        index: 15,
        cardText: {
          en: "The family and their neighbours started the #savesheikhjarrah campaign to protect their homes. They act collectively as the Sheikh Jarrah committee, and have been resisting displacement for decades.\nThe street where the families live has become a prominent site of solidarity and struggle in occupied Jerusalem.",
          ar: `بدأت الأسرة وجيرانها حملة "أنقذوا حي الشيخ جراح" لحماية منازلهم. وتصرفوا بشكل جماعي بوصفهم لجنة حي الشيخ جراح التي تقاوم الإخلاء القسري منذ عقود.\nأصبح الشارع الذي تعيش فيه العائلات موقعًا بارزًا للتضامن والنضال في القدس المحتلة.`,
        },
        notes: "[3D] Fade out the photo-matched frame, keep the 3d model",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "house_to_street_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 20.5161,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "house_to_street_cam.jpeg",
        },
        imageLink:
          "https://www.middleeasteye.net/news/israel-palestine-save-sheikh-jarrah-campaign",
        imageCredit: "Middle East Eye | Aseel Jundi",
      },
    ],
    layers: [],
    pins: [],
    labels: [],
    frames: [],
  },
  "/street": {
    slides: [
      {
        index: 1,
        chapterTitle: { en: "The Street", ar: "الشارع" },
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "house_to_street_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 20.5161,
          sensorSize: 36,
        },
        blackScreen: true,
      },
      {
        index: 1,
        chapterTitle: { en: "The Street", ar: "الشارع" },
        bounds: [35.229464982, 31.791046437, 35.231836416, 31.792183404],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "house_to_street_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 20.5161,
          sensorSize: 36,
        },
        blackScreen: true,
      },
      {
        index: 2,
        cardText: {
          en: "The Uthman Ibn Affan street in Sheikh Jarrah is the location of many Palestinian homes where families live under the threat of eviction . One of them, the Ghawi house, was first taken by settlers in 2009, and is still being occupied.",
          ar: `شارع عثمان بن عفان في الشيخ جراح هو موقع للعديد من المنازل الفلسطينية حيث تعيش العائلات تحت تهديد التهجير القسري. أحدها، منزل الغاوي، استولى عليه المستوطنون لأول مرة في عام 2009، ولا زالوا يحتلونه.`,
        },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to a photo-matched view at the street level",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "ghawi_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 25.3443,
          sensorSize: 38,
          threeMatch: BASE_URL + "images/" + "Ghawi_cam1.jpg",
        },
        imageLink: "https://www.bbc.co.uk/news/world-middle-east-57243631",
        imageCredit: "AFP | BBC",
      },
      {
        index: 3,
        cardText: {
          en: "Mariam Ghawi, who was forcibly expelled from her house in 2009 was among the protesters in the street.\nHere she holds a photograph of the interior of her home.",
          ar: `مريم الغاوي، التي طُردت قسرًا من منزلها عام 2009، كانت من بين المتظاهرين في الشارع.\nهنا تحمل صورة لمنزلها من الداخل.`,
        },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to a photo-match of the image showing Mariam Ghawi",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "ghawi_maryam_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 11.75,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "Ghawi_Maryam_Cam.jpg",
        },
        imageLink: "https://twitter.com/ayaisleemen/status/1398021369376432135",
        imageCredit: "Twitter @AyaIsleemEn",
      },
      {
        index: 4,
        cardText: {
          en: "The families residing on this street are routinely visited by Palestinians and supporters from Jerusalem and elsewhere.",
          ar: `تتم زيارة العائلات المقيمة في هذا الشارع بشكل روتيني من قبل الفلسطينيين والمتضامنين من القدس وأماكن أخرى.`,
        },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to a photo-matched view at the street level",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "ramadan_table_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 34.3479,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "ramadan_table_cam.jpg",
        },
        imageLink:
          "https://www.instagram.com/accounts/login/?next=/p/COx9ULFrQDS/",
        imageCredit: "Instagram @muna.kurd15",
      },
      {
        index: 5,
        cardText: {
          en: "The fact that settlers occupy the Ghawi family’s house is a pretext for the constant presence of a militarised police, who, together with settlers, regularly harass the residents.",
          ar: `إن احتلال المستوطنين لمنزل عائلة الغاوي هو ذريعة للوجود المستمر لشرطة عسكرية تقوم، مع المستوطنين، بمضايقة السكان بانتظام.`,
        },
        toggleTitle: { en: "Note", ar: "ملاحظة" },
        toggleContent: {
          en: "The layers of Israeli armed groups that govern Jerusalem include private security companies working for settlers, the municipal Jerusalem police, the border police, and the Israeli army. These groups form layers of a hyper-militarised policing that dominates and harasses Palestinian residents daily.",
          ar: `تتكون المجموعات المسلحة الإسرائيلية التي تحكم القدس من شركات أمنية خاصة تعمل لصالح المستوطنين، وشرطة بلدية القدس، وشرطة الحدود، والجيش الإسرائيلي. ويهيمن هذا المزيج من الشرطة ذات الطابع العسكري على السكان الفلسطينيين ويعمل على مضايقتهم يوميًا.`,
        },
        notes: "",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "ramadan_table_cam_3",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 14.43,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "ramadan_table_cam_3.jpg",
        },
        imageLink: "https://time.com/6047764/israeli-palestinian-violence/",
        imageCredit: "Time | Heidi Levine—Sipa USA",
      },

      {
        index: 6,
        cardText: {
          en: "Protesters are faced with stun grenades…",
          ar: "تتم مواجهة المتظاهرين بقنابل الصوت...",
        },
        notes: "",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "stun_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 52,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "stun_cam_1.png",
        },
        imageLink: "https://www.youtube.com/watch?v=J-lwXvDXGKI",
        imageCredit: `AJ+ | 'The Israeli Authorities Want To Kick Us Out Of Our Own Home'`,
      },
      {
        index: 7,
        cardText: {
          en: "High pressure water guns loaded with skunk water…",
          ar: "ورشاشات المياه ذات الضغط العالي المحملة بالمياه العادمة...",
        },
        notes: "",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "skunk_water_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 46.4102,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "skunk_water_cam.jpg",
        },
        imageLink: "https://www.youtube.com/watch?v=NzK3gA1uIJs",
        imageCredit: "Youtube | Bab Alamwd Alquds live",
      },
      {
        index: 8,
        cardText: { en: "Beatings…", ar: "والضرب..." },
        notes: "",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "beatings_cam_2",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 22.52,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "beatings_cam_2.jpg",
        },
        imageLink:
          "https://twitter.com/hasenegun/status/1393760260855406599?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed&ref_url=notion%3A%2F%2Fwww.notion.so%2FStreet-Videos-245ecc3c2f44470085d99fc723bd0d21",
        imageCredit: "Twitter @hasenegun",
      },

      {
        index: 9,
        cardText: { en: "And arrests.", ar: "والاعتقالات." },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to one photo-match or more showing arrests or beatings by the occupation forces",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "street_arrests_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 20.62,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "street_arrests_cam.jpg",
        },
        imageLink:
          "https://aohr.org.uk/قوات-الاحتلال-تعتدي-على-أهالي-الشيخ-جر/",
        imageCredit: "Arab Organisation for Human Rights in the UK",
      },
      {
        index: 10,
        cardText: {
          en: "Journalists covering the rising global reach of the campaign to #SaveSheikhJarrah were also beaten and arrested by police forces.",
          ar: "كما تعرض الصحفيون الذين يغطون الانتشار العالمي المتزايد لحملة أنقذوا حي الشيخ جراح للضرب والاعتقال من قبل قوات الشرطة.",
        },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to one photo-match or more showing arrests or beatings by the occupation forces",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "givara_arrest_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 26.75,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "givara_arrest_cam.jpg",
        },
        imageLink:
          "https://www.aljazeera.com/gallery/2021/6/6/the-moment-when-al-jazeera-journalist-was-arrested",
        imageCredit: "Al Jazeera | Oren Ziv",
      },
      // {
      //   index: 11,
      //   cardText: {
      //     en: "Settlers, too, harassed journalists, including the late Al Jazeera correspondent Shireen Abu Akleh, who was killed in Jenin on 11 May 2022.",
      //     ar: "",
      //   },
      //   notes: "",
      //   bounds: [35.2251, 31.787, 35.23765, 31.79494],
      //   show3D: true,
      //   highlightedMeshes: {
      //     ...hiddenMeshes,
      //   },
      //   camera3: {
      //     type: "blender",
      //     name: "shireen_cam_1",
      //     modelFile: "2022.06.15_ShJ.glb",
      //     focalLength: 26.8602,
      //     sensorSize: 36,
      //     threeMatch: BASE_URL + "images/" + "Shireen.jpg",
      //   },
      // },
      {
        index: 12,
        cardText: {
          en: "A checkpoint was put in place by the occupation forces at the end of the street to prevent protesters from reaching the street.",
          ar: "ونصبت قوات الاحتلال حاجزًا في نهاية الشارع لمنع المتظاهرين من الوصول إليه.",
        },
        notes: "",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "checkpoints_cam_4",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 66.1261,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "checkpoint_cam_4.png",
        },
        imageLink:
          "https://twitter.com/EShatnawe/status/1396477268361424899?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed&ref_url=notion%3A%2F%2Fwww.notion.so%2FStreet-Videos-245ecc3c2f44470085d99fc723bd0d21",
        imageCredit: "Twitter @EShatnawe",
      },

      {
        index: 13,
        cardText: {
          en: "The street is a microcosm of the broader struggle of Palestinian residents of the neighbourhood of Sheikh Jarrah and their supporters who daily confront one of the most overt forms of apartheid planning and settler colonial violence.",
          ar: "الشارع هو نموذج مصغر للنضال الأوسع لسكان حي الشيخ جراح الفلسطينيين وأنصارهم الذين يواجهون يوميًا أحد أكثر أشكال التخطيط العنصري العلني والعنف الاستعماري الاستيطاني.",
        },
        notes:
          "[3D] Camera 2022.06.15_ShJ.glb to one photo-match or more showing arrests or beatings by the occupation forces",
        bounds: [35.2251, 31.787, 35.23765, 31.79494],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
    ],
    layers: [],
    pins: [],
    labels: [],
    frames: [],
  },
  "/neighbourhood": {
    slides: [
      {
        index: 1,
        chapterTitle: {
          en: "The Neighbourhood: Sheikh Jarrah",
          ar: "الحي: الشيخ جراح",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 1,
        chapterTitle: {
          en: "The Neighbourhood: Sheikh Jarrah",
          ar: "الحي: الشيخ جراح",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      // {
      //   index: 0,
      //   cardText: {},
      //   bounds: [35.22093, 31.78035, 35.24924, 31.79827],
      //   show3D: true,
      //   highlightedMeshes: {
      //     ...hiddenMeshes,
      //   },
      //   camera3: {
      //     type: "blender",
      //     name: "street_cam_1",
      //     modelFile: "2022.06.15_ShJ.glb",
      //     focalLength: 30,
      //     sensorSize: 36,
      //   },
      // },
      {
        index: 2,
        cardText: {
          en: "Established in the late 19th century, the Sheikh Jarrah neighbourhood was a destination for upper class Jerusalemites, like the Al-Husseini family, who prefered to live outside the bustle of the Old City.\nKarm Al-Jaoni, where the families live, was agricultural land before the Nakba, as seen in this aerial image taken by the British Royal Air Force in 1931.",
          ar: "تم إنشاء حي الشيخ جراح في أواخر القرن التاسع عشر، وكان وجهة للطبقة الميسورة من المقدسيين، مثل عائلة الحسيني، الذين فضلوا العيش خارج صخب البلدة القديمة.\nكرم الجاعوني، حيث تعيش العائلات، كانت أرضًا زراعية قبل النكبة، كما يتضح من هذه الصورة الجوية التي التقطتها القوات الجوية الملكية البريطانية عام 1931.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "wikipedia_1931_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 72.8728,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "wikipedia_1931_cam.png",
        },
        imageLink: "https://www.loc.gov/pictures/resource/matpc.22158/",
        imageCredit:
          "Libray of Congress | American Colony (Jerusalem). Photo Department",
      },
      {
        index: 3,
        cardText: {
          en: "When the western part of Jerusalem was occupied during the Nakba, Palestinians fled to the eastern part of the city, which came under Jordanian control in 1948.\nThat part of the city included the Sheikh Jarrah neighbourhood.",
          ar: "عندما تم احتلال الجزء الغربي من القدس خلال النكبة، فر الفلسطينيون إلى الجزء الشرقي من المدينة، الذي كان يخضع للسيطرة الأردنية عام 1948.\nوشمل ذلك الجزء من المدينة حي الشيخ جراح.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "wikipedia_1931_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 80,
          sensorSize: 36,
        },
      },
      {
        index: 4,
        cardText: {
          en: "In 1956, an agreement between UNRWA and the Jordanian government gave land to 28 families of Palestinian refugees, together with permission to build houses in Sheikh Jarrah, in return for relinquishing their refugee status. The Al-Kurds are one of these families.",
          ar: "في عام 1956، أعطت اتفاقية بين الأونروا والحكومة الأردنية أراضٍ لـ28 عائلة من اللاجئين الفلسطينيين، بالإضافة إلى تصريح لبناء منازل في الشيخ جراح، مقابل التنازل عن وضع اللاجئ. عائلة الكرد هي إحدى هذه العائلات.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          shanti: { en: "Al-Shanti", ar: "دار الشنطي" },
          // 2008/2009
          ghawi: { en: "Ghawi", ar: "دار غاوي" },
          umkamel: { en: "Um Kamel", ar: "دار أم كامل" },
          hanoon: { en: "Hannoun", ar: "دار حنون" },
          // kurd_house_2000:  {en:"Al-Kurd Extention", ar:"توسعة دار الكرد"},
          // 2021 May 2
          kurd_house_1954: { en: "Al-Kurd", ar: "دار الكرد" },
          jaoni: { en: "Al-Jaoni", ar: "دار الجاعوني" },
          skafi: { en: "Skafi", ar: "دار سكافي" },
          qasem: { en: "Qasem", ar: "دار قاسم" },
          // 2021 Aug 2
          dajani: { en: "Al-Dajani", ar: "دار الدجاني" },
          daoodi: { en: "Al-Dauodi", ar: "دار الداوودي" },
          sabagh: { en: "Sabagh", ar: "دار الصباغ" },
          hmad: { en: "Hammad", ar: "دار حماد" }, // sure?
          // rest
          abdulatif: { en: "Abd Al-Latif", ar: "دار عبد اللطيف" },
          abuarfa: { en: "Abu Arafeh", ar: "دار أبو عرفة" },
          attiah: { en: "Attiyeh", ar: "دار عطية" },
          fityani: { en: "Fityani", ar: "دار الفتياني" },
          ghosha: { en: "Ghosha", ar: "دار غوشة" },
          hjajeej: { en: "Hajeej", ar: "دار حجيج" },
          husseini: { en: "Hussieni", ar: "دار الحسيني" },
          kawasmi: { en: "Qawasmi", ar: "دار القواسمي" },
          khatib: { en: "Al-Khatib", ar: "دار الخطيب" },
          mani: { en: "Al-Mani", ar: "دار الماني" },
          nilsiba: { en: "Nissiba", ar: "دار نسيبة" },
          oydah: { en: "Oydah", ar: "دار عودة" },
          slaimah: { en: "Slaymeh", ar: "دار السلايمة" },
          thiyab: { en: "Thiyab", ar: "دار ذياب" },
          zamamiri: { en: "Zamamiri", ar: "دار الزماميري" },
          zein: { en: "Zein", ar: "دار الزين" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          shanti: "built",
          ghawi: "built",
          umkamel: "built",
          hanoon: "built",
          kurd_house_2000: "hide",
          kurd_house_1954: "built",
          jaoni: "built",
          skafi: "built",
          qasem: "built",
          dajani: "built",
          daoodi: "built",
          hmad: "built",
          sabagh: "built",
          abdulatif: "built",
          abuarfa: "built",
          attiah: "built",
          fityani: "built",
          ghosha: "built",
          hjajeej: "built",
          husseini: "built",
          kawasmi: "built",
          khatib: "built",
          mani: "built",
          nilsiba: "built",
          oydah: "built",
          slaimah: "built",
          thiyab: "built",
          zamamiri: "built",
          zein: "built",
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 5,
        cardType: "section",
        images: [
          {
            url: BASE_URL + "content/" + "mee_agreement_2.jpg",
            caption: `Image: MEE - 'Explained: What is happening in Sheikh Jarrah?'`,
          },
          {
            url: BASE_URL + "content/" + "mee_agreement_1.jpg",
            caption: `Image: MEE - 'Explained: What is happening in Sheikh Jarrah?'`,
          },
        ],
        cardText: {
          en: "The agreement stipulated that full ownership of this land would be transferred to the families in 1959.",
          ar: "نصت الاتفاقية على نقل الملكية الكاملة لهذه الأرضي إلى العائلات في عام 1959.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          shanti: { en: "Al-Shanti", ar: "دار الشنطي" },
          // 2008/2009
          ghawi: { en: "Ghawi", ar: "دار غاوي" },
          umkamel: { en: "Um Kamel", ar: "دار أم كامل" },
          hanoon: { en: "Hannoun", ar: "دار حنون" },
          // kurd_house_2000:  {en:"Al-Kurd Extention", ar:"توسعة دار الكرد"},
          // 2021 May 2
          kurd_house_1954: { en: "Al-Kurd", ar: "دار الكرد" },
          jaoni: { en: "Al-Jaoni", ar: "دار الجاعوني" },
          skafi: { en: "Skafi", ar: "دار سكافي" },
          qasem: { en: "Qasem", ar: "دار قاسم" },
          // 2021 Aug 2
          dajani: { en: "Al-Dajani", ar: "دار الدجاني" },
          daoodi: { en: "Al-Dauodi", ar: "دار الداوودي" },
          sabagh: { en: "Sabagh", ar: "دار الصباغ" },
          hmad: { en: "Hammad", ar: "دار حماد" }, // sure?
          // rest
          abdulatif: { en: "Abd Al-Latif", ar: "دار عبد اللطيف" },
          abuarfa: { en: "Abu Arafeh", ar: "دار أبو عرفة" },
          attiah: { en: "Attiyeh", ar: "دار عطية" },
          fityani: { en: "Fityani", ar: "دار الفتياني" },
          ghosha: { en: "Ghosha", ar: "دار غوشة" },
          hjajeej: { en: "Hajeej", ar: "دار حجيج" },
          husseini: { en: "Hussieni", ar: "دار الحسيني" },
          kawasmi: { en: "Qawasmi", ar: "دار القواسمي" },
          khatib: { en: "Al-Khatib", ar: "دار الخطيب" },
          mani: { en: "Al-Mani", ar: "دار الماني" },
          nilsiba: { en: "Nissiba", ar: "دار نسيبة" },
          oydah: { en: "Oydah", ar: "دار عودة" },
          slaimah: { en: "Slaymeh", ar: "دار السلايمة" },
          thiyab: { en: "Thiyab", ar: "دار ذياب" },
          zamamiri: { en: "Zamamiri", ar: "دار الزماميري" },
          zein: { en: "Zein", ar: "دار الزين" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          shanti: "built",
          ghawi: "built",
          umkamel: "built",
          hanoon: "built",
          kurd_house_2000: "hide",
          kurd_house_1954: "built",
          jaoni: "built",
          skafi: "built",
          qasem: "built",
          dajani: "built",
          daoodi: "built",
          hmad: "built",
          sabagh: "built",
          abdulatif: "built",
          abuarfa: "built",
          attiah: "built",
          fityani: "built",
          ghosha: "built",
          hjajeej: "built",
          husseini: "built",
          kawasmi: "built",
          khatib: "built",
          mani: "built",
          nilsiba: "built",
          oydah: "built",
          slaimah: "built",
          thiyab: "built",
          zamamiri: "built",
          zein: "built",
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
        imageLink: "https://www.youtube.com/watch?v=Jp5wEdBBCfc&t=80s",
      },
      {
        index: 6,
        cardText: {
          en: "But the ownership had not yet been transferred by the time the eastern part of Jerusalem was occupied and unilaterally annexed during the 1967 war.\nIn 2020, a UN Special Rapporteur explained: “Despite having fulfilled the terms of the contract, the families did not receive the legal titles to the land.”",
          ar: `لكن لم يتم نقل الملكية بعد بحلول الوقت الذي تم فيه احتلال الجزء الشرقي من القدس وضمه من جانب واحد خلال حرب 1967.\nفي عام 2020، أوضح مقرر خاص للأمم المتحدة: "على الرغم من استيفاء شروط العقد، لم تحصل العائلات على صكوك الملكية القانونية للأرض".`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
          shanti: "built",
          ghawi: "built",
          umkamel: "built",
          hanoon: "built",
          // kurd_house_2000: "hide",
          kurd_house_1954: "built",
          jaoni: "built",
          skafi: "built",
          qasem: "built",
          dajani: "built",
          daoodi: "built",
          hmad: "built",
          sabagh: "built",
          abdulatif: "built",
          abuarfa: "built",
          attiah: "built",
          fityani: "built",
          ghosha: "built",
          hjajeej: "built",
          husseini: "built",
          kawasmi: "built",
          khatib: "built",
          mani: "built",
          nilsiba: "built",
          oydah: "built",
          slaimah: "built",
          thiyab: "built",
          zamamiri: "built",
          zein: "built",
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 7,
        cardText: {
          en: "Immediately after the 1967-war, Israel began to encourage Jews to settle in the eastern part of the city in an attempt to ‘unify’ it under Israeli rule.\nSettlers invaded the house of the Al-Shanti family while they were abroad, displacing them for the second time.",
          ar: `مباشرة بعد حرب 1967، بدأت إسرائيل في تشجيع اليهود على الاستيطان في الجزء الشرقي من المدينة في محاولة "لتوحيدها" تحت الحكم الإسرائيلي.\nاقتحم المستوطنون منزل عائلة الشنطي أثناء تواجدهم في الخارج وهجّروهم للمرة الثانية.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        labels3D: { shanti: { en: "Al-Shanti", ar: "دار الشنطي" } },
        highlightedMeshes: { ...hiddenMeshes, shanti: "occupied" },
        show3D: true,
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 8,
        cardType: "section",
        images: [
          {
            url: BASE_URL + "content/" + "absentee_properties.png",
            caption: "",
          },
        ],
        cardText: {
          en: "In 1970 Israel claimed for itself all lands which before 1948 were owned by Jewish inhabitants. At the same time they did not allow Palestinian refugees to claim their properties lost in 1948, which remained under the state’s “Absentees Properties Law”.",
          ar: `في عام 1970 طالبت إسرائيل لنفسها بجميع الأراضي التي كانت مملوكة لسكان يهود قبل عام 1948. في الوقت نفسه، لم يُسمح للاجئين الفلسطينيين بالمطالبة بممتلكاتهم التي فقدوها في عام 1948، والتي ظلت تحت "قانون أملاك الغائبين".`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: { ...hiddenMeshes, shanti: "occupied" },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 9,
        cardText: {
          en: "In 1972, based on the ‘Legal and Administrative Matters’ Law that only allows Jews to claim for land owned prior to 1948, settler groups claimed ownership over a number of houses in Sheikh Jarrah and began their efforts to expel Palestinian families from them.",
          ar: `في عام 1972، بناءً على قانون "المسائل القانونية والإدارية"  الذي يسمح لليهود فقط بالمطالبة بأرضٍ كانت مملوكة قبل عام 1948، ادعت مجموعات المستوطنين ملكية عدد من المنازل في حي الشيخ جراح وبدأت جهودها لطرد العائلات الفلسطينية منها.`,
        },
        // toggleTitle: { en: "Further Reading", ar: "قراءة متعمقة" },
        // toggleContent: {
        //   en: "The Ottoman documents provided by the settler groups “turned out to be a temporary land use document for Provisional for 3 years, dated 1891”, they managed to primarily register the land at the Israeli Land Registry Office.",
        //   ar: "",
        // },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        // labels3D: {
        //   shanti: {en: "Al-Shanti", ar: "دار الشنطي"},
        //   ghawi:  {en: "Ghawi", ar: "دار غاوي"},
        //   umkamel: {en: "Um Kamel", ar: "دار أم كامل"},
        //   hanoon: {en: "Hannoun", ar: "دار حنون"},
        // },
        highlightedMeshes: {
          ...hiddenMeshes,
          kurd_house_2000: "risk", // or hide
          shanti: "occupied",
          ghawi: "risk",
          umkamel: "risk",
          hanoon: "risk",
          kurd_house_1954: "risk",
          jaoni: "risk",
          skafi: "risk",
          qasem: "risk",
          dajani: "risk",
          daoodi: "risk",
          sabagh: "risk",
          hmad: "risk", // sure?
          // they should be 17 houses!!
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 10,
        cardText: {
          en: "In 1982, the ownership status of the 28 Palestinian families in Karm Al-Jaoni was not recognised and they were designated merely as tenants (‘protected tenants’) without ownership claims for their properties.\nSince then, these families have been in a protracted legal battle with the Israeli courts and various settler groups, the latest being Nahalat Shimon.",
          ar: `في عام 1982، لم يتم الاعتراف بوضع ملكية 28 عائلة فلسطينية في كرم الجاعوني وتم تصنيفهم فقط كمستأجرين ("مستأجرين محميين") دون حقوق ملكية لممتلكاتهم.\nمنذ ذلك الحين، دخلت هذه العائلات في معركة قانونية مطولة ضد المحاكم الإسرائيلية ومجموعات مستوطنين مختلفة، وآخرها "نحلات شمعون".`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          kurd_house_2000: "risk", // or hide
          ghawi: "risk",
          umkamel: "risk",
          hanoon: "risk",
          kurd_house_1954: "risk",
          jaoni: "risk",
          skafi: "risk",
          qasem: "risk",
          dajani: "risk",
          daoodi: "risk",
          sabagh: "risk",
          hmad: "risk", // sure?
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 11,
        cardText: {
          en: "Between 2008 and 2009, the Israeli police forcibly expelled the Ghawi, Um Kamel, and Hannoun. families—themselves refugees from 1948 Palestine.",
          ar: "بين عامي 2008 و2009، طردت الشرطة الإسرائيلية بالقوة عائلات الغاوي وأم كامل وحنون؛ وهم لاجئون من فلسطين 1948.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          ghawi: { en: "Ghawi", ar: "دار غاوي" },
          umkamel: { en: "Um Kamel", ar: "دار أم كامل" },
          hanoon: { en: "Hannoun", ar: "دار حنون" },
          kurd_house_2000: { en: "Al-Kurd Extention", ar: "توسعة دار الكرد" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          // 2021 Aug 2
          dajani: "risk",
          daoodi: "risk",
          sabagh: "risk",
          hmad: "risk", // sure?
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 12,
        cardText: {
          en: "Within minutes of the eviction, the Israeli police helped settlers invade and occupy the houses.\nIt was at that time that settlers also invaded the Al-Kurd house extension, which had been sealed since 2000.",
          ar: "خلال دقائق من الإخلاء القسري، ساعدت شرطة الاحتلال المستوطنين في اقتحام المنازل واحتلالها.\nفي ذلك الوقت، اقتحم المستوطنون أيضًا توسعة منزل الكرد الذي كان مغلقًا منذ عام 2000.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
        },
        camera3: {
          type: "blender",
          name: "umkamel_cam_2",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 33.7,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "umkamel_cam_2.png",
        },
        imageLink: "https://www.youtube.com/watch?v=r392kT11xCc",
        imageCredit:
          "Al-Quds International Foundation - 'Um Kamel's Tent' (Film)",
      },
      {
        index: 13,
        cardText: {
          en: "In November 2008, Um Kamel, who in 1948 was forcibly displaced together with her family from the Jerusalem neighbourhood of Talbieh, was displaced once more from her home in Sheikh Jarrah.",
          ar: `في نوفمبر 2008، تم تهجير "أم كامل"، التي نزحت عام 1948 مع عائلتها من حي الطالبية في القدس، مرة أخرى من منزلها في الشيخ جراح.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: { ...hiddenMeshes, umkamel: "occupied" },
        camera3: {
          type: "blender",
          name: "um_kamel_transition_1", // photomatch camera
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 50,
          sensorSize: 36,
        },
      },
      {
        index: 14,
        cardText: {
          en: "During this displacement, the occupation forces left her disabled husband, Abu Kamel, in the house’s front yard.\nAbu Kamel had a heart attack and passed away a week after.",
          ar: `وأثناء عملية الطرد، تركت قوات الاحتلال زوجها المقعد أبو كامل في الباحة الأمامية للمنزل.\nأصيب أبو كامل بنوبة قلبية وتوفي بعد أسبوع.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: { ...hiddenMeshes },
        camera3: {
          type: "blender",
          name: "umkamel_cam_4", // photomatch camera
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 49.1174,
          sensorSize: 41,
          threeMatch: BASE_URL + "images/" + "umkamel_cam_4.png",
        },
        imageLink: "https://www.youtube.com/watch?v=r392kT11xCc",
        imageCredit:
          "Al-Quds International Foundation - 'Um Kamel's Tent' (Film)",
      },
      {
        index: 15,
        cardText: {
          en: "Um Kamel started a long campaign to  regain ownership of her home.\nShe erected a solidarity tent on this plot of land belonging to the Obiedat family.",
          ar: "بدأت أم كامل حملة طويلة لاستعادة ملكية منزلها.\nنصبت خيمة تضامن على قطعة الأرض هذه التابعة لعائلة عبيدات.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          obaidat: { en: "Obaidat Land", ar: "أرض عائلة عبيدات" },
          umkamel: { en: "Um Kamel", ar: "دار أم كامل" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          obaidat: "mark",
          umkamel: "occupied",
        },
        camera3: {
          type: "blender",
          name: "um_kamel_transition_2", // photomatch camera
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 50,
          sensorSize: 36,
        },
      },

      {
        index: 16,
        cardText: {
          en: "The occupation forces have demolished her solidarity tent six times.\nAnd the Obiedat family were continually harrassed.",
          ar: "وقد هدمت قوات الاحتلال خيمتها التضامنية ست مرات.\nوكانت عائلة عبيدات تتعرض للمضايقات باستمرار.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: { ...hiddenMeshes },
        camera3: {
          type: "blender",
          name: "umkamel_tent_cam", // photomatch camera
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "umkamel_tent_cam.png",
        },
        imageLink:
          "https://www.facebook.com/Sheikh.Jarrah.units/photos/bc.AbovWDRpdIUj7qG5VPLfXl7D9q20peK-A7tX7RlNoW2paroIqe27Ed4FZa0B68Z16dZWfzyBxv1T4dbtjNbxJgCXE047JrBCLjari5_ca6UFpq6Mie-i9hjrjsIR48D98R-26F5k0dLeyGsvUefC3_y6QItVquZK28cZhjuoEDA2nxZHcyXsgd1Q60f5xmcUKh3KidI6kZ0-EFN32wFegOm2Ae1runhUAjTehgnxdl6vkmenoaid0BUCIGmQpA6U9A0Pa338wVKGnxlaxac5tyrsvqnY1K-77SZdHgPbZjB2fQ/280172570576799/?opaqueCursor=Abr1k9J5SRteveOVlEY1Pd7fmMrjMrhHnhPKu-q0DtdWjbhJxrIWpfjJrb8o937HMdBD5N8l-ikcVFVlBdDlqkWtvXGfYMmdC-SRu6J-cjtMkpVOGVxNPSjxwkOTI6awP0C9QC8BIXu6c4UK2U9lmYfJ6ve9bhH2N1VQCPIBhtY5lSP4XjqHeUpKZWe8dtEp0jL5rUUppiUCIlg5oeQoS7X33d8ex4dR6TkJrKklRC0WUfsSgZhC25aY2TQax2iDGZD_sFQSxTUun8McFDAE92uDYmJtMRGdkVoHYNTIwHhwB8EOjCYVeijygaCKsOxHt8Ndg0COUiB80-y1lxJX1uoTJwWST82NxKpZn211Ft1HXTkgmRQlTRrS5L9MTA7CwGE1PCiAtHLXDBHjEJRuYCmMKyRQXFSGDxKsMl7UB-EJHwInm-NPIEwDQMx1LLZCYgZnJAef6jLqPG7EmQHwYZItyuivzC6XoCD1oWxNqQXsprduy_zQflwIKEECU4aa-6iqLuVsZSoIBWpnSRzhTFWlRGtQ0I7DKHyNj0MYA8Llxji6iiwsFRxi1AV895AdVYDaSofEbonRupCIfNli_zS3T-w_WD-A7sJIQJAQWJq_1g",
        imageCredit: "Facebook @Sheikh.Jarrah.units",
      },
      {
        index: 17,
        cardText: {
          en: "Settlers still occupy the homes of Ghawi, Hannoun, and Um Kamel, and the Al-Kurd house extension.",
          ar: "ولا يزال المستوطنون يحتلون منازل الغاوي وحنون وأم كامل وتوسعة منزل الكرد.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          kurd_house_2000: { en: "Al-Kurd Extention", ar: "توسعة دار الكرد" },
          ghawi: { en: "Ghawi", ar: "دار غاوي" },
          umkamel: { en: "Um Kamel", ar: "دار أم كامل" },
          hanoon: { en: "Hannoun", ar: "دار حنون" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          // 2021 Aug 2
          dajani: "risk",
          daoodi: "risk",
          sabagh: "risk",
          hmad: "risk", // sure?
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 18,
        cardText: {
          en: "On 8 October 2020, the occupation’s Magistrate Court granted Nahalat Shimon’s petition  to evict the Al Kurd, Iskafi, Al-Qassim  and the Al-Jauni families.\nThe court ordered the families to leave their homes by 2 May 2021 and required each to pay the settlers’ legal expenses.",
          ar: `في 8 أكتوبر 2020، وافقت محكمة الصلح في القدس على التماس "نحلات شمعون" لطرد عائلات الكرد والإسكافي والقاسم والجاعوني.\nأمرت المحكمة العائلات بمغادرة منازلهم بحلول 2 مايو 2021 وطالبتهم بدفع المصاريف القانونية للمستوطنين.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          kurd_house_1954: { en: "Al-Kurd", ar: "دار الكرد" },
          jaoni: { en: "Al-Jaoni", ar: "دار الجاعوني" },
          skafi: { en: "Skafi", ar: "دار سكافي" },
          qasem: { en: "Qasem", ar: "دار قاسم" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          kurd_house_1954: "decision",
          jaoni: "decision",
          skafi: "decision",
          qasem: "decision",
          // 2021 Aug 2
          dajani: "risk",
          daoodi: "risk",
          sabagh: "risk",
          hmad: "risk", // sure?
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },

      {
        index: 19,
        cardText: {
          en: "Similarly, on 4 September 2020, the  court issued an ‘eviction’ order against the Al-Daoudi, Dajani and Hammad families.\nThe court ordered them to leave their homes by 1 August 2021 and required each family to pay the settlers’ legal expenses.",
          ar: "وبالمثل، أصدرت المحكمة في 4 سبتمبر 2020، أمر إجلاء بحق عائلات الداوودي والدجاني وحماد.\nأمرتهم المحكمة بمغادرة منازلهم بحلول 1 أغسطس 2021 وطالبت كل أسرة بدفع المصاريف القانونية للمستوطنين.",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          dajani: { en: "Al-Dajani", ar: "دار الدجاني" },
          daoodi: { en: "Al-Dauodi", ar: "دار الداوودي" },
          sabagh: { en: "Sabagh", ar: "دار الصباغ" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          kurd_house_1954: "decision",
          jaoni: "decision",
          skafi: "decision",
          qasem: "decision",
          // 2021 Aug 2
          dajani: "decision",
          daoodi: "decision",
          sabagh: "decision",
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 20,
        cardText: {
          en: "The families started a solidarity campaign that reached its peak in May 2021 as the ‘eviction’ date became imminent.\nTheir struggle was echoed by a wave of protests and repression that culminated in the May 2021 attacks on Gaza.",
          ar: ` بدأت العائلات حملة تضامن وصلت ذروتها في مايو 2021 مع اقتراب موعد "الإخلاء".\nوتردد صدى نضالهم في موجة الاحتجاجات والقمع الواسعة التي بلغت ذروتها في هجمات مايو 2021 على غزة.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          kurd_house_2000: "occupied",
          kurd_house_1954: "decision",
          jaoni: "decision",
          skafi: "decision",
          qasem: "decision",
          // 2021 Aug 2
          dajani: "decision",
          daoodi: "decision",
          sabagh: "decision",
          hmad: "risk", // sure?
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },

      {
        index: 21,
        cardText: {
          en: "Following local protests and global outrage, on 1 March 2022, the occupation’s Supreme Court  temporarily annulled the Magistrate Court’s ruling against the Al Kurd, Iskafi, Al-Qassim  and the Al-Jauni families.",
          ar: `بعد الاحتجاجات المحلية والغضب العالمي، في 1 مارس 2022، ألغت المحكمة العليا للاحتلال حكم محكمة الصلح ضد عائلات الكرد والإسكافي والقاسم والجاعوني.`,
        },

        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          kurd_house_1954: { en: "Al-Kurd", ar: "دار الكرد" },
          jaoni: { en: "Al-Jaoni", ar: "دار الجاعوني" },
          skafi: { en: "Skafi", ar: "دار سكافي" },
          qasem: { en: "Qasem", ar: "دار قاسم" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 22,
        cardType: "section",
        images: [
          {
            url: BASE_URL + "content/" + "families_statement.jpeg",
            caption: "Image: Twitter @m7mdkurd",
          },
        ],
        cardText: {
          en: "The families stated that: “The Occupation's Supreme Court would not have ruled in this direction had it not been for the thousands of Palestinians who participated in the ‘Unity Uprising’, and the global movement against ethnic cleansing in Palestine.”",
          ar: `ذكرت العائلات أن: "المحكمة العليا للاحتلال لم تكن لتصدر حكمًا في هذا الاتجاه لولا آلاف الفلسطينيين الذين شاركوا في انتفاضة الوحدة، والحركة العالمية ضد التطهير العرقي في فلسطين".`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
        },
        camera3: {
          type: "blender",
          name: "street_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 30,
          sensorSize: 36,
        },
      },
      {
        index: 23,
        cardText: {
          en: "Invasion of homes and legal petitions by settler groups are only some of the ways that Palestinians are displaced from Sheikh Jarrah.",
          ar: `اقتحام المنازل والالتماسات القانونية من قبل مجموعات المستوطنين ليست سوى بعض الطرق التي يتم بها تهجير الفلسطينيين من الشيخ جراح.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
        },
        camera3: {
          type: "blender",
          name: "neighborhood_view_2",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 50,
          sensorSize: 36,
        },
      },
      {
        index: 24,
        cardText: {
          en: "In November 2021, the plot of land where Um Kamel built her solidarity tent was seized by the occupation’s municipality purportedly for a public park [urban development].",
          ar: `في نوفمبر 2021، تمت مصادرة قطعة الأرض التي شيدت فيها "أم كامل" خيمتها التضامنية، على ما يُزعم أنها حديقة عامة [التطوير الحضري].`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          obaidat: { en: "Obaidat Land", ar: "أرض عائلة عبيدات" },
          planned_hotel: { en: "Planned Hotel", ar: "مخطط لبناء فندق" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          obaidat: "occupied",
          planned_hotel: "hide", // or something else?
        },
        camera3: {
          type: "blender",
          name: "neighborhood_view_2",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 50,
          sensorSize: 36,
        },
      },
      {
        index: 25,
        cardText: {
          en: "The Salhiyeh family, refugees from the western part of occupied Jerusalem, have been fighting a second displacement for 25 years.\nThe occupation’s municipality announced plans to build a school on a property the family says they bought before the 1967 war.",
          ar: `عائلة الصالحية، لاجئون من الجزء الغربي من القدس المحتلة، يحاربون عملية تهجير ثانية منذ 25 عامًا.\nأعلنت بلدية الاحتلال عن خطط لبناء مدرسة على عقار تقول العائلة إنها اشترته قبل حرب 1967.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: { karm_mufti: { en: "Karm Al-Mufti", ar: "كرم المفتي" } },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          salhiya: "occupied",
        },
        camera3: {
          type: "blender",
          name: "salhiye_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 50,
          sensorSize: 36,
        },
      },
      {
        index: 26,
        cardText: {
          en: "For days, the family resisted the imminent demolition of their home.\nStanding on their rooftop, they sought to hold off the bulldozers on demolition day.",
          ar: `لأيام ، قاومت الأسرة الهدم الوشيك لمنزلهم.\nسعوا لصد الجرافات يوم الهدم بالوقوف على سطح منزلهم.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: { karm_mufti: { en: "Karm Al-Mufti", ar: "كرم المفتي" } },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
        },
        camera3: {
          type: "blender",
          name: "salhiya_cam_1",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 55.1416,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "salhiya_cam_1.JPG",
        },
        imageLink:
          "https://nabd.com/s/99076043-cfeabe/عائلة-صالحية-تعد-التماسا-للجنائية-الدولية-حول-هدم-منزلهم-بالشيخ-جراح",
        imageCredit: "Sawa News Agency",
      },
      {
        index: 27,
        cardText: {
          en: "Later, during the night of 19 January 2022, while the family was asleep, the occupation forces raided the house, expelled the family and demolished the building.\nWhen members of the family resisted, they were arrested.",
          ar: `ليلة 19 يناير 2022، وبينما كانت العائلة نائمة، داهمت قوات الاحتلال المنزل وطردت العائلة وهدمت المبنى.\nعندما قاوم أفراد الأسرة تم القبض عليهم.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "hide",
          interior_ministry: "hide",
          settlement1: "hide",
          settlement2: "hide",
          western_neighbourhood_shiekh_jarrah: "hide",
        },
        camera3: {
          type: "blender",
          name: "salhiya_cam_2",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 57.21,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "salhiya_cam_2.jpeg",
        },
        imageLink:
          "https://www.independent.co.uk/news/world/middle-east/sheikh-jarrah-israel-palestinian-family-b1996552.html",
        imageCredit: "Independent | AFP via Getty Images",
      },
      {
        index: 28,
        cardText: {
          en: "The Salhiyeh house was built on the base of an older structure from the 19th century, on land that belongs to the Al-Husseini family–known as Karm Al-Mufti.",
          ar: `تم بناء بيت الصالحية على أساس بناء قديم يعود إلى القرن التاسع عشر على أرض تعود لعائلة الحسيني المعروفة باسم كرم المفتي.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: { karm_mufti: { en: "Karm Al-Mufti", ar: "كرم المفتي" } },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "strong",
          salhiya: "hide",
        },
        camera3: {
          type: "blender",
          name: "salhiye_cam_2",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 50,
          sensorSize: 36,
        },
      },
      {
        index: 29,
        cardText: {
          en: "This image taken by the British Royal Air Force in 1933 shows the Al-Husseini palace. The building was confiscated after the 1967 occupation under the Absentee’s Properties Law.\nThe Al-Husseini family's repeated attempts to regain ownership over their land were denied.\nToday, the occupation’s municipality plans to build settlement units and a theme park on the land.",
          ar: `تظهر هذه الصورة التي التقطتها القوات الجوية الملكية البريطانية عام 1933 قصر الحسيني. تمت مصادرة المبنى بعد احتلال 1967 بموجب قانون أملاك الغائبين.\nباءت محاولات عائلة الحسيني المتكررة لاستعادة ملكية أراضيهم بالفشل.\nواليوم تخطط بلدية الاحتلال لبناء وحدات استيطانية ومنتزه على الأرض.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        labels3D: {
          karm_mufti: { en: "Karm Al-Mufti", ar: "كرم المفتي" },
          british_consulate: {
            en: "British Consulate",
            ar: "القنصلية البريطانية",
          },
          shepherd_hotel: { en: "Shepherd Hotel", ar: "فندق شبرد" },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "strong",
          shepherd_hotel: "strong",
          shepherd_hotel_building: "strong",
        },
        camera3: {
          type: "blender",
          name: "hussieni_1933_cam",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 19.8414,
          sensorSize: 36,
          threeMatch: BASE_URL + "images/" + "husseini_1933_cam.png",
        },
        imageLink:
          "https://www.loc.gov/pictures/resource/matpc.14309/?co=matpc",
        imageCredit:
          "Libray of Congress | American Colony (Jerusalem). Photo Department",
      },

      {
        index: 31,
        cardText: {
          en: "Other families in the western part of Sheikh Jarrah are also facing displacement by settler groups. Some of these houses are already occupied by setters.",
          ar: `كما تواجه عائلات أخرى في الجزء الغربي من حي الشيخ جراح خطر الإخلاء على يد مجموعات المستوطنين. بعض هذه المنازل مأهولة بالفعل من قبل المستوطنين.`,
        },
        labels3D: {
          western_neighbourhood_shiekh_jarrah: {
            en: "Western Neighbourhood",
            ar: "الحي الغربي",
          },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "highlight",
          shepherd_hotel: "highlight",
          shepherd_hotel_building: "highlight",
          western_neighbourhood_shiekh_jarrah: "strong",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        camera3: {
          type: "blender",
          name: "neighborhood_view_3",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 24,
          sensorSize: 36,
        },
      },
      {
        index: 32,
        cardText: {
          en: "Perforated from inside, the neighbourhood is being surrounded from the outside by Jewish settlements.",
          ar: `بالإضافة إلى تمزيقه من الداخل، الحي مطوق بالمستوطنات`,
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "highlight",
          shepherd_hotel: "highlight",
          shepherd_hotel_building: "highlight",
          // interior_ministry: "highlight",
          settlements: "strong",
          israeli_builtup_area_1: "strong",
          israeli_builtup_area_2: "strong",
          israeli_builtup_area_3: "strong",
          israeli_builtup_area_1_buildings: "strong",
          israeli_builtup_area_2_buildings: "strong",
          israeli_builtup_area_3_buildings: "strong",
          settlement2: "strong",
          // road1: "strong",
          // road2: "strong",
          western_neighbourhood_shiekh_jarrah: "highlight",
          /////
          // interior_ministry001: "highlight",
          // ministry_sci_tech: "highlight",
          // national_headquarters: "highlight",
          // regional_police_headquarters: "highlight",
          // russian_compound_police_station: "highlight",
          // social_security: "highlight",
          // border_police_base: "highlight",
          // border_police_base_buildings: "highlight",
          // ministry_public_security: "highlight",
          // western_neighbourhood_shiekh_jarrah: "highlight",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        camera3: {
          type: "blender",
          name: "neighborhood_view_3",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 24,
          sensorSize: 36,
        },
      },
      {
        index: 33,
        cardText: {
          en: "And by public buildings serving the occupation.",
          ar: `والمباني العامة التي تخدم الاحتلال.`,
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        labels3D: {
          interior_ministry001: {
            en: "Ministry of Interior",
            ar: "وزارة داخلية الاحتلال",
          },
          ministry_sci_tech: {
            en: "Ministry of Science and Technology",
            ar: "وزارة",
          },
          national_headquarters: { en: "National Headquarters", ar: "" },
          regional_police_headquarters: {
            en: "Regional Police Headquarters",
            ar: "",
          },
          russian_compound_police_station: {
            en: "Russian Compound Police station",
            ar: "",
          },
          social_security: { en: "Social Security", ar: "" },
          border_police_base: { en: "Border Police Base", ar: "" },
          ministry_public_security: {
            en: "Ministry of Public Security",
            ar: "",
          },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "highlight",
          shepherd_hotel: "highlight",
          shepherd_hotel_building: "highlight",
          interior_ministry: "strong",
          western_neighbourhood_shiekh_jarrah: "highlight",
          //////
          settlements: "strong",
          israeli_builtup_area_1: "strong",
          israeli_builtup_area_2: "strong",
          israeli_builtup_area_3: "strong",
          israeli_builtup_area_1_buildings: "strong",
          israeli_builtup_area_2_buildings: "strong",
          israeli_builtup_area_3_buildings: "strong",
          settlement2: "strong",
          /////
          interior_ministry001: "strong",
          ministry_sci_tech: "strong",
          national_headquarters: "strong",
          regional_police_headquarters: "strong",
          russian_compound_police_station: "strong",
          social_security: "strong",
          border_police_base: "strong",
          border_police_base_buildings: "strong",
          ministry_public_security: "strong",
        },
        show3D: true,
        // camera3: {
        //   type: "blender",
        //   name: "salhiye_cam_2",
        //   modelFile: "2022.06.15_ShJ.glb",
        //   focalLength: 50,
        //   sensorSize: 36,
        // },
        camera3: {
          type: "blender",
          name: "neighborhood_view_3",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 24,
          sensorSize: 36,
        },
      },

      {
        index: 34,
        cardText: {
          en: "Roads and light-rail lines designed to serve settlers further contribute to the fragmentation of the Palestinian built fabric.",
          ar: `الطرق وخطوط السكك الحديدية الخفيفة المصممة لخدمة المستوطنين تساهم بشكل أكبر في تفتيت نسيج المباني الفلسطينية.`,
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "highlight",
          shepherd_hotel: "highlight",
          shepherd_hotel_building: "highlight",
          interior_ministry: "highlight",
          settlements: "highlight",
          israeli_builtup_area_1: "highlight",
          israeli_builtup_area_2: "highlight",
          israeli_builtup_area_3: "highlight",
          israeli_builtup_area_1_buildings: "highlight",
          israeli_builtup_area_2_buildings: "highlight",
          israeli_builtup_area_3_buildings: "highlight",
          settlement2: "strong",
          road1: "strong",
          road2: "strong",
          western_neighbourhood_shiekh_jarrah: "highlight",
          /////
          interior_ministry001: "highlight",
          ministry_sci_tech: "highlight",
          national_headquarters: "highlight",
          regional_police_headquarters: "highlight",
          russian_compound_police_station: "highlight",
          social_security: "highlight",
          border_police_base: "highlight",
          border_police_base_buildings: "highlight",
          ministry_public_security: "highlight",
          western_neighbourhood_shiekh_jarrah: "highlight",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        camera3: {
          type: "blender",
          name: "neighborhood_view_3",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 24,
          sensorSize: 36,
        },
      },
      {
        index: 35,
        cardText: {
          en: "Open areas are designed as ‘no-build zones’ into which Palestinian neighbourhoods cannot expand, These areas can however be occupied by Jewish settlements.",
          ar: `تم تصميم المناطق المفتوحة على أنها "مناطق حظر بناء" لا يمكن للأحياء الفلسطينية التوسع فيها، ومع ذلك يمكن احتلال هذه المناطق بواسطة مستوطنات يهودية.`,
        },
        labels3D: {
          western_neighbourhood_shiekh_jarrah: {
            en: "Western Neighbourhood",
            ar: "الحي الغربي",
          },
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "highlight",
          shepherd_hotel: "highlight",
          shepherd_hotel_building: "highlight",
          interior_ministry: "highlight",
          settlements: "highlight",
          israeli_builtup_area_1: "highlight",
          israeli_builtup_area_1_buildings: "highlight",
          israeli_builtup_area_2: "highlight",
          israeli_builtup_area_2_buildings: "highlight",
          israeli_builtup_area_3: "highlight",
          israeli_builtup_area_3_buildings: "highlight",
          settlement2: "strong",
          road1: "strong",
          road2: "strong",
          western_neighbourhood_shiekh_jarrah: "highlight",
          /////
          interior_ministry001: "highlight",
          ministry_sci_tech: "highlight",
          national_headquarters: "highlight",
          regional_police_headquarters: "highlight",
          russian_compound_police_station: "highlight",
          social_security: "highlight",
          border_police_base: "highlight",
          border_police_base_buildings: "highlight",
          ministry_public_security: "highlight",
          western_neighbourhood_shiekh_jarrah: "strong",
          planned_parks_2: "mark",
        },
        bounds: [35.22093, 31.78035, 35.24924, 31.79827],
        show3D: true,
        camera3: {
          type: "blender",
          name: "neighborhood_view_3",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 24,
          sensorSize: 36,
        },
      },

      {
        index: 36,
        cardText: {
          en: "These same colonial practices of displacement, occupation, envelopment and containment are applied on a larger scale in the city of Jerusalem.",
          ar: `يتم تطبيق نفس الممارسات الاستعمارية المتمثلة في التهجير والاحتلال والتطويق والاحتواء على نطاق أوسع في مدينة القدس.`,
        },
        highlightedMeshes: {
          ...hiddenMeshes,
          ...highlightedMeshesCustom,
          Cube002: "occupied",
          obaidat: "occupied",
          karm_mufti: "highlight",
          shepherd_hotel: "highlight",
          shepherd_hotel_building: "highlight",
          interior_ministry: "highlight",
          settlements: "highlight",
          israeli_builtup_area_1: "highlight",
          israeli_builtup_area_2: "highlight",
          israeli_builtup_area_3: "highlight",
          israeli_builtup_area_1_buildings: "highlight",
          israeli_builtup_area_2_buildings: "highlight",
          israeli_builtup_area_3_buildings: "highlight",
          settlement2: "strong",
          road1: "strong",
          road2: "strong",
          western_neighbourhood_shiekh_jarrah: "highlight",
          /////
          interior_ministry001: "highlight",
          ministry_sci_tech: "highlight",
          national_headquarters: "highlight",
          regional_police_headquarters: "highlight",
          russian_compound_police_station: "highlight",
          social_security: "highlight",
          border_police_base: "highlight",
          border_police_base_buildings: "highlight",
          ministry_public_security: "highlight",
          western_neighbourhood_shiekh_jarrah: "strong",
          planned_parks_2: "mark",
        },
        bounds: [35.226716222, 31.789213693, 35.237220663, 31.794242541],
        show3D: true,
        camera3: {
          type: "blender",
          name: "top_view_large",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 1,
          small_settlements: 1,
        },
      },
    ],
    layers: [],
    pins: [],
    labels: [],
    frames: [],
  },
  "/city": {
    slides: [
      {
        index: 1,
        chapterTitle: {
          en: "The City: Ethnic cleansing in Occupied Jerusalem",
          ar: "المدينة: التطهير العرقي في القدس",
        },
        bounds: [35.226716222, 31.789213693, 35.237220663, 31.794242541],
        show3D: false,
        camera3: {
          type: "blender",
          name: "top_view_large",
          modelFile: "2022.06.15_ShJ.glb",
          focalLength: 10,
          sensorSize: 36,
          marginFactor: 1,
        },
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 1,
        },
      },
      {
        index: 1,
        chapterTitle: {
          en: "The City: Ethnic cleansing in Occupied Jerusalem",
          ar: "المدينة: التطهير العرقي في القدس",
        },
        bounds: [35.226716222, 31.789213693, 35.237220663, 31.794242541],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
        },
      },
      {
        index: 2,
        cardText: {
          en: "The spectrum of tools used to expel Palestinians from the Sheikh Jarrah neighbourhood are part of a wider policy to displace Palestinians from Jerusalem.",
          ar: `تعد مجموعة الأساليب المستخدمة لطرد الفلسطينيين من حي الشيخ جراح جزءًا من سياسة أوسع لتهجير الفلسطينيين من القدس.`,
        },
        bounds: [35.21041, 31.76337, 35.26087, 31.7953],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
          // built_up_settlements_js_west: 1,
        },
      },
      {
        index: 3,
        cardText: {
          en: "The city was divided during the Nakba in 1948 and its western side was occupied, along with 78% of historic Palestine.\nMany of the families in Sheikh Jarrah are refugees from this part of the city.",
          ar: `قُسّمت المدينة خلال نكبة 1948 واحتُلّ جانبها الغربي مع 78٪ من فلسطين التاريخية.\nالعديد من العائلات في حي الشيخ جراح لاجئة من هذا الجزء من المدينة.`,
        },
        bounds: [35.1678, 31.7318, 35.3007, 31.8159],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.9,
        },
      },
      {
        index: 4,
        cardText: {
          en: "The eastern part of the city was occupied and annexed in 1967. Across Jerusalem, new settlements come to form what observers have called ‘a living wall’ that surrounds and bisects Palestinian areas.",
          ar: `تم احتلال الجزء الشرقي من المدينة وضمه عام 1967. وفي جميع أنحاء القدس، تظهر مستوطنات جديدة لتشكل ما أطلق عليه المراقبون "جدار حي" يحيط بالمناطق الفلسطينية ويقسّمها.`,
        },
        show3D: false,
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          built_up_settlements_js_east: 1,
        },
      },
      {
        index: 5,
        cardText: {
          en: "The zoning of large sections of the city as ‘green’ areas  gives the occupation authorities another pretext to confiscate Palestinian properties and to further contain Palestinian urban growth in Jerusalem.",
          ar: `إن تقسيم مناطق كبيرة من المدينة إلى مناطق "خضراء" يعطي سلطات الاحتلال ذريعة أخرى لمصادرة الممتلكات الفلسطينية واحتواء النمو العمراني الفلسطيني في القدس.`,
        },
        // bounds: [35.21485, 31.76107, 35.26418, 31.79228],
        bounds: [35.19348, 31.73751, 35.29672, 31.80284],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          built_up_settlements_js_east: 1,
          // new_national_parks: 0.5,
          green_areas_js: 0.4,
        },
      },
      {
        index: 6,
        cardText: {
          en: "Other Jewish settlements are built around Jerusalem, further separating it from Palestinian cities in the West Bank.",
          ar: `تم بناء مستوطنات يهودية أخرى حول القدس، ما يفصلها عن المدن الفلسطينية في الضفة الغربية.`,
        },
        // bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        bounds: [34.8875, 31.5992, 35.486, 31.9778],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          // separation_barrier: 1,
          built_up_settlements_js_east: 0.8,
          // jerusalem_walls_national_park: 0.3,
          // new_national_parks: 0.3,
          green_areas_js: 0.3,
          settlements_outside_js: 1,
        },
      },
      {
        index: 7,
        cardText: {
          en: "The apartheid wall isolates the city from the occupied West Bank and is strategically constructed to inhibit Palestinian access to Jerusalem and confiscate open Palestinian lands.",
          ar: `يعزل جدار الفصل العنصري المدينة عن الضفة الغربية المحتلة، وقد تم بناؤه بشكل استراتيجي لمنع وصول الفلسطينيين إلى القدس ومصادرة الأراضي الفلسطينية.`,
        },
        bounds: [34.8875, 31.5992, 35.486, 31.9778],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          built_up_settlements_js_east: 1,
          new_national_parks: 0.5,
          green_areas_js: 0.4,
          settlements_outside_js: 1,
          separation_barrier: 1,
          // roads: 1,
        },
      },
      {
        index: 8,
        cardText: {
          en: "Roadways and rail lines, designed to to serve the Jewish settlements, further cut apart and contain Palestinian areas.",
          ar: `الطرق وخطوط السكك الحديدية، المصممة لخدمة المستوطنات اليهودية تضاعف من تقطيع واحتواء المناطق الفلسطينية.`,
        },
        // bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        bounds: [34.8875, 31.5992, 35.486, 31.9778],

        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
          // jerusalem_walls_national_park: 0.3,
          new_national_parks: 0.4,
          green_areas_js: 0.4,
          built_up_settlements_js_west: 0.8,
          // separation_barrier: 1,
          built_up_settlements_js_east: 0.8,
          // jerusalem_walls_national_park: 0.3,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
        },
      },
      {
        index: 9,
        cardText: {
          en: "After annexation, the urban development of the entire city, including the Palestinian neighbourhoods, fell under the control of the occupation’s municipality. The municipality considers 44,000 houses (accommodating 80% of Palestinians) in Jerusalem to be illegal.",
          ar: `بعد الضم، أصبح التطوير العمراني للمدينة بأكملها، بما في ذلك الأحياء الفلسطينية، تحت سيطرة بلدية الاحتلال. وتعتبر البلدية 44 ألف منزل (80٪ من الفلسطينيين) في القدس غير شرعية.`,
        },
        // images: [
        //   BASE_URL + "content/" + "demolition_01.png",
        //   BASE_URL + "content/" + "demolition_02.png",
        // ],
        // bounds: [35.21041, 31.76337, 35.26087, 31.7953],
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        // bounds: [34.8875, 31.5992, 35.486, 31.9778],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          built_up_settlements_js_east: 1,
          new_national_parks: 0.4,
          green_areas_js: 0.4,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          js_new_municipal_boundary: 1,
          Pal_JS_neigh: 1,
        },
      },
      {
        index: 10,
        cardText: {
          en: "This acts as a pretext for house demolition throughout the city.\nAccording to OCHA, more than 1,600 Palestinian structures have been demolished in Jerusalem since 2009.",
          ar: `ويُعد هذا بمثابة ذريعة لهدم المنازل في جميع أنحاء المدينة.\nوبحسب مكتب الأمم المتحدة لتنسيق الشؤون الإنسانية (أوتشا)، فقد تم هدم أكثر من 1600 مبنى فلسطيني في القدس منذ عام 2009.`,
        },
        // images: [
        //   BASE_URL + "content/" + "demolition_01.png",
        //   BASE_URL + "content/" + "demolition_02.png",
        // ],
        // bounds: [35.21041, 31.76337, 35.26087, 31.7953],
        bounds: [35.18517, 31.73375, 35.29428, 31.80279],
        // bounds: [34.8875, 31.5992, 35.486, 31.9778],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          built_up_settlements_js_east: 1,
          new_national_parks: 0.4,
          green_areas_js: 0.4,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          Pal_JS_neigh: 1,
        },
      },
      {
        index: 11,
        cardType: "section",
        cardText: {
          en: "Across Jerusalem, Israel uses what they claim to be “biblical archaeology” as a pretext to seize Palestinian lands.\nIn the Palestinian neighbourhood Silwan, by the old city, settler groups excavated within and under the neighbourhood, destablising roads, monsque and homes.",
          ar: `وفي جميع أنحاء القدس، تستخدم إسرائيل ما يزعمون أنه "علم آثار توراتي" كذريعة للاستيلاء على الأراضي الفلسطينية.\n في حي سلوان الفلسطيني، قرب البلدة القديمة، قامت مجموعات المستوطنين بأعمال الحفر داخل الحي وتحته ، مما أدى إلى زعزعة الطرق والمساجد والمنازل.`,
        },
        images: [
          {
            url: BASE_URL + "content/" + "Silwan.jpg",
            caption: "Image: Omar Robert Hamilton | The Guardian",
          },
        ],
        // bounds: [35.22205, 31.76395, 35.25032, 31.78184],
        bounds: [35.18517, 31.73375, 35.29428, 31.80279],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          built_up_settlements_js_east: 1,
          new_national_parks: 0.5,
          green_areas_js: 0.5,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          Pal_JS_neigh: 1,
        },
        imageLink:
          "https://www.theguardian.com/world/2010/may/26/jerusalem-city-of-david-palestinians-archaeology",
      },
      {
        index: 12,
        cardText: {
          en: "The same property registration laws used in Sheikh Jarrah are also used in to confiscate Palestinian property in many other parts of the city, such as here in Beit Hanina.",
          ar: `يتم استخدام قوانين تسجيل الملكية بنفس القدر لمصادرة الممتلكات الفلسطينية في أجزاء أخرى كثيرة من المدينة، كما هو الحال هنا في "بيت حنينا".`,
        },
        show3D: false,
        bounds: [35.17798, 31.78685, 35.28709, 31.85589],
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
          jerusalem_walls_national_park: 0.3,
          new_national_parks: 0.5,
          green_areas_js: 0.5,
          built_up_settlements_js_west: 0.8,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          built_up_settlements_js_east: 1,
          Pal_JS_neigh: 1,
        },
      },
      {
        index: 13,
        cardText: {
          en: "Dense Palestinian areas, like the Shuafat Refugee Camp, were cut off from the rest of the city by the apartheid wall in an attempt to maintain the Jewish majority.",
          ar: `تم عزل مناطق فلسطينية كثيفة ، مثل مخيم شعفاط للاجئين، عن بقية المدينة بواسطة جدار الفصل العنصري في محاولة للحفاظ على الأغلبية اليهودية.`,
        },
        show3D: false,
        bounds: [35.20638, 31.78211, 35.299, 31.84072],
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
          jerusalem_walls_national_park: 0.3,
          new_national_parks: 0.5,
          green_areas_js: 0.5,
          built_up_settlements_js_west: 0.8,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          built_up_settlements_js_east: 1,
          Pal_JS_neigh: 1,
        },
      },
      {
        index: 14,
        cardText: {
          en: "Under apartheid in Jerusalem, the official planning policy is to keep the Palestinian population under 30%.\nTo force Palestinian migration from the city, the occupation’s municipality designates only 13% of the city’s lands for Palestinian construction. It rarely approves building permissions for Palestinians, and routinely demolishes those homes that are built without them.\nStill, Palestinians hold on to the city, and constitute close to 40% of its  population.",
          ar: `في الفصل العنصري في القدس، سياسة التخطيط الرسمية هي إبقاء التكتل السكاني الفلسطيني أقل من 30٪.\nلتشجيع الهجرة الفلسطينية من المدينة، تخصص بلدية الاحتلال 13٪ فقط من أراضي المدينة للبناء الفلسطيني. ونادرًا ما توافق على تصاريح البناء للفلسطينيين، وتقوم بشكل روتيني بهدم تلك المنازل التي يتم بناؤها بدون تصريح.\nمع ذلك، يتمسّك الفلسطينيون بالمدينة ويشكلون ما يقرب من 40٪ من سكانها.`,
        },
        show3D: false,
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        visibleMapLayers: {
          ...geoJsonVisibility,
          the_48: 0,
          small_settlements: 0.9,
          jerusalem_walls_national_park: 0.3,
          new_national_parks: 0.5,
          green_areas_js: 0.5,
          built_up_settlements_js_west: 0.8,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          built_up_settlements_js_east: 1,
          Pal_JS_neigh: 1,
          js_new_municipal_boundary: 1,
        },
      },
      {
        index: 15,
        cardText: {
          en: "The areas occupied in 1967 around Jerusalem, are designated 'Area C', and are directly administered by Israel.",
          ar: `المناطق التي احتُلت عام 1967 حول القدس، مصنفة "منطقة ج"، وتديرها إسرائيل مباشرة.`,
        },
        // images: [
        //   BASE_URL + "content/" + "demolition_01.png",
        //   BASE_URL + "content/" + "demolition_02.png",
        // ],
        // bounds: [35.21041, 31.76337, 35.26087, 31.7953],
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        // bounds: [34.8875, 31.5992, 35.486, 31.9778],
        show3D: false,
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          built_up_settlements_js_east: 1,
          new_national_parks: 0.4,
          green_areas_js: 0.4,
          settlements_outside_js: 1,
          separation_barrier: 1,
          roads: 1,
          roads_base: 1,
          Pal_C48: 1,
          green_line: 1,
          area_c: 1,
          the_48: 1,
        },
      },
    ],
    layers: [],
    pins: [],
    labels: [],
    frames: [],
  },
  "/land": {
    slides: [
      {
        index: 1,
        chapterTitle: { en: "The Land", ar: "الأرض" },
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          separation_barrier: 1,
          built_up_settlements_js_east: 0.8,
          jerusalem_walls_national_park: 0.3,
          new_national_parks: 0.3,
          green_areas_js: 0.5,
          settlements_outside_js: 1,
          //
          the_48: 1,
          area_c: 1,
          separation_barrier: 1,
        },
      },
      {
        index: 1,
        chapterTitle: { en: "The Land", ar: "الأرض" },
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          separation_barrier: 1,
          built_up_settlements_js_east: 0.8,
          jerusalem_walls_national_park: 0.3,
          new_national_parks: 0.3,
          green_areas_js: 0.5,
          settlements_outside_js: 1,
          //
          the_48: 1,
          area_c: 1,
          separation_barrier: 1,
        },
      },
      {
        index: 2,
        cardText: {
          en: "Today, Palestinian life under apartheid in Jerusalem is governed by separate but interrelated systems of settler colonial oppression: a military occupation that denies Palestinians in the West Bank access to the city, a civilian administration in the eastern part of the city, and systemic discrimination meant to erase Palestinian political and cultural life in the western side.",
          ar: `اليوم، تخضع حياة الفلسطينيين في ظل الفصل العنصري في القدس لأنظمة مختلفة ولكن مترابطة من القمع الاستعماري الاستيطاني: احتلال عسكري يمنع الفلسطينيين في الضفة الغربية من الوصول إلى المدينة، وإدارة مدنية في الجزء الشرقي من المدينة، والتمييز المنهجي لمحو الحياة السياسية والثقافية الفلسطينية في الجانب الغربي.`,
        },
        bounds: [35.10954, 31.71179, 35.32591, 31.87164],
        visibleMapLayers: {
          ...geoJsonVisibility,
          small_settlements: 0.9,
          built_up_settlements_js_west: 0.8,
          separation_barrier: 1,
          built_up_settlements_js_east: 0.8,
          jerusalem_walls_national_park: 0.3,
          new_national_parks: 0.3,
          green_areas_js: 0.5,
          settlements_outside_js: 1,
          //
          the_48: 1,
          area_c: 1,
          separation_barrier: 1,
        },
      },
      {
        index: 3,
        cardText: {
          en: "Jerusalem is at the epicentre of an overall settler colonial system which manifests itself differently in different parts of historic Palestine.",
          ar: `تقع القدس في بؤرة نظام استعماري استيطاني شامل يتجلى بشكل مختلف في أجزاء مختلفة من فلسطين التاريخية.`,
        },
        bounds: [32.982, 30.738, 37.166, 33.386],
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          // separation_barrier: 1,
        },
      },
      {
        index: 4,
        cardText: {
          en: "In the West Bank, Palestinian cities and villages (Areas A and B) are torn apart by settlements, military checkpoints and the apartheid wall.\nThese areas are routinely invaded by the occupation forces.",
          ar: `في الضفة الغربية، تُمزق المدن والقرى الفلسطينية (المنطقتان أ و ب) بسبب المستوطنات ونقاط التفتيش العسكرية وجدار الفصل العنصري.\nيتم اجتياح هذه المناطق بشكل روتيني من قبل جيش الاحتلال.`,
        },
        bounds: [34.287, 31.314, 36.263, 32.564],
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          Pal_WB_cities: 1,
          // separation_barrier: 1,
        },
      },
      {
        index: 5,
        cardText: {
          en: "In Masafer Yatta, in what would be the largest mass expulsions since the 1967 occupation, thousands of Palestinians face the imminent threat of eviction from an area designated by the army as a ‘live fire’ zone.",
          ar: `في منطقة "مُسافر يطّا"، فيما يمكن أن يكون أكبر عمليات طرد جماعي منذ احتلال عام 1967، يواجه آلاف الفلسطينيين تهديدًا وشيكًا بالإخلاء من منطقة حددها الجيش كمنطقة "إطلاق نار".`,
        },
        bounds: [34.45, 31.041, 35.868, 31.938],
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          Pal_MY: 1,
          green_line: 1,
          // separation_barrier: 1,
        },
      },
      {
        index: 6,
        cardText: {
          en: "The besieged Gaza Strip continues to be subjected to an ongoing occupation, and recurring  bombardments resulting in hundreds of deaths, and the destruction of large parts of its civilian infrastructure, industrial zones and  agricultural lands.",
          ar: `لا يزال قطاع غزة المحاصر يخضع لاحتلال مستمر ، وقصف متكرر أسفر عن مئات الضحايا وتدمير أجزاء كبيرة من بنيته التحتية المدنية، والمناطق الصناعية والأراضي الزراعية.`,
        },
        bounds: [33.691, 30.918, 35.666, 32.168],
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          Pal_Gaza: 1,
          // Pal_WB_cities: 1,
          // separation_barrier: 1,
        },
      },
      {
        index: 7,
        cardText: {
          en: "The Palestinians who remained in areas of historic Palestine occupied in 1948 are provided unequal citizenship, with over 65 laws that directly discriminate against them as non-Jewish citizens. They are also subjected to dispossession and forced displacement.",
          ar: `يحصل الفلسطينيون الذين بقوا في مناطق فلسطين التاريخية المحتلة عام 1948 على مواطنة غير متكافئة، مع أكثر من 65 قانونًا يميز بشكل مباشر ضدهم كمواطنين غير يهود. كما أنهم يتعرضون للنزع والتهجير القسري.`,
        },
        bounds: [32.982, 30.738, 37.166, 33.386],
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          Pal_48_mixed: 1,
          // separation_barrier: 1,
        },
      },
      {
        index: 8,
        cardText: {
          en: "In Yafa, for example, displacement of Palestinians takes the form of the gentrification of its historic neighbourhoods.",
          ar: `في يافا، على سبيل المثال، يتم التهجير بذريعة تطوير أحياء المدينة التاريخية.`,
        },
        toggleTitle: { en: "Further Reading", ar: "قراءة متعمقة" },
        toggleContent: {
          en: "Similar to practices of displacement faced by families in Sheikh Jarrah, ‘Some 497 eviction and demolition orders have been served by the Amidar, Israel's government-operated public housing company, targeting Ajami and Jabaliyya residents’.",
          ar: `تتعرض العائلات في العجمي وجباليا لنفس ممارسات النزوح التي يتعرض لها سكان الشيخ جراح. قدمت شركة الإسكان العامة الإسرائيلية ، عميدار ، ما يقرب من 497 أمر إخلاء وهدم.`,
        },
        bounds: [33.702, 31.362, 35.904, 32.756],
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          Pal_48_mixed: 1,
          // separation_barrier: 1,
        },
      },
      {
        index: 9,
        cardText: {
          en: "In the Naqab, entire Palestinian villages are repeatedly declared illegal and demolished.\nResidents resist by returning and rebuilding them. The Village of Al Araqib, for example, was returned to and rebuilt 203 times.",
          ar: `في النقب، أُعلن مرارًا أن قرى فلسطينية بأكملها غير شرعية وهدمت.\nالسكان يقاومون بالعودة وإعادة البناء. أعيد بناء قرية العراقيب 203 مرات.`,
        },
        bounds: [34.206, 31.027, 35.505, 31.849],
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          Pal_48_mixed: 1,
          // separation_barrier: 1,
        },
      },
      {
        index: 10,
        cardText: {
          en: "The ethnic cleansing process that started with the Nakba in 1948 is still ongoing throughout all parts of historic Palestine.\nDespite close to eighty years of Israeli aparthied, settler colonialism, and ongoing dispossessions, Palestinians refuse their separation and resist the violence imposed on them by Israel.",
          ar: `لا تزال عملية التطهير العرقي التي بدأت مع النكبة عام 1948 مستمرة في جميع أنحاء فلسطين التاريخية.\nعلى الرغم من ما يقرب من ثمانين عامًا من الفصل العنصري الإسرائيلي ، والاستعمار الاستيطاني ، ونزع الملكية المستمر ، يرفض الفلسطينيون تفرقتهم ويقاومون العنف الذي تفرضه إسرائيل عليهم.`,
        },
        bounds: [32.982, 30.738, 37.166, 33.386],
        visibleMapLayers: {
          ...geoJsonVisibility,
          //
          the_48: 1,
          area_c: 1,
          Pal_48_mixed: 1,
          Pal_Gaza: 1,
          Pal_WB_cities: 1,
          // separation_barrier: 1,
        },
      },
    ],
    layers: [],
    pins: [],
    labels: [],
    frames: [],
  },
};

export default data;
