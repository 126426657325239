import React from "react";

export default function CardVideo(props) {
  if (!props.src) {
    return null;
  }

  const BASE_URL = "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/";

  return (
    <div style={{ width: "400px" }}>
      <video
        // preload
        controls="controls"
        autoPlay={false}
        width="400px"
        src={BASE_URL + props.src.replaceAll(" ", "%20")}
      />
    </div>
  );
}
