import {slide as Menu} from 'react-burger-menu';
import {Link} from "react-router-dom";

import {colors} from '../constants';

// import './BurgerMenu.css';

const menuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '15px',
    top: '15px'
  },
  bmBurgerBars: {
    background: '#8d8d8d'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '50%'
  },
  bmMenu: {
    // background: '#232323',
    background: 'rgba(0, 0, 0, 0.8)',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  }
};

const routeStyles = {
  textDecoration: 'none',
  textTransform: 'uppercase',
  outline: 'none',
  fontWeight: 'bold',
  fontSize: '3.5vw',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  justifyContent: 'space-between'
};


// TO-DO: Make items dynamic and configurable
const BurgerMenu = ({styles, language, routes, currentRoute}) => {
  return (
    <Menu className="burger-menu" styles={{...menuStyles, ...styles}}>
      {routes.map(r => (
        <Link
          className="menu-item"
          to={r.url}
          style={{
            ...routeStyles,
            color: currentRoute === r.url ? colors.fa_red : colors.white,
          }}
        >
          {r.title[language]}
        </Link>
      ))
      }
    </Menu >
  );
}

export default BurgerMenu;
