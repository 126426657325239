import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";

export const fovFromBlenderFocalLength = (aspectRatio, sensor_width, lens) => {
  // https://b3d.interplanety.org/en/how-to-get-camera-fov-in-degrees-from-focal-length-in-mm/
  // FoV – camera Field of View in radians
  // W – camera Sensor Width in millimeters
  // S – camera Focal Length in millimeters

  // const w = window.innerWidth;
  // const h = window.innerHeight;
  // const aspectRatio = w / h;

  // https://b3d.interplanety.org/en/how-to-get-camera-fov-in-degrees-from-focal-length-in-mm/
  // trying to get fov from blender
  // horizontal fov
  const HfovRad = 2 * Math.atan(sensor_width / (2 * lens));

  // assuming the above is horizontal, getting the vertical one
  // based on this link
  // https://stackoverflow.com/questions/17837652/calculating-frame-and-aspect-ratio-guides-to-match-cameras
  const VfovRad = 2 * Math.atan(Math.tan(HfovRad / 2) / aspectRatio);
  const fov = VfovRad * (180 / Math.PI);
  // console.log(fov);
  // this function returns the vertical field of view
  // ... that makes sure the horizontal field from Blender fits!!
  // ... not the vertical field of view in Blender
  // imp: check this
  // https://blender.stackexchange.com/questions/23431/how-to-set-camera-horizontal-and-vertical-fov
  const newFOVRad = HfovRad * 0.5625;
  const newFOVDegrees = newFOVRad * (180 / Math.PI);

  return fov;
};

const lerp = (start, end, amt) => {
  return (1 - amt) * start + amt * end;
};

// not sure if we should keep using nodesDict
export const cameraSettingsFromSlideCam = (isMobile, slideCam, nodesDict) => {
  let marginFactor = isMobile ? 0.8 : 1.2;
  const camAspectRatio = 16 / 9; // check if this is always the case
  const defaultCameraSettings = {
    // get these from a master config
    position: new THREE.Vector3(-220, 720, 130),
    rotationE: new THREE.Euler(1.2, 0.2, 0.1, "XYZ"),
    fov: 50, // not applied yet
  };
  const camera = slideCam;
  if (!camera) {
    // no camera settings provided in the slide settings
    return defaultCameraSettings;
  }
  if (camera.type === "blender" && camera.name) {
    if (camera.marginFactor) marginFactor = camera.marginFactor;
    const nodes = nodesDict[camera.modelFile];
    // console.log(camera.name);
    const camSettings = {
      position: nodes[camera.name].position,
      rotationE: nodes[camera.name].rotation,
      fov:
        fovFromBlenderFocalLength(
          window.innerWidth / window.innerHeight,
          camera.sensorSize,
          camera.focalLength
        ) * marginFactor,
      matchFOV: fovFromBlenderFocalLength(
        camAspectRatio,
        camera.sensorSize,
        camera.focalLength
      ),
    };
    // console.log("----", camera);
    // console.log(camSettings);
    return camSettings;
  }
  if (camera.type === "three") {
    return {
      position: new THREE.Vector3(
        camera.position[0],
        camera.position[1],
        camera.position[2]
      ),
      rotationE: new THREE.Euler(
        camera.rotationE[0],
        camera.rotationE[1],
        camera.rotationE[2],
        "XYZ"
      ),
      fov: camera.fov, // not applied yet}
    };
  }

  return defaultCameraSettings;
};

// old
const AnimateCamera = (groupRef, camRef, position, rotation, fov) => {
  // important: the camera and the transition should be different functions!!
  // https://codesandbox.io/s/animation-move-camera-mhkcj?file=/src/index.js:236-595
  // transition_topview
  // console.log(position, rotation);
  const vec = new THREE.Vector3();
  vec.set(position.x, position.y, position.z); // target

  const initialPos = new THREE.Vector3();
  let initialFOV = 100;
  const initialEul = new THREE.Quaternion();
  const quat = new THREE.Quaternion();
  quat.setFromEuler(rotation); // target

  // console.log(new THREE.Quaternion(), quat.setFromEuler(rotation));
  // const groupRef = useRef();
  const camera = camRef.current;
  const group = groupRef.current;

  // initialPos.copy(group?.position);
  // initialEul.copy(camera?.quaternion);
  // const initialFOV = camera?.fov;
  // console.log(initialPos, initialEul, initialFOV);

  // camera.getWorldDirection(targetVec);
  // console.log(targetVec);

  // console.log(camera);
  let alpha = 0.0;
  const step = 0.005;
  useFrame((state) => {
    // console.log("useFrame running...");

    if (group && camera) {
      if (alpha === 0.0) {
        // console.log("alpha, 1", alpha);
        initialPos.copy(group.position);
        initialEul.copy(camera.quaternion);
        initialFOV = camera.fov;
        alpha += step;
        // console.log(initialPos, initialEul, initialFOV);
      }
      if (alpha > 0.0 && alpha < 1 + step) {
        // console.log("alpha, 2", alpha);
        // const dis = group.position.distanceTo(vec);
        // console.log(dis);

        group.position.lerpVectors(initialPos, vec, alpha);

        camera.quaternion.slerpQuaternions(initialEul, quat, alpha);
        // camera.updateProjectionMatrix();
        // animate fov
        // stop after a while?
        camera.fov = lerp(initialFOV, fov, alpha);
        // camera.lookAt(-220, 720, -130); // change this :)
        camera.updateProjectionMatrix();
        alpha += step;
        // console.log(alpha);
        // console.log(camera.target);
        // console.log(camera.fov);
      }
    }
    // state.camera.lookAt(0, 0, 0);
    // state.camera.updateProjectionMatrix();
  });
};
