import "./App.css";

import React, { useMemo, useState, useRef } from "react";
// import { Route, Routes, useLocation } from "react-router-dom";

import MapboxMap from "./components/MapboxMap";
import Scene3D from "./components/Scene3D";

import { ProgressBar } from "./components/ProgressBar";
import PhoneCard from "./components/PhoneCard";

import StaticVideo from "./components/StaticVideo";
// import ChapterTitle from "./components/ChapterTitle";
import ScreenText from "./components/ScreenText";
// import { Resizable } from "./components/Resizable";
// import NavBar from "./components/NavBar";

// import { URLS } from "./constants";

import chapters_data from "./configs/chapters_data";

import ImageSequence from "./components/ImageSequence";
import LandingPage from "./components/LandingPage";
import ImageMatch from "./components/ImageMatch";

// console.log(mobile_data);
// const DATA = processExcelData(mobile_data);

function MobileApp() {
  // const {
  //   galleryMaterials,
  //   layers,
  //   frames,
  //   slides: ,
  // } = useMemo(() => {
  //   return processExcelData(chapters_data);
  // }, []);
  const { galleryMaterials, layers, frames, slides } = chapters_data["/street"];

  const matchCameras = slides
    .map((s) => {
      if (s.camera3) {
        if (s.camera3.threeMatch) {
          return s.camera3;
        }
      }
    })
    .filter((cam) => cam !== undefined);

  console.log(matchCameras);

  // const  = ; // , customSlides
  //   const [chapter, setChapter] = useState("/");
  // console.log(chapter);
  // const IMAGE_SEQ_CHAPTER = URLS.filter((f) => f.url === "/pushback-1").map(
  //   (f) => f.url
  // )[0];
  // const setImages = true;

  //   const location = useLocation();
  // console.log(location);

  // location.pathname;
  // URLS.indexOf(URLS.find((u) => u.url === location.pathname));

  //   useEffect(() => {
  //     URLS.indexOf(URLS.find((u) => u.url === location.pathname));
  //     setChapter(location.pathname);
  //   }, [location.pathname]);

  // console.log(chapter);
  //   const chapterSettings = URLS.filter((s) => s.url === chapter)[0];
  // console.log(chapterSettings);
  // const chapterStartIdx = chapterSettings.startIdx;

  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  console.log(slides[currentSlideIdx]);

  const ref = useRef();

  const allWrapperStyle = {
    position: "relative",
    overflow: "scroll",
    height: "100vh",
    width: "100vw",
  };

  const mapContainerStyle = {
    height: "100vh",
    width: "100vw",
    position: "fixed",
  };

  const renderPhoneChapter = (slidesList) => {
    // the slides list should come directly from data
    // const slidesList = Object.entries()
    //   .map(([key, value]) => value)
    //   .slice(startIdx, endIdx + 1);

    return (
      <div id="all-cards-container">
        <PhoneCard slides={slides} callbackFromParent={setCurrentSlideIdx} />
      </div>
    );
  };

  return (
    <div id={"all-wrapper"} ref={ref} style={allWrapperStyle}>
      <ProgressBar elementId="all-wrapper" />
      {/* {currentSlideIdx === 0 || currentSlideIdx === 1 ? (
        <LandingPage isMobile={true} currentSlideIdx={currentSlideIdx} />
      ) : null} */}
      {ref.current ? (
        <div
          style={mapContainerStyle}
          // width={bounds.width}
          // height={bounds.height}
        >
          {/* <Map
            divRef={ref}
            currentSlideIdx={currentSlideIdx}
            layers={layers}
            viewState={[currentSlideIdx].viewState}
            mapSettings={config.mapSettings}
            isMobile={true}
            //   chapter={chapter}
            //   chapterSettings={chapterSettings}
          /> */}
          <MapboxMap slide={slides[currentSlideIdx]} />
        </div>
      ) : null}
      <Scene3D
        isMobile={true}
        slide={slides[currentSlideIdx]}
        currentSlideIdx={currentSlideIdx}
        matchCameras={matchCameras}
      />
      {/* <ImageMatch currentSlideIdx={currentSlideIdx} /> */}

      {/* {ref.current && setImages ? (
        <ImageSequence
          divRef={ref}
          // chapter={chapter}
          chapterStartSlide={-1}
          currentSlideIdx={currentSlideIdx}
          videoSettings={frames}
        />
      ) : null} */}
      {/* <StaticVideo isMobile={true} currentSlideIdx={slides[currentSlideIdx]} /> */}
      {/* <ScreenText isMobile={true} currentSlideIdx={slides[currentSlideIdx]} /> */}

      {renderPhoneChapter(slides)}
    </div>
  );
}

export default MobileApp;
