import { FlyToInterpolator } from "deck.gl";
// import { FirstPersonView } from "@deck.gl/core";
import csvSlides from "./Slides";
import { WebMercatorViewport } from "@deck.gl/core";

const transitionLength = 3000;
const interpolator = new FlyToInterpolator();

const viewport = new WebMercatorViewport({
  width: window.innerWidth,
  height: window.innerHeight,
  longitude: -122.45,
  latitude: 37.78,
  zoom: 12,
  pitch: 0,
  bearing: 0,
});

export const addTransitionsToSlides = (slides) => {
  const transitionsSlides = {};
  Object.keys(slides).forEach((slideNum) => {
    const slide = slides[slideNum];
    const slideViewState = slide.viewState;
    // const { longitude, latitude, zoom } = viewport.fitBounds([
    //   [slide.bounds[0], slide.bounds[1]],
    //   [slide.bounds[2], slide.bounds[3]],
    // ]);
    const updatedSlide = {
      ...slide,
      viewState: {
        ...slideViewState,
        // longitude,
        // latitude,
        // zoom,
        transitionDuration: transitionLength,
        transitionInterpolator: interpolator,
        // bearing: 0,
        // pitch: 0,
      },
    };
    transitionsSlides[slideNum] = updatedSlide;
  });
  return transitionsSlides;
};

// console.log(csvSlides);
// console.log(addTransitionsToSlides(csvSlides));

const config = {
  mapSettings: {
    MAPBOX_TOKEN:
      "pk.eyJ1IjoiZmFkZXYiLCJhIjoiY2pyMjNiZHdvMHVjcjQ5cnU0b2dpdGRxZCJ9.7bci8C-z3EVCa_yMZKymeg",
    mapStyle: "mapbox://styles/fadev/ckzeciv9k003o14o22m04s8yt",
  },
  theme_color_hex: "#ed2024",
  theme_color_rgb: [237, 32, 36],

  slides: addTransitionsToSlides(csvSlides),
  layers: [],
};

export default config;
