import React, { useRef } from "react";
import * as THREE from "three";
import { useSpring, animated, config } from "@react-spring/three";
import { easeBackInOut, easeElasticInOut } from "d3";

import { PerspectiveCamera } from "@react-three/drei";
import { useThree } from "@react-three/fiber";

export default function AnimationCamera({
  isMobile,
  slide,
  activeCameraSettings,
}) {
  const groupRef = useRef();
  const camRef = useRef();

  const { camera } = useThree();
  const fromQuat = new THREE.Quaternion();
  const toQuat = new THREE.Quaternion();
  fromQuat.copy(camera.quaternion);
  toQuat.setFromEuler(activeCameraSettings.rotationE);

  // console.log(camera.fov, activeCameraSettings.fov);
  const springs = useSpring({
    // delay: slide.springDelay ? slide.springDelay : 0, // 2000 if we want to fade out match completely first
    scale: slide.threeMatch !== undefined ? 5 : 1,
    alpha: 1,
    // easing: easeElasticInOut.amplitude(1).period(0.3),
    position: [
      activeCameraSettings.position.x,
      activeCameraSettings.position.y,
      activeCameraSettings.position.z,
    ],
    fov: activeCameraSettings.fov ? activeCameraSettings.fov : 100,
    config: { duration: 3000 }, //config.wobbly,
    from: { alpha: 0 },
    reset: true,
    onChange(cont) {
      // console.log(cont.value.alpha);
      // fov
      camera.fov = cont.value.fov;
      // rotation
      camera.quaternion.slerpQuaternions(fromQuat, toQuat, cont.value.alpha);

      camera.updateProjectionMatrix();
    },
  });

  return (
    <animated.group
      position={springs.position}
      ref={groupRef}
      name="mona yakub photomatch"
    >
      <group name="Camera_Orientation">
        <group name="Camera_Orientation_Orientation">
          <PerspectiveCamera
            ref={camRef}
            name="Camera_Orientation_Orientation_Orientation"
            makeDefault={true}
            far={3000}
            near={0.1}
            focus={2} // for depth of field
            aspect={1}
            // onUpdate={(camera) => {
            //   console.log(camera.fov);
            //   camera.updateProjectionMatrix();
            // }}
          />
        </group>
      </group>
    </animated.group>
  );
}
