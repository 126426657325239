import React from "react";
import { InView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

import MediaSlider from "./MediaSlider";
import CardText from "./card/CardText";
import CardToggle from "./card/CardToggle";
import CardVideo from "./card/CardVideo";
import CardVimeo from "./card/CardVimeo";
import CardYoutube from "./card/CardYoutube";
import CardAudio from "./card/CardAudio";
import CardLink from "./card/CardLink";
import CardFALink from "./card/CardFALink";
import { LANGUAGE_LOWERCASE } from "../constants";
// import "./Card.css";

export default function Card({
  isMobile,
  slide,
  slideIdx,
  language,
  // galleryData,
  // callbackFromParent,
  // next,
}) {
  // const slidesKeys = Object.keys(SLIDES);
  // const lastSlideToShow = slidesKeys.length + parseInt(slidesKeys[0]);

  const isSection = slide.cardType && slide.cardType === "section";

  const cardStyleDesktop = {
    color: "black",
    left: "160px", // doesn't work here?
    top: 150, // doesn't work here?

    backgroundColor: isSection ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.8)",
    zIndex: 30,
    // maxWidth: `250px`,
    // width: isSection ? "auto" : 400,
    width: isSection ? "auto" : "25vw",
    margin: isSection ? `0 400px` : `0 15px`,
    padding: "10px",
    opacity: 1,
  };
  const cardStyleMobile = {
    color: "black",
    left: "160px", // doesn't work here?
    top: 150, // doesn't work here?

    backgroundColor: isSection ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.8)",
    zIndex: 30,
    // width: .25 * width,
    // maxWidth: `250px`,
    width: "98vw",
    // margin: `0 15px`,
    padding: "1vw",
    opacity: 1,
  };

  const cardTextStyle = {
    textAlign: language === LANGUAGE_LOWERCASE.AR ? "right" : "left",
  };

  return (
    <div
      style={isMobile ? cardStyleMobile : cardStyleDesktop}
      id={"card-container-" + (slideIdx + 1).toString()}
      dir={language === LANGUAGE_LOWERCASE.AR ? "rtl" : "ltr"}
      lang={language === LANGUAGE_LOWERCASE.AR ? "ar" : "en"}
    >
      {/* <p style={{ fontSize: "12px", color: "gray" }}>
                        {" "}
                        {value.slideIdx + 1}
                        {"/"}
                        {lastSlideToShow}
                      </p> */}

      <CardText
        style={cardTextStyle}
        text={slide.cardText[language]}
        isSection={isSection}
      />
      {/* {slide.notes ? (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {slide.notes}
                        </p>
                      ) : null} */}
      {slide.toggleTitle && slide.toggleContent && (
        <CardToggle
          language={language}
          toggleTitle={slide.toggleTitle[language]}
          toggleContent={slide.toggleContent[language]}
        />
      )}
      <CardFALink src={slide.inSlideFALink} />
      <CardVideo src={slide.inSlideVideoPlayer} />
      <CardVimeo src={slide.inSlideVimeoPlayer} />
      <CardYoutube src={slide.inSlideYoutubePlayer} />
      <CardAudio src={slide.inSlideAudioPlayer} />
      <MediaSlider materials={slide.images} />
      <CardLink src={slide.imageLink} />
    </div>
  );
}
