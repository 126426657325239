import "./App.css";

import React, { useState, useRef, useEffect, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import MapboxMap from "./components/MapboxMap";
import Scene3D from "./components/Scene3D";

import { ProgressBar } from "./components/ProgressBar";
import Scrollers from "./components/Scrollers";

import StaticVideo from "./components/StaticVideo";
import StaticVideoNoAudio from "./components/StaticVideoNoAudio";
import BlackBackground from "./components/BlackBackground";
import ChapterTitle from "./components/ChapterTitle";
import ScreenText from "./components/ScreenText";
import ImageCredit from "./components/ImageCredit";
import { Resizable } from "./components/Resizable";
import NavBar from "./components/NavBar";
import BurgerMenu from "./components/BurgerMenu";

import { URLS, LANGUAGES, LANGUAGE_LOWERCASE } from "./constants";

import chapters_data from "./configs/chapters_data";
import ImageSequence from "./components/ImageSequence";
// import LandingPage from "./components/LandingPage";
import ImageMatch from "./components/ImageMatch";

export const BASE_URL =
  "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/";

// process content/data

console.log(chapters_data);

function DesktopApp({ isMobile }) {
  const [chapter, setChapter] = useState("/");
  const [language, setLanguage] = useState(LANGUAGES.EN);
  // const {
  //   galleryMaterials,
  //   layers,
  //   frames,
  //   slides: allSlides,
  // } = useMemo(() => {
  //   return processExcelData(excel_data);
  // }, []);

  const { galleryMaterials, layers, frames, slides } = chapters_data[chapter];
  // const slides = chapters_data[chapter]

  const uniqueCameraNames = [];

  const matchCameras = slides
    .map((s) => {
      if (s.camera3) {
        if (s.camera3.threeMatch) {
          const camName = s.camera3.name;

          if (!uniqueCameraNames.includes(s.camera3.name)) {
            uniqueCameraNames.push(camName);
            return s.camera3;
          }
        }
      }
    })
    .filter((cam) => cam !== undefined);

  // console.log(chapter);
  const IMAGE_SEQ_CHAPTER = URLS.filter((f) => f.url === "/pushback-1").map(
    (f) => f.url
  )[0];
  const setImages = chapter === IMAGE_SEQ_CHAPTER;

  const location = useLocation();
  // console.log(location);

  // location.pathname;
  // URLS.indexOf(URLS.find((u) => u.url === location.pathname));

  useEffect(() => {
    URLS.indexOf(URLS.find((u) => u.url === location.pathname));
    setChapter(location.pathname);
  }, [location.pathname]);

  // console.log(chapter);
  const chapterSettings = URLS.filter((s) => s.url === chapter)[0];
  // console.log(chapterSettings);

  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  // console.log("++++++++++", currentSlideIdx, slides[currentSlideIdx]);

  const ref = useRef();

  const allWrapperStyle = {
    position: "relative",
    overflow: "scroll",
    height: "100vh",
    width: "100vw",
  };

  const mapContainerStyle = {
    height: "100vh",
    width: "100vw",
    position: "fixed",
  };

  const lowercaseLanguage = LANGUAGE_LOWERCASE[language];
  console.info(lowercaseLanguage);

  const renderChapter = (slides, next) => {
    // console.log(slides);
    return (
      <div id="all-cards-container">
        <Scrollers
          // galleryData={galleryMaterials}
          isMobile={isMobile}
          slides={slides}
          callbackFromParent={setCurrentSlideIdx}
          next={next}
          language={lowercaseLanguage}
        />
      </div>
    );
  };

  return (
    <Resizable className="resize-container">
      {(bounds) => (
        <div id={"all-wrapper"} ref={ref} style={allWrapperStyle}>
          <ProgressBar elementId="all-wrapper" />
          <NavBar
            URLS={URLS}
            chapter={chapter}
            setCurrentSlideIdx={setCurrentSlideIdx}
            width={bounds.width}
            height={bounds.height}
            isMobile={isMobile}
            onToggle={setLanguage}
            language={lowercaseLanguage}
          />
          {/* {currentSlideIdx === 0 || currentSlideIdx === 1 ? (
            <LandingPage isMobile={isMobile} currentSlideIdx={currentSlideIdx} />
          ) : null} */}
          {ref.current ? (
            <div
              style={mapContainerStyle}
              width={bounds.width}
              height={bounds.height}
            >
              {/* <Map
                divRef={ref}
                currentSlideIdx={currentSlideIdx}
                layers={layers}
                viewState={{
                  ...slides[currentSlideIdx].viewState,
                  width: bounds.width,
                  height: bounds.height,
                }}
                mapSettings={config.mapSettings}
                chapter={chapter}
                chapterSettings={chapterSettings}
                isMobile={isMobile}
              /> */}
              <MapboxMap
                slide={slides[currentSlideIdx]}
                language={lowercaseLanguage}
                isMobile={isMobile}
              />
            </div>
          ) : null}
          {isMobile && (
            <BurgerMenu
              routes={URLS}
              language={lowercaseLanguage}
              currentRoute={chapter}
            />
          )}
          {
            <Scene3D
              isMobile={isMobile}
              slide={slides[currentSlideIdx]}
              currentSlideIdx={currentSlideIdx}
              matchCameras={matchCameras}
              language={lowercaseLanguage}
            />
          }
          <ImageMatch slide={slides[currentSlideIdx]} />
          {/* {ref.current && setImages ? (
            <ImageSequence
              divRef={ref}
              chapter={chapter}
              chapterStartSlide={4}
              currentSlideIdx={currentSlideIdx}
              videoSettings={frames}
            />
          ) : null} */}
          {/* <LoadingBar /> */}
          <BlackBackground
            isMobile={isMobile}
            slide={slides[currentSlideIdx]}
          />
          <StaticVideo isMobile={isMobile} slide={slides[currentSlideIdx]} />
          <StaticVideoNoAudio
            isMobile={isMobile}
            slide={slides[currentSlideIdx]}
          />
          <ChapterTitle
            slide={slides[currentSlideIdx]}
            language={lowercaseLanguage}
          />
          <ScreenText isMobile={isMobile} slide={slides[currentSlideIdx]} />
          <ImageCredit isMobile={isMobile} slide={slides[currentSlideIdx]} />

          <Routes>
            {URLS.map(
              (
                { url, next } //this shouldn't be a map?
              ) => (
                <Route
                  key={`route-${url}`}
                  exact
                  path={url}
                  element={renderChapter(slides, next)}
                  // render={() => renderChapter(startIdx, endIdx)}
                />
              )
            )}
          </Routes>
        </div>
      )}
    </Resizable>
  );
}

export default DesktopApp;
