import React from "react";
import config from "./../configs/config";

export default function ChapterTitle({ slide, language }) {
  if (!slide) {
    return null;
  }
  // console.log(slide);
  // console.log(slide.chapterTitle);
  return (
    <div
      id={"chapter-title"}
      className={`full-screen-content ${
        slide.chapterTitle !== undefined ? "visible-eighty" : "hidden"
      }`}
    >
      <span>
        <h1
          style={{
            color: config.theme_color_hex,
            // fontWeight: "normal",
            textAlign: "center",
            fontSize: "4vh",
          }}
        >
          {slide.chapterTitle && slide.chapterTitle[language]}
        </h1>
        {/* <br /> */}
      </span>
    </div>
  );
}
