import React, { useState, useEffect, useRef } from "react";
// import ReactPlayer from "react-player/vimeo";

// Only loads the YouTube player

export default function StaticVideoNoAudio({ isMobile, slide }) {
  const videoRef = useRef();

  useEffect(() => {
    if (slide.fullScreenVideoNoAudio) {
      if (videoRef.current) {
        videoRef.current.muted = true;
        videoRef.current.volume = 0;
        videoRef.current.play();
        console.log("playing video");
      }
    }
  }, [slide]);

  if (!slide) {
    return null;
  }

  // console.log(slide);
  return (
    <div
      id={"static-video-no-audio"}
      style={{
        // color: "black",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // top: 0,
        // left: 600,
        // top: 150,
        // right: 0,
        // bottom: 300,
        opacity: slide.fullScreenVideoNoAudio ? "1" : "0.0",
        backgroundColor: "black",
        pointerEvents: "none",
        zIndex: 20,
        width: "100vw",
        height: "100vh",
        // padding: 10,
        WebkitTransition: `opacity ${
          slide.fullScreenVideoNoAudio ===
            "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/" +
              "Parvin/Videos/Video for Animations/transition_RL_01.mp4" ||
          slide.fullScreenVideoNoAudio ===
            "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/" +
              "Parvin/Videos/Video for Animations/transition_RL_02.mp4"
            ? "0.2s"
            : "1s"
        }`,
      }}
    >
      {slide.fullScreenVideoNoAudio ? (
        <video
          controls={isMobile}
          ref={videoRef}
          preload="auto"
          muted
          // controls="controls" //
          autoPlay={true}
          zindex="300"
          width="100%"
          playsInline
          type="video/mp4"
          // ref={onVideoMount}
          src={slide.fullScreenVideoNoAudio}
          onEnded={() => {
            console.log("video ended");
            //   setSkipped(true);
            //   setShowArrow(false);
          }}
        />
      ) : null}
    </div>
  );
}
