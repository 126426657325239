import React from "react";
import { InView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

import MediaSlider from "./MediaSlider";
import CardText from "./card/CardText";
import CardToggle from "./card/CardToggle";
import CardVideo from "./card/CardVideo";
import CardVimeo from "./card/CardVimeo";
import CardYoutube from "./card/CardYoutube";
import CardAudio from "./card/CardAudio";
import CardLink from "./card/CardLink";
import CardFALink from "./card/CardFALink";
// import "./Card.css";

export default function Section({
  slide,
  slideIdx,
  // galleryData,
  // callbackFromParent,
  // next,
  // width,
}) {
  // const slidesKeys = Object.keys(SLIDES);
  // const lastSlideToShow = slidesKeys.length + parseInt(slidesKeys[0]);

  const narrativeWrapperStyle = {
    fontSize: 30,
    color: "yellow",
    position: "absolute",
    // overflow: "scroll",
    zIndex: 90,
  };

  const narrativeElementStyle = {
    height: "120vh",
    width: "100vw",
    // display: "flex",
    // alignItems: "center",
  };

  const sectionStyle = {
    color: "black",
    left: "160px", // doesn't work here?
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 30,
    // width: .25 * width,
    // maxWidth: `250px`,
    width: "100vw",
    margin: `0 15px`,
    padding: 30,
    opacity: 1,
  };

  return (
    <div
      style={sectionStyle}
      id={"section-container-" + (slideIdx + 1).toString()}
    >
      <CardText text={slide.sectionText} />
      {/* {slide.notes ? (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {slide.notes}
                        </p>
                      ) : null} */}
      <CardToggle
        toggleTitle={slide.toggleTitle}
        toggleContent={slide.toggleContent}
      />
      <CardFALink src={slide.inSlideFALink} />
      <CardVideo src={slide.inSlideVideoPlayer} />
      <CardVimeo src={slide.inSlideVimeoPlayer} />
      <CardYoutube src={slide.inSlideYoutubePlayer} />
      <CardAudio src={slide.inSlideAudioPlayer} />
      {/* <MediaSlider
                        galleryMaterials={galleryData}
                        currentSlideNum={value.slideIdx}
                      /> */}
      <CardLink src={slide.inSlideLink} />
    </div>
  );
}
