import React, { useState } from "react";
import "./CardText.css";

export default function CardToggle(props) {
  const [toggled, setToggled] = useState(false);
  const language = props.language;
  const hideText = language === "en" ? "  hide" : " " + "إخفاء";
  const hideArrow = language === "en" ? "arrow right" : "arrow left";

  if (!props.toggleTitle) {
    // check this
    return null;
  }

  // const to = props.text;
  const toggleParagraphs = props.toggleContent.split("\n");

  const processToggleParagraph = (par) => {
    const splitForTag = par.split("<q>");
    return splitForTag.length > 1 ? splitForTag[1] : splitForTag[0];
  };

  return (
    <p className="card-text">
      <span className="card-toggle" onClick={() => setToggled(!toggled)}>
        {" "}
        <i className={toggled ? "arrow down" : hideArrow}></i>
        {toggled ? hideText : "  " + props.toggleTitle}
      </span>

      {toggled ? (
        <span>
          <br />
          <br />
          {toggleParagraphs.map((p, i) => (
            <span>
              <span className="card-text-toggle">
                {processToggleParagraph(toggleParagraphs[i])}
              </span>
              <br />
              {/* <br /> */}
            </span>
          ))}
        </span>
      ) : null}
    </p>
  );
}
