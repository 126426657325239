import React, {useRef} from "react";
import * as THREE from "three";

import {PerspectiveCamera} from "@react-three/drei";
import {useThree} from "@react-three/fiber";

import {useHelper} from "@react-three/drei";
import {CameraHelper} from "three";

export default function MatchCameraHelper({cameraSettings}) {
  const camRef = useRef();
  useHelper(camRef, CameraHelper, "blue");
  const quat = new THREE.Quaternion();
  quat.setFromEuler(cameraSettings.rotationE);

  return (
    <>
      <group name="mona yakub photomatch" position={cameraSettings.position}>
        <group name="Camera_Orientation">
          <group name="Camera_Orientation_Orientation">
            <PerspectiveCamera
              ref={camRef}
              name="Camera_Orientation_Orientation_Orientation"
              makeDefault={false}
              rotation={cameraSettings.rotationE}
              quaternion={quat}
              far={1}
              near={0.1}
              fov={cameraSettings.fov}
              focus={2} // for depth of field
              aspect={1}
            // onUpdate={(camera) => {
            //   console.log(camera.fov);
            //   camera.updateProjectionMatrix();
            // }}
            />
          </group>
        </group>
      </group>
      {/* {camera && <cameraHelper args={[camera]} />} */}
    </>
  );
}
