import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import * as THREE from "three";
import {useSpring, animated, config} from "@react-spring/three";
import {useLoader} from "@react-three/fiber";

import {getTanFromDegrees} from "./utils/math";
import {TextureLoader} from "three";

export default function PhotomatchPlane({
  slideCam,
  matchCamera,
  cameraSettings,
}) {
  const planeRef = useRef();
  const [material] = useState(() => new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0
  }));

  // slideCam
  const slideCamAtMatch = slideCam.threeMatch !== undefined;
  const IamTheMatch = matchCamera.name === slideCam.name;

  useSpring({
    opacity: slideCamAtMatch && IamTheMatch ? 1 : 0,
    reset: true,
    delay: slideCamAtMatch && IamTheMatch ? 4000 + 0 : 0,
    config: {duration: 2000}, //config.wobbly,

    onChange(cont) {
      planeRef.current.material.opacity = cont.value.opacity;
    },
  });

  const texture = useLoader(TextureLoader, matchCamera.threeMatch)

  useLayoutEffect(() => {
    if (texture) {
      material.map = texture;
    }
  }, [texture])

  const plane = planeRef.current;
  if (plane) {
    plane.position.set(
      cameraSettings.position.x,
      cameraSettings.position.y,
      cameraSettings.position.z
    );
    plane.quaternion.setFromEuler(cameraSettings.rotationE);
    const halfFovAngle = cameraSettings.matchFOV * 0.5;
    const zMovement = 1;
    const tan = getTanFromDegrees(halfFovAngle);
    // const camera aspect..
    const aspect = window.innerWidth / window.innerHeight;
    const aspectRev = window.innerHeight / window.innerWidth;

    const w = window.innerWidth;
    const scaleFac = Math.abs(tan) * zMovement * 2;
    // const planeH = 1;
    // const moveZ = planeH / Math.tan((cameraSettings.fov * Math.PI) / 180);
    // plane.scale.set(scaleFac * 0.5 * imageAspectRatio, scaleFac * 0.5, 1);
    plane.translateZ(-zMovement);

    const imageAspectRatio = texture.image.width / texture.image.height;
    texture.needsUpdate = true;
    plane.scale.set(scaleFac * imageAspectRatio, scaleFac, 1);

    texture.encoding = THREE.sRGBEncoding;

    plane.material = material;
  }

  return (
    <mesh ref={planeRef}>
      <planeBufferGeometry attach="geometry" args={[1, 1]} />
      {/* <meshStandardMaterial attach="material" color={"red"} /> */}
    </mesh>
  );
}
