import {useState} from "react";
import Switch from "react-switch";

import {colors} from "../constants";

import './Toggle.css';

const Toggle = ({onChange, isMobile, styles, leftLabel, rightLabel}) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="toggle" style={{right: isMobile ? '250px' : '120px'}}>
      <Switch
        onChange={(checked) => {
          setChecked(checked)
          onChange(checked ? rightLabel : leftLabel)
        }}
        styles={styles}
        checked={checked}
        disabled={false}
        onColor={colors.grey}
        offColor={colors.grey}
        uncheckedIcon={<div className='unchecked-icon'>{rightLabel}</div>}
        checkedIcon={<div className='checked-icon'>{leftLabel}</div>}
      />
    </div>
  );
}

export default Toggle;
