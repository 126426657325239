import React, {useState, useRef, useEffect, Suspense} from "react";

import * as THREE from "three";
import {Canvas} from "@react-three/fiber";
import {PerspectiveCamera, softShadows} from "@react-three/drei";

import {Lightmap} from "@react-three/lightmap";

import SceneLighting from "./3d/SceneLighting";
import BlenderScene from "./3d/BlenderScene";
import SimpleScene from "./3d/SimpleScene";
import Tower from "./3d/Tower";

// softShadows();

export default function Scene({isMobile, slide, matchCameras, language}) {
  // style
  const threeDStyle = {
    height: window.innerHeight,
    width: window.innerWidth,
    position: "fixed",
    // left: "50vw",
    opacity: slide.show3D ? "1" : "0",
    WebkitTransition: `opacity ${"1s"}`,
  };

  const [hemiLight] = useState(
    () => new THREE.HemisphereLight(0xffffff, 0x111111, 1)
  );

  const onCreated = ({gl, scene}) => {
    gl.setClearColor("#202020");

    gl.setPixelRatio(window.devicePixelRatio);

    gl.toneMapping = THREE.ReinhardToneMapping; //THREE.ACESFilmicToneMapping; THREE.ReinhardToneMapping;

    gl.outputEncoding = THREE.sRGBEncoding;

    scene.add(hemiLight);
  };

  return (
    <div id="scene" style={threeDStyle}>
      <Suspense fallback={<div>Loading... </div>}>
        <Canvas
          shadows
          shadowMap
          colorManagement
          gl={{antialias: true}}
          onCreated={onCreated}
        >
          {isMobile ? null : <SceneLighting />}
          {/* <fog attach="fog" color="#969696" near={0.1} far={2000} /> */}
          {/* <Lightmap ao> */}

          <group name="shift-blender-objects" position={[220, -725, -120]}>
            <Suspense>
              <BlenderScene
                isMobile={isMobile}
                slide={slide}
                matchCameras={matchCameras}
                language={language}
              />
            </Suspense>
          </group>
          {/* <Tower /> */}
          {/* </Lightmap> */}
        </Canvas>
      </Suspense>
    </div>
  );
}
