import "./App.css";

import React from "react";

import MobileApp from "./MobileApp";
import DesktopApp from "./DesktopApp";

export const BASE_URL =
  "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/";

function App() {
  const isMobile = window.matchMedia("(hover: none)").matches;
  console.log("isMobile :", isMobile);

  return <DesktopApp isMobile={isMobile} />;
}

export default App;
