import React, { useState, useEffect, useRef } from "react";
// import ReactPlayer from "react-player/vimeo";

// Only loads the YouTube player

export default function StaticVideo({ isMobile, slide }) {
  const [skipped, setSkipped] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const videoRef = useRef();

  // console.log("skipped", skipped);
  // console.log("showArrow", showArrow);

  useEffect(() => {
    if (slide.fullScreenVideo) {
      if (videoRef.current) {
        setShowArrow(true);
        setSkipped(false);
        videoRef.current.muted = false;
        videoRef.current.volume = 1;
        videoRef.current.play();
        console.log("playing video");
      }
    }
  }, [slide]);

  if (!slide) {
    return null;
  }

  // console.log(slide);
  return (
    <div
      id={"static-video"}
      style={{
        // color: "black",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // top: 0,
        // left: 600,
        // top: 150,
        // right: 0,
        // bottom: 300,
        opacity: slide.fullScreenVideo ? "1" : "0.0",
        backgroundColor: "black",
        pointerEvents: showArrow ? "auto" : "none",
        zIndex: slide.fullScreenVideo && isMobile && !skipped ? 1000 : 20,
        width: "100vw",
        height: "100vh",
        // padding: 10,
        WebkitTransition: `opacity ${
          slide.fullScreenVideo ===
            "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/" +
              "Parvin/Videos/Video for Animations/transition_RL_01.mp4" ||
          slide.fullScreenVideo ===
            "https://fa-public-assets.fra1.cdn.digitaloceanspaces.com/" +
              "Parvin/Videos/Video for Animations/transition_RL_02.mp4"
            ? "0.2s"
            : "1s"
        }`,
      }}
    >
      {slide.fullScreenVideo ? (
        <video
          controls={isMobile}
          ref={videoRef}
          preload="auto"
          muted
          // controls="controls" //
          // autoPlay={true}
          zindex="300"
          width="100%"
          playsInline
          type="video/mp4"
          // ref={onVideoMount}
          src={slide.fullScreenVideo}
          onEnded={() => {
            console.log("video ended");
            setSkipped(true);
            setShowArrow(false);
          }}
        />
      ) : null}

      {isMobile && showArrow && (
        <div
          id="arrow-div"
          onClick={() => {
            console.log("skip clicked");
            setSkipped(true);
            setShowArrow(false);
          }}
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            bottom: "20px",
            height: "20vh",
            width: "100vw",
          }}
        >
          {/* <p>
            <i
              style={{
                // fontSize: "90px",
                border: "solid white",
                borderWidth: "0px 5px 5px 0px",
                display: "inline-block",
                padding: "8px",
                transform: "rotate(45deg)",
                webkitTransform: "rotate(45deg)",
              }}
            />
          </p> */}
          <p
            style={{
              color: "white",
              // border: `1px solid white`,
              padding: `1rem`,
            }}
          >
            Skip Video
          </p>
        </div>
      )}
    </div>
  );
}
