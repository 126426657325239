import React, { useState, useEffect, useRef } from "react";
// import ReactPlayer from "react-player/vimeo";

// Only loads the YouTube player

export default function BlackBackground({ slide }) {
  if (!slide) {
    return null;
  }

  // console.log(slide);
  return (
    <div
      id={"black-background"}
      style={{
        // color: "red",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // top: 0,
        // left: "50vw",
        // top: 150,
        // right: 0,
        // bottom: 300,
        opacity: slide.imageMatch || slide.fullScreenVideo ? "1" : "0.0",
        backgroundColor: "black",
        pointerEvents: "none",
        zIndex: 9,
        width: "100vw",
        height: "100vh",
        // padding: 10,
        WebkitTransition: `opacity ${"3s"}`,
      }}
    >
      <div></div>
    </div>
  );
}
