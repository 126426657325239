import React, {useState, useEffect, useRef} from "react";
// import ReactPlayer from "react-player/vimeo";

// Only loads the YouTube player

export default function ImageMatch({slide}) {
  if (!slide) {
    return null;
  }

  return (
    <div
      id={"image-match"}
      style={{
        // color: "black",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // top: 0,
        // left: "50vw",
        // top: 150,
        // right: 0,
        // bottom: 300,
        opacity: slide.imageMatch ? "1" : "0.0",
        backgroundColor: "none",
        pointerEvents: "none",
        zIndex: 10,
        width: "100vw",
        height: "100vh",
        // padding: 10,
        WebkitTransition: `opacity ${"3s"}`,
      }}
    >
      <img
        preload="true"
        // width="auto"
        width="100%"
        // height="100%"
        objectFit="cover"
        src={slide.imageMatch}
        alt={slide.imageMatch}
      />
    </div>
  );
}
