export const URLS = [
  {
    title: {
      en: `Introduction`,
      ar: `مقدمة`
    },
    url: `/`,
    next: `/house`,
    startIdx: 0,
    endIdx: 2,
  },
  {
    title: {
      en: `The House`,
      ar: `البيت`
    },
    url: `/house`,
    next: `/street`,
    startIdx: 3,
    endIdx: 37,
  },
  {
    title: {
      en: `The Street`,
      ar: `الشارع`
    },
    url: `/street`,
    next: `/neighbourhood`,
    startIdx: 38,
    endIdx: 73,
  },
  {
    title: {
      en: `The Neighbourhood`,
      ar: `الحي`
    },
    url: `/neighbourhood`,
    next: `/city`,
    startIdx: 74,
    endIdx: 101,
  },
  {
    title: {
      en: `The City`,
      ar: `المدينة`
    },
    url: `/city`,
    next: `/land`,
    startIdx: 109,
    endIdx: 114,
  },
  {
    title: {
      en: `The Land`,
      ar: `الأرض`
    },
    url: `/land`,
    next: null,
    startIdx: 115,
    endIdx: 126,
  },
];

export const LANGUAGES = {
  EN: 'EN',
  AR: 'AR'
}

export const LANGUAGE_LOWERCASE = {
  EN: 'en',
  AR: 'ar'
}

export const colors = {
  fa_red: "#eb443e",
  yellow: "#ffd800",
  black: "#000000",
  white: "#ffffff",
  grey: "#5d6475",
};
