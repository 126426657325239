import React from "react";
import "./CardButtons.css";

export default function CardLink(props) {
  if (!props.src) {
    return null;
  }

  // const FA_red = "#dc5042";

  return (
    <div style={{ marginRight: "0px", overflow: "hidden" }}>
      <button
        className="FA-button"
        onClick={(e) => {
          e.preventDefault();
          console.log("button clicked");
          window.open(props.src, "_blank");
          // window.location.href = "http://google.com";
        }}
      >
        Go To Investigation
      </button>
    </div>
  );
}
