const slides = {
  0: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Parvin’s third pushback coincided with the beginning of the crisis at the Pazarkule/Kastanies border crossing outside of Edirne, on February 28th, 2020. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.4481, 41.613, 26.6035, 41.7064], // [26.4703, 41.62, 26.6088, 41.7033],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  1: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Throughout the day, Parvin shared her live location with friends, who took screenshots at fixed intervals. ",
    contentMedia: "",
    date: "",
    dateRangeStart: "24/02/2020",
    dateRangeEnd: "28/02/2020",
    bounds: [26.4481, 41.613, 26.6035, 41.7064], // [26.4703, 41.62, 26.6088, 41.7033],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  2: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "In the early hours of February 28th Parvin and four of her companions travelled from Edirne to Karaagac by taxi. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.4481, 41.613, 26.6035, 41.7064], // [26.4703, 41.62, 26.6088, 41.7033],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  3: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "At 08:51 Greek time, the group left Karaagac on foot, making their way to the border fence.  ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48647, 41.63653, 26.54217, 41.67],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  4: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "On the way, they met other groups who were also making their way to the fence, after hearing rumors that the border would open. ",
    contentMedia: "",
    date: "",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48647, 41.63653, 26.54217, 41.67],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  5: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport: "At 09:13 they arrived at the fence",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48588, 41.62692, 26.55728, 41.66983],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  6: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "There, they were met by increased border guard and army presence, both Turkish and Greek.  ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48588, 41.62692, 26.55728, 41.66983],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  7: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport: "They remained at the fence for the next 45 minutes. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.47803, 41.62215, 26.53752, 41.6579],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  8: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Unable to cross, at approximately 10:00 they left the fence and headed to the direction of the Pazarkule/Kastanies border crossing point.  ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.47803, 41.62215, 26.53752, 41.6579],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo:
      "Parvin/Videos/Pushback 3/Pushback3-1-WhatsApp Ptt 2020-02-28 at 10.09.29.mp4",
  },
  9: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "They arrived at the river north-east of the crossing at 10:32. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.45968, 41.63371, 26.53721, 41.6803],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  10: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "A few minutes later, at 10:41, the group made their way to the forested area north-west of the border crossing. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.47164, 41.6425, 26.50586, 41.66306],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  11: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "This 500m-long stretch of land is the only part of the border near Pazarkule where the border was, at the time, unfenced. The boundary was only marked with small metal flags.  ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48284, 41.65227, 26.49808, 41.66143],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  12: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Following the events of that day, the area has now been deforested on both sides of the border. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48284, 41.65227, 26.49808, 41.66143],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  13: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "At 10:50, the group reached the border, and walked along the borderline.",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.47609, 41.64815, 26.50048, 41.6628],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  14: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Parvin took photos of the flags designating the boundary between the two countries. ",
    contentMedia: "",
    date: "",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.47609, 41.64815, 26.50048, 41.6628],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  15: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "At 10:54, Parvin’s live location was some 50 metres inside Greece.  ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.484605, 41.654096, 26.490035, 41.65736], // [26.47428, 41.64734, 26.49936, 41.66241],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  16: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "At 10:55, Parvin sent an audio message confirming she had crossed into Greek territory. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.484605, 41.654096, 26.490035, 41.65736], // [26.47428, 41.64734, 26.49936, 41.66241],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo:
      "Parvin/Videos/Pushback 3/Pushback3-2-Whatsapp WhatsApp Audio 2020-02-28 at 10.55.15.mp4",
  },
  17: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Parvin and her companions walked inside Greek territory and parallel to the border for the next 25 minutes",
    contentMedia: "",
    date: "",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.484605, 41.654096, 26.490035, 41.65736], // [26.47367, 41.64767, 26.4991, 41.66295],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  18: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "At 11:18 her live location places her some 100metres from the Greek outpost at the border crossing.  ",
    contentMedia: "",
    date: "",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.46258, 41.63594, 26.51162, 41.66542],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  19: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      'There, they were met with a strong Greek police and army presence. Tear gas, stun grenades and live fire were used, and the group was forced to return to Turkish soil.: \n\n"A big black army truck arrived [...]. It had a lot of Greek soldiers, and they were shooting in our direction with guns. In fact they were shooting at the trees to scare us. I heard one of the shots close to my left side which hit a tree. I was terrified. [We] ran back to Turkey. The Greek officers threw many sound bombs at us. It was really scary. I felt this wave of bombs in my heart."',
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.46258, 41.63594, 26.51162, 41.66542],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  20: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "At 11:21, Parvin’s live location places her back into Turkish soil, thus marking her third and most rapid pushback. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.45698, 41.62959, 26.51358, 41.66361],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  21: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport: "",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.47886, 41.63121, 26.53453, 41.66467],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  22: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "She would spend the next hours by the road marking the official Pazarkule crossing, waiting for an opportunity to cross.  ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48006, 41.64841, 26.50005, 41.66042],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  23: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "During her time at the crossing, Parvin endured and documented repeated volleys of teargas and stun grenades discharged from the Greek side. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48006, 41.64841, 26.50005, 41.66042],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  24: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Her videos were aired by Sky News, who interviewed her by the border crossing on the night of February the 28th. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48006, 41.64841, 26.50005, 41.66042],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "Parvin/Videos/Pushback 3/video_sky news.mp4",
    fullScreenVideo: "",
  },
  25: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Parvin also spoke to  the Canadian Broadcasting Company, sharing her testimony of the events. ",
    contentMedia: "",
    date: "28/02/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.48006, 41.64841, 26.50005, 41.66042],
    inSlideLink:
      "https://www.cbc.ca/radio/asithappens/as-it-happens-friday-edition-1.5479898/as-turkey-opens-path-to-europe-one-refugee-describes-war-like-scene-at-border-with-greece-1.5479904",
    inSlideFALink: "",
    inSlideAudioPlayer:
      "https://mp3.cbc.ca/radio/CBC_Radio_VMS/95/199/dave-VtqDKtBy-20200228.mp3",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
  26: {
    info: "",
    titleReport: "",
    titleMedia: "",
    contentReport:
      "Parvin would spend several more days at the crossing, before leaving to Istanbul on 02/03/2020. ",
    contentMedia: "",
    date: "02/03/2020",
    dateRangeStart: "",
    dateRangeEnd: "",
    bounds: [26.075, 40.378, 29.56, 42.472],
    inSlideLink: "",
    inSlideFALink: "",
    inSlideAudioPlayer: "",
    inSlideVideoPlayer: "",
    fullScreenVideo: "",
  },
};
export default slides;
