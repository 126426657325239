import React from "react";

export default function ImageCredit({ isMobile, slide }) {
  if (!slide.imageCredit) {
    return null;
  }

  console.log(slide.imageCredit);

  return (
    <div
      id={"image-credit"}
      style={{
        // color: "black",
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // alignContent: "flex-end",
        zIndex: "1000",
        flexDirection: "column",
        // top: 0,
        // left: 600,
        // top: 150,
        // right: 0,
        // bottom: 300,
        opacity: slide.imageCredit ? "0.8" : "0.0",
        backgroundColor: "none", // "black", 7dffb3  00ff7f
        pointerEvents: "none",
        // zIndex: 20,
        width: "100vw",
        height: "100vh",
        // padding: "0vw 10vw",
        WebkitTransition: "opacity 2s",
      }}
      // className={`scrollable-video__container`}
    >
      <p
        style={{
          color: "white",
          //   marginTop: "auto",
          position: "absolute",
          bottom: "0px",
          //   flexGrow: "1",
          // fontWeight: "normal",
          fontSize: isMobile ? "4vw" : "1vw",
          textAlign: "center",
          //   margin: "1vw 10vw",
        }}
      >
        Image: {slide.imageCredit}
      </p>
    </div>
  );
}
