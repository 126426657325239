import React from "react";
import "./CardText.css";

export default function CardText({style, text, isSection}) {
  if (!text) {
    return null;
  }

  const paragraphs = text.split("\n");
  // console.log(paragraphs);

  const processParagraph = (paragraph) => {
    const splitForOpeningTag = paragraph.split("<");
    const processNoTag = (seg) => {
      const splitForClosingTag = seg.split(">");
      return splitForClosingTag.length > 1
        ? splitForClosingTag[1]
        : splitForClosingTag[0];
    };
    const processTag = (seg) => {
      // console.log(seg, text);
      const splitForClosingTag = seg.split(">");
      const tag = splitForClosingTag[0];
      const content = splitForClosingTag[1];

      if (tag === "h") {
        return <span className="card-text-highlight">{content}</span>;
      }
      if (tag === "q") {
        return <span className="card-text-quote">{content}</span>;
      }
      if (tag === "hq") {
        return <span className="card-text-highlight-quote">{content}</span>;
      }
      if (tag === "l") {
        return (
          <a
            className="card-text-link"
            rel="noreferrer"
            href={content.split("(")[1].split(")")[0]}
            target="_blank"
          >
            {content.split("(")[0]}
          </a>
        );
      }
      if (tag === "ql") {
        return (
          <a
            className="card-text-link-quote"
            rel="noreferrer"
            href={content.split("(")[1].split(")")[0]}
            target="_blank"
          >
            {content.split("(")[0]}
          </a>
        );
      }
      return content;
    };

    return (
      <p className="card-text" style={style}>
        {splitForOpeningTag.map((s, i) =>
          i % 2 === 0
            ? processNoTag(splitForOpeningTag[i])
            : processTag(splitForOpeningTag[i])
        )}
      </p>
    );
  };

  return paragraphs.map((p) => processParagraph(p));
}
