import { useCallback, useEffect } from "react";

import "./ProgressBar.css";

export const ProgressBar = ({ elementId = `` }) => {
  const wrapper = document.getElementById(elementId);
  const onScroll = useCallback(
    (e) => {
      // console.log(e, document);
      // https://stackoverflow.com/questions/1145850/how-to-get-height-of-entire-document-with-javascript
      // const body = document.body,
      // html = document.documentElement;
      const height = Math.max(
        wrapper.scrollHeight
        // body.offsetHeight,
        // html.clientHeight,
        // html.scrollHeight,
        // html.offsetHeight
      );
      // console.log(wrapper.scrollTop, wrapper.scrollY);
      // wrapper.
      // const wrapperDiv = document.getElementById("all-wrapper");
      const scrollPercentage =
        wrapper.scrollTop / (height - window.innerHeight * 1.2);
      // console.log(scrollPercentage);
      document.documentElement.style.setProperty(
        "--progress-tracker-width",
        `${scrollPercentage * 100}vw`
      );
    },
    [wrapper]
  );

  useEffect(() => {
    if (!wrapper) return;
    wrapper.addEventListener("scroll", onScroll);
    return function cleanup() {
      wrapper.removeEventListener("scroll", onScroll);
    };
  }, [onScroll, wrapper]);

  return (
    <div className="progress-bar__container">
      <div className="progress-bar__tracker"></div>
    </div>
  );
};
