import React from "react";

export default function CardVimeo(props) {
  if (!props.src) {
    return null;
  }

  // <iframe src="https://player.vimeo.com/video/**yourvideonumberhere**" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

  return (
    <div style={{ width: "400px" }}>
      <iframe
        title="video"
        src={props.src}
        width="400"
        height="300"
        frameBorder="0"
        // allow="autoplay; fullscreen; picture-in-picture"
        // allowfullscreen
      />
    </div>
  );
}
